import dayjs from 'dayjs';
import { NoData } from 'features/no-data/no-data';
import { UsersTopBar } from 'features/users/components/top-bar/users-top-bar';
import { UsersTable } from 'features/users/components/users/users-table';
import {
  UserFilterFormsValues,
  UserFiltersFormValuesPaginated,
} from 'features/users/components/users-filter-form/types';
import { useGetUsersList } from 'features/users/use-cases/get-users';
import { useUserContext } from 'features/users/user-context';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

const getInitialFilterValues: UserFilterFormsValues = {
  Name: undefined,
  RoleName: undefined,
  Email: undefined,
  IsActive: undefined,
  CreatedDate: undefined,
};

export const AdminUsersView = () => {
  const { menuItem }: any = useUserContext();
  const { params, updateParams, resetPage } =
    usePaginationParams<UserFiltersFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });
  const preparePayload = (data: any) => {
    return {
      ...data, // Spread other existing properties in `data`
      CreatedDate: data?.CreatedDate
        ? dayjs(data.CreatedDate).format('DD-MM-YYYY')
        : null,
    };
  };
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const getUsersListQueryResponse = useGetUsersList(
    preparePayload(params), // Directly pass `params`
    {
      retry: false, // Disable retry if the query fails
    },
  );
  const handleFiltersChange = (values: UserFilterFormsValues) => {
    updateParams(values);
    resetPage();
  };
  if (menuItem?.rc?.length === 0) {
    return <NoData />;
  }

  return (
    <>
      <UsersTopBar
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
      />
      <UsersTable
        isUsersListLoading={getUsersListQueryResponse.isFetching}
        usersList={getUsersListQueryResponse?.data?.rc || []}
        itemsCount={getUsersListQueryResponse.data?.total || 0}
        tableParams={params}
        updateParams={updateParams}
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
      />
      <Outlet />
    </>
  );
};
