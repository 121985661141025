import { Form, Input } from 'antd';
import { CategoryFormField } from 'features/master-management/components/filter-field/type';
// import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { Fragment } from 'react';

export const CategoryInfoFormItems = () => {
  // const { data: Frequency } = useDropDownFlag('Frequency');

  return (
    <Fragment>
      <Form.Item<CategoryFormField>
        name={CategoryFormField.CategoryName}
        label={'Category Name'}
        rules={[
          {
            required: true,
            whitespace: false,
          },
        ]}
      >
        <Input placeholder={'Enter CategoryName'} />
      </Form.Item>
      {/* <Form.Item<CategoryFormField>
        name={CategoryFormField.Frequency}
        label='Frequency'
      >
        <Select
          options={Frequency?.data?.rc.map((item: FrequencyName) => ({
            label: item.text, // Use `label` for the display value
            value: item.value, // Use `value` for the actual value
          }))}
          placeholder='Please select'
        />
      </Form.Item> */}
    </Fragment>
  );
};
