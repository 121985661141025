import iconclnt from 'assets/icons/ds_icon_01.svg';
import iconusr from 'assets/icons/ds_icon_02.svg';
import iconnusr from 'assets/icons/ds_icon_03.svg';
import iconinavt from 'assets/icons/ds_icon_04.svg';
import { useGetCardList } from 'features/Dashboard/use-cases/get-dashBoradCard';
import { Fragment, useEffect } from 'react';
export const CardInfo = () => {
  const { data: dataList, refetch } = useGetCardList('' as any);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Fragment>
      <div className='mltybxcard'>
        <div className='card'>
          <div className='c_icon'>
            <img src={iconclnt} alt='' />
          </div>
          <div className='c_vlu'>
            <p>Clients</p>
            <h3>{dataList?.res?.totalClients || '0'}</h3>
          </div>
        </div>
        <div className='card'>
          <div className='c_icon'>
            <img src={iconnusr} alt='' />
          </div>
          <div className='c_vlu'>
            <p>New Clients</p>
            <h3>{dataList?.res?.clientNewUsers || '0'}</h3>
          </div>
        </div>
        <div className='card'>
          <div className='c_icon'>
            <img src={iconusr} alt='' />
          </div>
          <div className='c_vlu'>
            <p>Users</p>
            <h3>{dataList?.res?.clientUsers || '0'}</h3>
          </div>
        </div>
        {/* <div className='card'>
          <div className='c_icon'>
            <img src={iconnusr} alt='' />
          </div>
          <div className='c_vlu'>
            <p>New Clients</p>
            <h3>{dataList?.res?.clientNewUsers || '0'}</h3>
          </div>
        </div> */}
        <div className='card'>
          <div className='c_icon'>
            <img src={iconinavt} alt='' />
          </div>
          <div className='c_vlu'>
            <p>Inactive</p>
            <h3>{dataList?.res?.clientInactiveUsers || '0'}</h3>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
