import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Grid,
  Image,
  Input,
  message,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Modal } from 'components';
import { useRequestManagementUpload } from 'features/assigned-clients/use-cases/create-report';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { validateFileBeforeUpload } from 'routes/utils';

const { useBreakpoint } = Grid;

export const AddReportView = ({
  onClose,
  refetch,
  // selectedValue,
  year,
  month,
  initilaMarkFinished,
}: {
  onClose: () => void;
  refetch: any;
  // selectedValue: any;
  year: any;
  month: any;
  initilaMarkFinished: any;
}) => {
  const { id } = useParams();
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const { isLoading, mutate } = useRequestManagementUpload();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFormValues>();
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };

  const handleUpdate = (formValues: any) => {
    const IsClientUpload: any = false;
    const formData = new FormData();
    const file = fileList[0]?.originFileObj;
    if (file == undefined) {
      return message.error('Please Upload Document');
    }
    formData.append('DocumentName', formValues.DocumentName);
    formData.append('CompanyId', id as any);
    formData.append('Note', formValues?.Note);
    // formData.append('PeriodId', selectedValue);
    formData.append('Month', month);
    formData.append('Year', year);
    // Append the file size if the file exists
    if (file) {
      formData.append('file', file); // Include the entire file object
      formData.append('FileName', file.name);
      formData.append('FileSize', file.size.toString()); // File size in bytes
    } else {
      formData.append('FileSize', '0'); // Default value if no file is selected
    }

    formData.append('IsClientUpload', IsClientUpload);

    mutate(formData, {
      onSuccess: () => {
        onClose(); // Close modal or reset form
        refetch(); // Refetch the document list to get updated data
        initilaMarkFinished();
      },
    });
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={<span className='font-semibold'>Upload Documents</span>}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Save'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          className: 'btn cstmfull',
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          //   disabled={isLoading}
        >
          <Form.Item
            label='Report Name'
            name='DocumentName'
            rules={[
              {
                required: true,
                message: 'Please Enter Report Name',
              },
            ]}
          >
            <Input placeholder='Enter Report Name' />
          </Form.Item>
          <Form.Item name={'file'} label='Upload Document'>
            <div style={{ width: '100%' }}>
              <Upload
                action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
                listType='picture-card'
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={validateFileBeforeUpload}
                maxCount={1}
                style={{ width: '100%' }} // Ensure the Upload takes 100% width
              >
                {fileList?.length >= 1 ? null : (
                  <div style={{ width: '100%' }}>
                    {' '}
                    {/* Upload button full width */}
                    {uploadButton}
                  </div>
                )}
              </Upload>
            </div>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
          <Form.Item
            label='Notes'
            name={'Note'}
            rules={[
              {
                required: true,
                message: 'Please Enter Notes',
              },
            ]}
          >
            <TextArea placeholder='Notes' />
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
