import {
  SubFilterFormValuesPaginated,
  SubFormFilterValues,
} from 'features/subscription-management/components/subscription-filter-field/type';
import { SubscriptionItemView } from 'features/subscription-management/components/subscription-table/subscription-table-view';
import { SubscriptionTopBar } from 'features/subscription-management/components/top-bar/subscription-top';
import { useGetSubscriptionList } from 'features/subscription-management/use-cases/get-subscription';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
export const SubscriptionView = () => {
  const getInitialFilterValues: SubFormFilterValues = {
    id: undefined,
    Name: undefined,
    isActive: undefined,
  };
  const { params, updateParams, resetPage } =
    usePaginationParams<SubFilterFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });
  const [editSubscriptionModal, setEditSubscriptionModal] = useState(false);
  const [editSubData, setEditSubData] = useState('');
  const { data, isFetching } = useGetSubscriptionList(params, { retry: false });
  const handleFiltersChange = (values: SubFormFilterValues) => {
    updateParams(values);
    resetPage();
  };
  
return (
    <>
      <SubscriptionTopBar
        setEditSubscriptionModal={setEditSubscriptionModal}
        editSubscriptionModal={editSubscriptionModal}
        // setEditSubData={setEditSubData}
        editSubData={editSubData}
        // refetch={refetch}
        handleFilter={handleFiltersChange}
        getInitialFilter={getInitialFilterValues}
      />
      <SubscriptionItemView
        isRoleListLoading={isFetching}
        subList={data?.rc || ([] as any)}
        tableParams={params}
        // updateParams={updateParams}
        setEditSubscriptionModal={setEditSubscriptionModal}
        setEditSubData={setEditSubData}
      />
      <Outlet />
    </>
  );
};
