import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AssignClientsResponse,
  AssignedClientsApiService,
} from 'features/assigned-clients/api/assign-clients-api.service';

import { AssignDetailClientsQueryKeys } from './query-keys';
const { GetAssignById } = AssignedClientsApiService();

const getClientsDetailsQuery = (
  id: string,
  options?: UseQueryOptions<any>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<AssignClientsResponse>;
  options?: UseQueryOptions<AssignClientsResponse>;
} => ({
  queryKey: AssignDetailClientsQueryKeys.detail(id),
  queryFn: ({ signal }) =>
    GetAssignById(id, {
      config: { signal },
    }),
  ...options,
});

export const useGetAssignDetails = (
  id: string,
  options?: UseQueryOptions<AssignClientsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<AssignClientsResponse, Error>(
    getClientsDetailsQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
