import { PaginatedRequestParams } from 'api';

export enum SubFormField {
  SubscriptionName = 'name',
}

export type SubFormValues = {
  [SubFormField.SubscriptionName]: string | undefined;
};

export enum SubFormFilterField {
  Id = 'id',
  SubscriptionName = 'Name',
  Status = 'isActive',
}
export type SubFormFilterValues = {
  [SubFormFilterField.Id]: string | undefined;
  [SubFormFilterField.SubscriptionName]: string | undefined;
  [SubFormFilterField.Status]: string | undefined;
};

export type SubFilterFormValuesPaginated = SubFormFilterValues &
  PaginatedRequestParams;
