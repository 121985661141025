import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Switch, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import Typography from 'antd/es/typography/Typography';
import { Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import dayjs from 'dayjs';
import { usePermissions } from 'features/users';
import { AddAdminUserModal } from 'features/users/components/modals/add-admin-user-modal';
import { UserFiltersFormValuesPaginated } from 'features/users/components/users-filter-form';
import { useStatusChange } from 'features/users/use-cases/update-status';
import { FilePenLine } from 'lucide-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { User } from 'types';
// ../modals/add-admin-user-modal

type UsersTableProps = {
  isUsersListLoading: boolean;
  usersList: any[];
  itemsCount: number;
  tableParams: any;
  updateParams: Dispatch<SetStateAction<UserFiltersFormValuesPaginated>>;
  showAddUserModal: any;
  setShowAddUserModal: any;
};

export const UsersTable = ({
  usersList,
  isUsersListLoading,
  // itemsCount,
  tableParams,
  showAddUserModal,
  setShowAddUserModal,
  updateParams,
}: UsersTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const navigate = useNavigate();
  const { mutate } = useStatusChange();
  const [loadingStatus, setLoadingStatus] = useState<{
    [key: string]: boolean;
  }>({});

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      PageIndex: pagination.current,
      RecordPerPage: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  const handleStatusChange = (id: string, currentStatus: boolean) => {
    const updatedStatus = !currentStatus; // Toggle the current status
    const formData = {
      UserId: id,
      Status: updatedStatus, // Send the new status in the payload
    };
    setLoadingStatus((prev) => ({ ...prev, [id]: true })); // Set loading state for the specific user
    mutate(formData, {
      onSuccess: () => {
        setLoadingStatus((prev) => ({ ...prev, [id]: false }));
        // Optional: Refresh or update local state here
      },
      onError: () => {
        setLoadingStatus((prev) => ({ ...prev, [id]: false }));
      },
    });
  };

  return (
    <StyledTableWrapper className='card grid_secton'>
      {/* <StyledTableHeader></StyledTableHeader> */}
      <StyledTableHeader className='gs_head'>
        <h3>
          Users Management{' '}
          <span>{usersList?.length > 0 ? usersList[0].TotalRecords : '0'}</span>
        </h3>

        <div className='buttongroup'>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setShowAddUserModal(true)}
            style={{ backgroundColor: '#223666', color: 'white' }}
          >
            {t('usermanagement.button.adduser')}
          </Button>
          {showAddUserModal && (
            <AddAdminUserModal
              onClose={() => setShowAddUserModal(false)}
              type={1}
            />
          )}
        </div>
      </StyledTableHeader>

      <div className='cstmtblbx'>
        <div className='cstm_table'>
          <Table<User>
            loading={isUsersListLoading}
            dataSource={usersList}
            rowKey='id'
            onChange={handleTableChange}
            pagination={{
              current: tableParams.PageIndex,
              defaultPageSize: tableParams.RecordPerPage,
              total:
                usersList && usersList.length > 0
                  ? usersList[0]?.TotalRecords
                  : 0, // Check for empty dataList
            }}
            onRow={(record) => ({
              onClick: () => {
                const selection = window?.getSelection()?.toString();
                if (!selection?.length) {
                  navigate(getRoute(ROUTES.USERS_ADMIN_EDIT, record.id));
                }
              },
            })}
            components={{
              header: {
                cell: (
                  props: React.HTMLAttributes<HTMLTableHeaderCellElement>,
                ) => <th {...props} />,
              },
            }}
          >
            <Column
              title={'Sr No'}
              dataIndex={'Sr No'}
              render={(_, record, index) =>
                (tableParams.PageIndex - 1) * tableParams.RecordPerPage +
                index +
                1
              }
            />
            <Column<User>
              title={t('usermanagement.table.username')}
              dataIndex={'Name'}
            />
            <Column<User>
              title={t('usermanagement.table.Email')}
              dataIndex={'Email'}
            />
            <Column<User>
              title={t('usermanagement.table.userrole')}
              dataIndex={'RoleName'}
            />
            <Column<User>
              title={t('usermanagement.table.dateadded')}
              dataIndex={'CreatedOn'}
              render={(_, record: any) =>
                dayjs(record.CreatedOn).format('DD MMM YYYY')
              }
            />
            <Column<User>
              width='125px'
              title={t('usermanagement.table.status')}
              dataIndex={'role'}
              render={(_, record: any) => (
                <Space>
                  <Typography
                    style={{ color: record.IsActive ? '#155724' : 'red' }}
                  >
                    {record.IsActive ? 'Active' : 'Inactive'}
                  </Typography>
                </Space>
              )}
            />
            <Column<User>
              width='125px'
              title={t('usermanagement.table.action')}
              dataIndex={'Id'}
              render={(_, record: any) => (
                <Space className='grdbtngroup'>
                  <Button
                    className='btn'
                    type='link'
                    onClick={() =>
                      navigate(getRoute(ROUTES.USERS_ADMIN_EDIT, record.Id))
                    }
                  >
                    <FilePenLine height={18} />
                    {/* <EditOutlined /> */}
                  </Button>
                  <Switch
                    disabled={loadingStatus[record.Id]} // Disable during loading
                    loading={loadingStatus[record.Id]} // Show loading for specific user
                    onChange={() =>
                      handleStatusChange(record.Id, record.IsActive)
                    } // Pass current status
                    checked={record.IsActive} // Reflect current status
                  />
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </StyledTableWrapper>
  );
};
