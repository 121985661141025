import { PlusCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Space, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import dayjs from 'dayjs';
import { ClientsFilterFormPaginated } from 'features/new-clients/components/form-filter/type';
import { usePermissions } from 'features/users';
import { FilePenLine } from 'lucide-react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { newClients } from 'types/new-clients';
import { statusStyle } from 'utils/status';

type ClientsTableProps = {
  isFetching: boolean;
  setReminderModal: any;
  setReminderId: any;
  setMailModal: any;
  dataList: any;
  tableParams: any;
  updateParams: Dispatch<SetStateAction<ClientsFilterFormPaginated>>;
};

export const ClientsTable = ({
  dataList,
  isFetching,
  tableParams,
  updateParams,
  setReminderId,
  setMailModal,
  setReminderModal,
}: ClientsTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const navigate = useNavigate();
  if (!permissions) return null;
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<newClients> | SorterResult<newClients>[],
  ) => {
    updateParams({
      ...tableParams,
      PageIndex: pagination.current,
      RecordPerPage: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  return (
    <StyledTableWrapper className='card grid_secton'>
      <StyledTableHeader className='gs_head'>
        <h3>
          Client List{' '}
          <span>{dataList?.length > 0 ? dataList[0]?.TotalRecords : '0'}</span>
        </h3>
        <div className='buttongroup'>
          <Button
            className='btn'
            icon={<PlusCircleOutlined />}
            onClick={() => navigate(ROUTES.CLIENTS_ADD)}
          >
            {t('assignedClient.button.addClient')}
          </Button>
        </div>
      </StyledTableHeader>

      <div className='cstmtblbx'>
        <div className='cstm_table'>
          <Table<newClients>
            loading={isFetching}
            dataSource={dataList}
            rowKey='id'
            onChange={handleTableChange}
            pagination={{
              current: tableParams.PageIndex,
              defaultPageSize: tableParams.RecordPerPage,
              total:
                dataList && dataList.length > 0 ? dataList[0]?.TotalRecords : 0,
            }}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  navigate(
                    `${getRoute(
                      ROUTES.CLIENTS_DETAILS,
                      record.CompanyId,
                    )}?clsid=${record.Id}`,
                  );
                },
                style: { cursor: 'pointer' },
              };
            }}
            components={{
              header: {
                cell: (
                  props: React.HTMLAttributes<HTMLTableHeaderCellElement>,
                ) => <th {...props} />,
              },
            }}
          >
            <Column
              title={'Sr No'}
              dataIndex={'Sr No'}
              render={(_, record, index) =>
                (tableParams.PageIndex - 1) * tableParams.RecordPerPage +
                index +
                1
              }
            />
            <Column<newClients>
              title={t('assignedClient.table.clientName')}
              dataIndex={'Name'}
            />

            <Column<newClients>
              title={t('assignedClient.table.businessName')}
              dataIndex={'BusinessName'}
            />
            <Column<newClients>
              title={t('assignedClient.table.clientEmail')}
              dataIndex={'Email'}
            />

            <Column<newClients>
              title={'Phone Number'}
              dataIndex={'PhoneNumber'}
            />
            <Column<newClients>
              title={t('assignedClient.table.LastUpdate')}
              dataIndex={'LastUpdated'}
              render={(_, record) =>
                dayjs(record.LastUpdated).format('DD MMM YYYY')
              }
            />
            <Column<newClients>
              title={t('assignedClient.table.status')}
              dataIndex={'DocumentStatus'}
              render={(text) => <span style={statusStyle(text)}>{text}</span>}
            />

            <Column<newClients>
              title={'Document'}
              render={(_, render) => (
                <span>{`${render.NoOfAttachments} Attachment`}</span>
              )}
              dataIndex={'NoOfAttachments'}
            />

            <Column<newClients>
              title={t('assignedClient.table.action')}
              dataIndex={'user'}
              render={(_, record) => (
                <Space className='grdbtngroup'>
                  <Button
                    className='btn'
                    onClick={(e) => {
                      e.stopPropagation();
                      setReminderId(record);
                      setReminderModal(true);
                    }}
                  >
                    <SendOutlined style={{ transform: 'scaleY(-1)' }} />
                  </Button>
                  <Button
                    className='btn'
                    type='link'
                    icon={<FilePenLine height={16} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setReminderId(record);
                      setMailModal(true);
                    }}
                  />
                  {/* <Button
                    className='btn'
                    type='link'
                    icon={<Trash2 stroke='#E04343' height={16} />}
                    onClick={(e) => e.stopPropagation()}
                  /> */}
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </StyledTableWrapper>
  );
};
