import { Form, Grid, Input, message, Select } from 'antd';
import { Modal } from 'components';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { assignFormFieldValue } from 'features/assigned-clients/components/form-filter/type';
import { useDropDownFlag } from 'features/assigned-clients/use-cases/drop-down';
import { useGetDropDownFlagList } from 'features/assigned-clients/use-cases/drop-down-flag';
import { useRequestUpload } from 'features/assigned-clients/use-cases/upload-request';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
const { useBreakpoint } = Grid;
export const RequestDocumentModal = ({
  onClose,
  refetch,
  // selectedValue,
  getDocumentsCount,
  year,
  month,
}: {
  onClose: () => void;
  refetch: any;
  year: any;
  month: any;
  // selectedValue: any;
  getDocumentsCount: any;
}) => {
  const formName = 'add-category-form';
  const { id } = useParams();
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [subCategoryValue, setSubCategoryValue] = useState('');
  const [subClassValue, setSubClassValue] = useState<any>('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const [form] = Form.useForm<assignFormFieldValue>();
  const { isLoading, mutate } = useRequestUpload();
  const { data: category } = useDropDownFlag(
    'Category',
    `&CompanyId=${id}&ClientId=${ids}`,
  );
  const { data: subCategory } = useGetDropDownFlagList({
    Flag: 'SubCategory',
    ParentId: subCategoryValue,
    ClientId: ids,
    CompanyId: id,
  });

  const handleUpdate = (formValue: any) => {
    const formData = new FormData();
    formData.append('CompanyId', id as any);
    let serviceId = formValue.class; // Default to class
    if (formValue.category && formValue.SubCategory) {
      serviceId = formValue.SubCategory; // If both category and subcategory are selected, use subcategory
    } else if (formValue.category) {
      serviceId = formValue.category; // If only category is selected, use category
    }
    // Override if all three are selected to use `class`
    if (formValue.category && formValue.SubCategory && formValue.class) {
      serviceId = formValue.class; // If all three are selected, prioritize class
    }
    formData.append('ServiceId', serviceId); // Use the resolved serviceId
    // formData.append('PeriodId', selectedValue);
    formData.append('Month', month);
    formData.append('Year', year);
    formData.append('DocumentName', formValue.DocumentName);
    formData.append('RequestDocumentNotes', formValue.RequestDocumentNotes);
    mutate(formData, {
      onSuccess: () => {
        onClose(); // Call the onClose function when the mutation is successful
        refetch(); // Call refetch to reload data after the mutation
        getDocumentsCount();
      },
    });
  };
  const initialValues = async () => {
    try {
      const apires: any = await AssignedClientsApiService().dropDownByClassFlag(
        {
          Flag: 'Class',
          ParentId: subClassValue,
          ClientId: ids,
          CompanyId: id,
        },
      );
      setSubClassValue(apires);
    } catch (error: any) {
      message.error(error);
    }
  };
  useEffect(() => {
    if (subClassValue) {
      initialValues();
    }
  }, [subClassValue]);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Upload Document'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Send'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          className: 'btn cstmfull',
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<assignFormFieldValue>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          // initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item
            label='Document Name'
            name={'DocumentName'}
            rules={[
              {
                required: true,
                whitespace: false,
              },
            ]}
          >
            <Input placeholder='Enter document' />
          </Form.Item>
          <Form.Item
            label='Category'
            name={'category'}
            rules={[
              {
                required: true,
                message: 'Category is required',
              },
            ]}
          >
            <Select
              options={category?.data?.rc?.map((item: any) => ({
                label: item.text,
                value: item.value,
              }))}
              onChange={(value) => setSubCategoryValue(value)}
              placeholder='Please Select'
            />
          </Form.Item>
          {subCategory?.data?.rc.length > 0 && (
            <Form.Item name={'SubCategory'} label={'Sub Category'}>
              <Select
                options={subCategory?.data.rc?.map((item: any) => ({
                  label: item.text,
                  value: item.value,
                }))}
                onChange={(value) => setSubClassValue(value)}
                placeholder='please select'
              />
            </Form.Item>
          )}
          {subClassValue?.data?.rc.length > 0 && (
            <Form.Item name={'class'} label={'Class'}>
              <Select
                options={subClassValue?.data?.rc.map((item: any) => ({
                  label: item.text,
                  value: item.value,
                }))}
                placeholder='Please Select'
              />
            </Form.Item>
          )}
          <Form.Item
            name='RequestDocumentNotes'
            label='Note'
            rules={[
              {
                required: true,
                whitespace: false,
              },
            ]}
          >
            <Input placeholder='Enter Note' />
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
