import { Form, Grid, Input, Select } from 'antd';
import { Modal } from 'components';
import {
  SubcategoryFormField,
  SubCategoryFormValues,
} from 'features/master-management/components/filter-field/type';
import { useCreateSubCategory } from 'features/master-management/use-cases/create-subCategory';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
const { useBreakpoint } = Grid;
export const EditSubCategoryViewModal = ({
  onClose,
  editData,
}: {
  onClose: () => void;
  editData: any;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<SubCategoryFormValues>();
  const { isLoading, mutate } = useCreateSubCategory();
  const { data } = useGetCategoryList('' as any);
  const getInitialValues = {
    [SubcategoryFormField.SubCategoryName]: editData.subCategoryName,
    [SubcategoryFormField.CategoryId]: editData.categoryId,
  };
  const handleUpdate = (values: any) => {
    const payload: any = {
      id: editData.id,
      subCategoryName: values.subCategoryName,
      categoryId: values.categoryId,
    };
    mutate(payload, { onSuccess: onClose });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Edit Sub Category'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Edit SubCategory'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<SubcategoryFormField>
          id={formName}
          name={formName}
          form={form as any}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate as any}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item<SubcategoryFormField>
            name={SubcategoryFormField.SubCategoryName}
            label={'Sub Category Name'}
            rules={[
              {
                required: true,
                whitespace: false,
              },
            ]}
          >
            <Input placeholder={'Enter CategoryName'} />
          </Form.Item>
          <Form.Item<SubcategoryFormField>
            name={SubcategoryFormField.CategoryId}
            label={'Category'}
          >
            <Select
              options={data?.rc?.map((item: any) => ({
                label: item.categoryName,
                value: item.id,
              }))}
              placeholder='please select'
            />
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
