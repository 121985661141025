import { Form, Grid, Input, message, Select } from 'antd';
import { Modal } from 'components';
import { MasterApiService } from 'features/master-management/api/master-api.service';
import {
  ClassFormField,
  SubCategoryFormValues,
} from 'features/master-management/components/filter-field/type';
import { useEditClass } from 'features/master-management/use-cases/edit-class';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';

const { useBreakpoint } = Grid;
export const EditClassViewModal = ({
  onClose,
  editData,
}: {
  onClose: () => void;
  editData: any;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<SubCategoryFormValues>();
  const { isLoading, mutate } = useEditClass();
  const [subCaetgory, setSubCategory] = useState<any>('');
  const { data } = useGetCategoryList('' as any);

  const getInitialValues = {
    [ClassFormField.CategoryId]: editData.categoryId,
    [ClassFormField.SubCategoryId]: editData.subCategoryId,
    [ClassFormField.ClassName]: editData.className,
  };

  const handleUpdate = (values: any) => {
    const payload: any = {
      id: editData.id,
      className: values.className,
      subCategoryId: values.subCategoryId,
      categoryId: values.categoryId,
    };
    mutate(payload, { onSuccess: onClose });
  };
  const subCategory = async (id: any) => {
    try {
      const apiRes = await MasterApiService().GetSubCategoryById(
        `CategoryId=${id}` as any,
      );
      setSubCategory(apiRes);
    } catch (error) {
      message.error('something is wrong');
    }
  };
  useEffect(() => {
    if (editData?.categoryId) {
      subCategory(editData.categoryId); // Call subCategory when categoryId exists
    }
  }, [editData?.categoryId]);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Edit Class List'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Edit Class'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<ClassFormField>
          id={formName}
          name={formName}
          form={form as any}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate as any}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item<ClassFormField>
            name={ClassFormField.CategoryId}
            label={'Category'}
          >
            <Select
              options={data?.rc?.map((item: any) => ({
                label: item.categoryName,
                value: item.id,
              }))}
              onChange={(value) => subCategory(value)}
              placeholder='Please Select'
            />
          </Form.Item>
          <Form.Item<ClassFormField>
            name={ClassFormField.SubCategoryId}
            label={'Sub Category'}
          >
            <Select
              options={subCaetgory?.rc?.map((item: any) => ({
                label: item.subCategoryName,
                value: item.id,
              }))}
              placeholder='please select'
            />
          </Form.Item>
          <Form.Item<ClassFormField>
            name={ClassFormField.ClassName}
            label={'Class'}
          >
            <Input placeholder='Enter Class' />
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
