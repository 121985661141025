import {
  CloseOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Button, Image, Modal } from 'antd';
interface FileViewerProps {
  documentPath: string;
}

const FileViewer: React.FC<FileViewerProps> = ({ documentPath }: any) => {
  const getFileExtension = (url: string): string | null => {
    const match = url?.match(/\.(\w+)(?:\?|$)/);

    return match ? match[1].toLowerCase() : null;
  };
  const openModal = () => {
    Modal.info({
      title: 'Document View',
      width: '100%',
      icon: null,
      closable: true,
      closeIcon: <CloseOutlined />,
      style: { top: '0px', maxWidth: '500px' },
      content: (
        <div style={{ textAlign: 'start' }}>
          {['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv', 'pdf'].includes(
            extension!,
          ) ? (
            <iframe
              src={`https://docs.google.com/gview?url=${documentPath}&embedded=true`}
              width='100%'
              height='450px'
              style={{ border: 'none' }}
              title='Document Viewer'
            />
          ) : (
            <p>Unsupported file type for preview</p>
          )}
        </div>
      ),
    });
  };
  const extension = getFileExtension(documentPath);

  if (
    extension === 'jpg' ||
    extension === 'jpeg' ||
    extension === 'png' ||
    extension === 'gif'
  ) {
    return <Image src={documentPath} alt='Document' />;
  } else if (extension === 'pdf') {
    return (
      <Button
        type='link'
        onClick={openModal}
        icon={<FilePdfOutlined />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {/* Open Excel / CSV File */}
      </Button>
    );
  } else if (
    ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'].includes(extension!)
  ) {
    return (
      <Button
        type='link'
        onClick={openModal}
        icon={<FileExcelOutlined />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {/* Open Excel / CSV File */}
      </Button>
    );
  } else {
    return (
      <Button
        type='link'
        href={documentPath}
        target='_blank'
        icon={<FileOutlined />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {/* Download File */}
      </Button>
    );
  }
};

export default FileViewer;
