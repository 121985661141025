import { PaginatedRequestParams } from 'api';

export enum CategoryFormField {
  CategoryName = 'categoryName',
  Id = 'id',
  Frequency = 'frequencyId',
}
export type CategoryFormValues = {
  [CategoryFormField.CategoryName]: string | undefined;
  [CategoryFormField.Id]: string | undefined;
};

export enum CategoryFormFilterField {
  CategoryName = 'CategoryName',
}
export type CategoryFormFilterValues = {
  [CategoryFormFilterField.CategoryName]: string | undefined;
};

export type CategoryFilterFormValuesPaginated = CategoryFormFilterValues &
  PaginatedRequestParams;

export enum SubcategoryFormField {
  ID = 'id',
  SubCategoryName = 'subCategoryName',
  CategoryId = 'categoryId',
  Frequency = 'frequencyId',
}
export type SubCategoryFormValues = {
  [SubcategoryFormField.SubCategoryName]: string | undefined;
  [SubcategoryFormField.CategoryId]: string | undefined;
  [SubcategoryFormField.Frequency]: string | undefined;
};

export enum SubCategoryFormFilterField {
  SubCategoryName = 'SubCategoryName',
  CategoryId = 'CategoryId',
}
export type SubCategoryFormFilterValues = {
  [SubCategoryFormFilterField.SubCategoryName]: string | undefined;
  [SubCategoryFormFilterField.CategoryId]: string | undefined;
};
export type SubCategoryFilterFormValuesPaginated = SubCategoryFormFilterValues &
  PaginatedRequestParams;

export enum ClassFormField {
  Id = 'id',
  ClassName = 'className',
  SubCategoryId = 'subCategoryId',
  CategoryId = 'categoryId',
  Frequency = 'frequencyId',
}
export type ClassFormValues = {
  [ClassFormField.ClassName]: string | undefined;
  [ClassFormField.SubCategoryId]: string | undefined;
  [ClassFormField.CategoryId]: string | undefined;
  [ClassFormField.Frequency]: string | undefined;
};
export enum classFormFilterField {
  SubCategoryId = 'SubCategoryId',
  CategoryId = 'CategoryId',
  ClassName = 'ClassName',
}
export type ClassFormFilterValues = {
  [classFormFilterField.SubCategoryId]: string | undefined;
  [classFormFilterField.CategoryId]: string | undefined;
  [classFormFilterField.ClassName]: string | undefined;
};

export type ClassFilterFormValuesPaginated = ClassFormFilterValues &
  PaginatedRequestParams;
