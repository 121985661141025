import { CONFIG } from 'config';

import { axiosInstance } from './axios-instance';
import { ApiClientConfig } from './types';

/**
 * Get client side timezone.
 *
 * @returns {(+|-)HH:mm} - Where `HH` is 2 digits hours and `mm` 2 digits minutes.
 * @example
 * // From Indian/Reunion with UTC+4
 * // '+04:00'
 * getTimeZone()
 */
const getTimeZone = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const offset = Math.abs(timezoneOffset);
  const offsetOperator = timezoneOffset < 0 ? '+' : '-';
  const offsetHours = Math.floor(offset / 60)
    .toString()
    .padStart(2, '0');
  const offsetMinutes = Math.floor(offset % 60)
    .toString()
    .padStart(2, '0');

  return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
};

const replaceSubstring = (
  input: string,
  target: string,
  replacement: string,
): string => {
  // alert(input);
  // alert(target);
  // alert(replacement);
  const startIndex = input.indexOf(target);
  // alert(startIndex);
  const endIndex = startIndex + target.length;
  // alert(endIndex);
  const replacedStr =
    input.slice(0, startIndex) + replacement + input.slice(endIndex);
  // alert(replacedStr); // "Hello, John!"

  return replacedStr;
  // return input.replace(new RegExp(target, 'g'), replacement);
};

/**
 * @param {string} endpoint - API endpoint relative to `baseURL`, for example `/users`
 */
export const apiClient = <TResponseData>({
  endpoint,
  data = {},
  params = {},
  method = 'GET',
  config = {},
}: // apiVersion = 'v1',
ApiClientConfig) => {
  // Identity Server Endpoints...
  const identityEndpoints = [
    '/admin/login',
    '/admin/users',
    '/login',
    '/users',
  ];
  // let baseURL = `${CONFIG.LI3IB_API_BASE_URL}/${apiVersion}`;
  let baseURL = `${CONFIG.LI3IB_API_BASE_URL}/`;
  const hasRequestData = Object.keys(data).length > 0;
  const hasParams = Object.keys(params).length > 0;

  // Check if request is for Identity Server..
  let identityUrl = false;
  identityEndpoints.forEach((val) => {
    if (endpoint.includes(val)) {
      identityUrl = true;
    }
  });

  // alert(baseURL)

  // Replace baseUrl for Sports API if the request is for Sports APIs..
  if (!identityUrl) {
    // baseURL = replaceSubstring(baseURL, '/identity/', '/sports/');
    baseURL = replaceSubstring(baseURL, '/identity/', '/identity/');
  }

  // alert(baseURL+"---"+endpoint +"---"+identityUrl)

  return axiosInstance.request<TResponseData>({
    ...config,
    baseURL,
    url: endpoint,
    ...(hasRequestData ? { data } : undefined),
    ...(hasParams ? { params } : undefined),
    method,
    headers: {
      'Content-Type': 'application/json',
      'x-ur-toffset': getTimeZone(),
      ...config.headers,
    },
  });
};
