import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Switch, Table } from 'antd';
import { Column } from 'components';
import { StyledFiltersWrapper } from 'components/content-filters/content-filters.styles';
import { StyledTableHeader } from 'components/table/table.styled';
import { useCategoryUserStatus } from 'features/assigned-clients/use-cases/category-user-status';
import {
  ClassFilterFormValuesPaginated,
  ClassFormFilterValues,
} from 'features/master-management/components/filter-field/type';
import { useGetClientsClassUserList } from 'features/new-clients/use-cases/get-class';
import { usePaginationParams } from 'hooks';
import { FilePenLine, Trash2 } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ClassClientsUserFilterForm } from './form-filter/class-clients-user-filter';
import { AddEditClassCategoryUserModal } from './modal/add-edit-class-user';

export const AssignClassEditCategory = ({ assignvalue }: any) => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState('');
  const getInitialFilterValues: ClassFormFilterValues = {
    SubCategoryId: undefined,
    CategoryId: undefined,
    ClassName: undefined,
  };
  const [loadingStatus, setLoadingStatus] = useState<{
    [key: number]: boolean;
  }>({});
  const { mutate: CategoryUserStatus, isLoading: globalStatusLoading } =
    useCategoryUserStatus();
  const onChangeDelete = (record: any) => {
    const CetgoryDelete = {
      flag: 'DELETE',
      companyId: id, // Assuming `id` is defined elsewhere in your code
      seviceId: record.id || '', // Assuming `serviceId` is a property in `record`
      status: record.status, // Status is passed as a parameter
    };
    CategoryUserStatus(CetgoryDelete, {
      onSuccess: (data: any) => {
        if (data?.rs == 1) {
          message.success('Class Delete successfully');
        } else {
          message.error('Cannot delete Class as it is linked to a document');
        }
      },
    }); // Call mutate with data.id
  };
  const { params, updateParams, resetPage } =
    usePaginationParams<ClassFilterFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });
  const {
    data: dataList,
    isFetching,
    refetch,
  } = useGetClientsClassUserList({
    ...params,
    CompanyId: id,
  });
  const formId = 'role-filters-form';
  const handleFiltersChange = (values: ClassFormFilterValues) => {
    updateParams(values);
    resetPage();
  };
  const handleStatusChange = (record: any, checked: boolean) => {
    const formData = {
      flag: 'UPDATE',
      companyId: id,
      seviceId: record.id || '', // Use value as the service ID
      status: checked, // Send the checked value (true or false) as the updated status
    };
    // Update loading status for the record while processing
    setLoadingStatus((prev) => ({ ...prev, [record.id]: true }));
    // Call the mutate function (API call for status update)
    CategoryUserStatus(formData, {
      onSuccess: (data) => {
        setLoadingStatus((prev) => ({ ...prev, [record.id]: false }));
        if (data?.rs == 1) {
          message.success('Status Changed successfully');
        } else {
          message.error(data?.rm);
        }
      },
      onError: () => {
        setLoadingStatus((prev) => ({ ...prev, [record.id]: false }));
      },
    });
  };
  const handleOpenEdit = (item: any) => {
    setEditData(item);
    setShowModal(true);
  };
  useEffect(() => {
    if (assignvalue == 5) {
      refetch();
    }
  }, [assignvalue]);

  return (
    <Fragment>
      <ClassClientsUserFilterForm
        formId={formId}
        handleFilter={handleFiltersChange}
        initialValues={getInitialFilterValues}
      />
      <StyledFiltersWrapper className='card editbxnew grid_secton'>
        <StyledTableHeader className='gs_head'>
          <h3>
            Class List
            {/* <span>{dataList?.length > 0 ? dataList[0].totalRecords : '0'}</span> */}
          </h3>
          <div className='buttongroup'>
            <Button
              icon={<PlusCircleOutlined />}
              style={{ backgroundColor: '#223666', color: 'white' }}
              onClick={() => {
                setEditData(null as any);
                setShowModal(true);
              }}
            >
              Add New Class
            </Button>
            {showModal && (
              <AddEditClassCategoryUserModal
                onClose={() => setShowModal(false)}
                editData={editData}
                setEditData={setEditData}
                refetch={refetch}
              />
            )}
          </div>
        </StyledTableHeader>
        <div className='cstmtblbx w-full'>
          <div className='cstm_table'>
            <Table
              loading={isFetching}
              dataSource={dataList?.rc as any}
              rowKey='id'
            >
              <Column
                width={'125px'}
                title={'Sr No'}
                render={(_, __, index) => {
                  return index + 1;
                }}
              />
              <Column title={'Category Name'} dataIndex={'categoryName'} />
              <Column
                title={'Sub Category Name'}
                dataIndex={'subCategoryName'}
              />
              <Column title={'Class Name'} dataIndex={'className'} />
              <Column
                width={'125px'}
                title={'Status'}
                dataIndex={'status'}
                render={(_, record: any) => (
                  <span
                    style={{
                      color: record.isActive === true ? '#155724' : 'red', // Green for "Active", Red for "Inactive"
                    }}
                  >
                    {record?.isActive == true ? 'Active' : 'InActive'}
                  </span>
                )}
              />
              <Column
                width='135px'
                title={'Action'}
                dataIndex={'Id'}
                render={(_, record: any) => (
                  <Space className='grdbtngroup'>
                    <Button
                      className='btn'
                      type='link'
                      onClick={() => {
                        setShowModal(true);
                        handleOpenEdit(record);
                      }}
                    >
                      <FilePenLine height={16} />
                    </Button>
                    <Popconfirm
                      title='Delete the Class'
                      description='Are you sure to delete this Class?'
                      okText='Yes'
                      cancelText='No'
                      onConfirm={() => onChangeDelete(record)}
                    >
                      <Button className='btn' type='link'>
                        <Trash2 stroke='#E04343' height={16} />
                      </Button>
                    </Popconfirm>

                    <Switch
                      disabled={loadingStatus[record.id] || globalStatusLoading} // Disable if loading
                      loading={loadingStatus[record.id]} // Show loading for specific category
                      onChange={(checked) =>
                        handleStatusChange(record, checked)
                      } // Pass checked value to the handler
                      checked={record.isActive} // Ensure this reflects the actual status of the record
                    />
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </StyledFiltersWrapper>
    </Fragment>
  );
};
