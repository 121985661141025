import { Button, DatePicker, Form, Grid, Input } from 'antd';
import { ContentFilters, Select } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import {
  UserFilterFormFields,
  UserFilterFormsValues,
} from 'features/users/components/users-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: UserFilterFormsValues) => void;
  initialValues: UserFilterFormsValues;
  closeModal?: () => void;
};

export const UsersClientsFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFilterFormsValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const onFinish = (formValues: UserFilterFormsValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <Form<UserFilterFormsValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<UserFilterFormsValues>
            label={'User Name'}
            name={UserFilterFormFields.Name}
          >
            <Input placeholder={'Enter User Name'} />
          </Form.Item>
          <Form.Item<UserFilterFormsValues>
            label={'Email'}
            name={UserFilterFormFields.Email}
          >
            <Input placeholder={'Enter Email'} />
          </Form.Item>
          <Form.Item<UserFilterFormsValues>
            label={'User Role'}
            name={UserFilterFormFields.RoleName}
          >
            <Input placeholder={'Enter Role'} />
          </Form.Item>
          <Form.Item<UserFilterFormsValues>
            label={'Date Added'}
            name={'CreatedDate' as any}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item<UserFilterFormsValues>
            label='Status'
            name={UserFilterFormFields.IsActive}
          >
            <Select
              options={[
                { value: 'true', label: 'Active' },
                { value: 'false', label: 'InActive' },
              ]}
              placeholder='Please select'
            />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{'Clear'}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
