import { Form, Grid } from 'antd';
import { Modal } from 'components';
import { UserInfoFormItems } from 'features/users/components/modals/user-info-form-items';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useCreateUser } from 'features/users/use-cases/create-user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
const { useBreakpoint } = Grid;
export const AddAdminUserModal = ({
  onClose,
  type = 0,
}: {
  onClose: () => void;
  type?: number;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [form] = Form.useForm<UserFormValues>();
  const { t } = useTranslation();
  const { isLoading, mutate } = useCreateUser();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };

  const handleUpdate = (formValues: any) => {
    try {
      const file = fileList[0]?.originFileObj;
      const formData = new FormData();
      formData.append('UserName', formValues.UserName?.trim());
      formData.append('RoleId', formValues.RoleId);
      formData.append('Email', formValues.Email?.trim());
      formData.append('file', file); // Include the entire file object
      formData.append('FileName', file.name);
      mutate(formData, { onSuccess: onClose });
    } catch (error) {
      console.error('Error updating brand:', error);
    }
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={t('usermanagement.addClient.label')}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('adminUsers.addNewUser')}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <UserInfoFormItems
            fileList={fileList}
            setFileList={setFileList}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
            type={type}
          />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
