import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { Role } from 'types/role';
export interface RoleListParams extends PaginatedRequestParams {
  full_user_name?: string;
  phone_number?: string;
  roles?: string[];
  retry?: boolean;
  company_id?: string;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}
export type CreateRoleDTO = {
  UserName?: string;
  Email?: string | null;
  RoleId: string;
};
export type RoleResponse = PaginatedResponse<Role>;

export const RoleApiService = () => {
  const createRolePermission = async (
    payload: CreateRoleDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Role>({
      ...callConfig,
      endpoint: `/RoleManagement/SetRoleList`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getRoleId = async (
    RoleId: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Role>({
      ...callConfig,
      endpoint: `/RoleManagement/GetPermissionlist?RoleId=${RoleId}`,
    });

    return response.data;
  };
  const getRole = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<RoleResponse>({
      ...callConfig,
      endpoint: `/RoleManagement/GetRoleList`,
      params,
    });

    return response.data;
  };
  const getRolePermission = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<RoleResponse>({
      ...callConfig,
      endpoint: `/RoleManagement/GetPermissionlist`,
      params,
    });

    return response.data;
  };
  const deleteRole = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<RoleResponse>({
      ...callConfig,
      endpoint: `/RoleManagement/DeleteRole/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'DELETE',
    });

    return response.data;
  };

  return {
    getRole,
    getRolePermission,
    createRolePermission,
    getRoleId,
    deleteRole,
  };
};
