import { AliasToken } from 'antd/es/theme/internal';

type CustomTokens = Pick<AliasToken, 'colorPrimary' | 'colorPrimaryText'>;

export const customAntColors: CustomTokens = {
  colorPrimary: '#223666',
  colorPrimaryText: '#12361F',
};

export const colors = {
  ...customAntColors,
  // neutral palette is not a part of the antd colors scheme
  gray1: '#000000',
  gray2: '#fafafa',
  gray3: '#f5f5f5',
  gray4: '#f0f0f0',
  gray5: '#d9d9d9',
  gray6: '#bfbfbf',
  gray7: '#8c8c8c',
  gray8: '#595959',
  gray9: '#434343',
  gray10: '#262626',
  gray11: '#1F1F1F',
  gray12: '#141414',
  gray13: '#000000',
  greenDark: '#ffffff',
  greenLight: '#00B465',
  greenExtraLight: '#D4ECDE',
  pink: '#FFDAE0',
  pureWhite: '#0000001',
  pureBlack: '#000000',

  /** @token app/Bright Red */
  redBright: '#FF2A00',
  /** @token li3ibgreen */
  li3ibgreen1: '#DAE8DC',
  /** @token li3ibgreen/2 */
  li3ibgreen2: '#CEDBD0',
  /** @token li3ibgreen/5 */
  li3ibgreen5: '#76B584',
  /** @token red/3 */
  red3: '#FFA39E',
  /** @token red/5 */
  red5: '#FF4D4F',

  /* New Theme Color Code Start */

  defaultColor: '#20356A',
  labelcolor: '#272C2A',
  bluedV2: '#263448',
  blue_light: '#445FA1',
  lightgray: '#E2E8F0',

  grayfolder: '#7D819E',
  hoverbg: '#DCE1ED',

  leftmenu: '#282938',
  leftmenudefault: '#BEBEC3',

  defaultbordercolor: '#dde1ef',
  bggridheader: '#F5F5F5',
  defaultheaderbordercolor: '#e0e0e0',

  /* New Theme Color Code End */
};
