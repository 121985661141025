import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { DashBoardApiService } from 'features/Dashboard/api/dashboard-api.service';
import { CategoryResponse } from 'features/master-management/api/master-api.service';
import { RoleListParams } from 'features/role-management/api/role-api.service';
import { UserListParams } from 'features/users';
import qs from 'qs';

import { DashBoardQueryKeys } from './query-keys';
// ../api/dashboard-api.service
const { getDashBoard } = DashBoardApiService();

const getUsersQuery = (
  params: RoleListParams,
  options?: UseQueryOptions<CategoryResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<CategoryResponse>;
  options?: UseQueryOptions<CategoryResponse>;
} => ({
  queryKey: DashBoardQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getDashBoard(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetCardList = (
  params: UserListParams,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
