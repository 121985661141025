import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { MasterApiService } from 'features/master-management/api/master-api.service';
import { queryClient } from 'providers';

import { MasterQueryKeys } from './query-keys';

const { deleteSubCategory } = MasterApiService();

export const useDeleteSubCategory = () => {
  const { message } = App.useApp();

  return useMutation(
    (id: string) => {
      return deleteSubCategory(id); // Pass the subscription id to the delete function
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);
        message.error(uiMessage); // Display error message
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: MasterQueryKeys.all, // Invalidate subscription queries to refresh the data
        });
        message.success('SubCategory deleted successfully'); // Display success message
      },
    },
  );
};
