import dayjs from 'dayjs';
import { AssignTable } from 'features/assigned-clients/components/assign-table/users-table';
import {
  AssignFilterFormPaginated,
  assignFormFilterValue,
} from 'features/assigned-clients/components/form-filter/type';
import { AssignTopBar } from 'features/assigned-clients/components/top-bar/assign-top-bar';
import { useGetAssignList } from 'features/assigned-clients/use-cases/get-assign';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';
const getInitialFilterValues: assignFormFilterValue = {
  Name: undefined,
  Email: undefined,
  PhoneNumber: undefined,
  BusinessName: undefined,
  date: [undefined, undefined],
  StatusId: undefined,
  AssignClientName: undefined,
};

export const AssignClientsView = () => {
  const [showModal, setShowModal] = useState(false);
  const [assignedData, setAssinedData] = useState('');
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const { params, updateParams, resetPage } =
    usePaginationParams<AssignFilterFormPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });
  const preparePayload = (paramsw: any) => {
    const createdAtFrom = paramsw.date?.[0];
    const createdAtTo = paramsw.date?.[1];

    return {
      ...omit(params, ['date']),
      LastUpdateFrom: createdAtFrom
        ? formatDate({
            date: dayjs(createdAtFrom).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      LastUpdateTo: createdAtTo
        ? formatDate({
            date: dayjs(createdAtTo).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
    };
  };
  const getUsersListQueryResponse = useGetAssignList(preparePayload(params), {
    retry: false,
  });
  const handleFiltersChange = (values: assignFormFilterValue) => {
    updateParams(values);
    resetPage();
  };

  return (
    <>
      <AssignTopBar
        handleFilters={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
        setShowModal={setShowModal}
        showModal={showModal}
        assignedData={assignedData}
        setSubscriptionModal={setSubscriptionModal}
        subscriptionModal={subscriptionModal}
      />
      <AssignTable
        isListLoading={getUsersListQueryResponse.isLoading}
        dataList={getUsersListQueryResponse?.data?.rc as any}
        itemsCount={getUsersListQueryResponse.data?.total || 0}
        tableParams={params}
        updateParams={updateParams}
        setAssinedData={setAssinedData}
        setShowModal={setShowModal}
        setSubscriptionModal={setSubscriptionModal}
      />
      <Outlet />
    </>
  );
};
