export const statusStyle = (status: string) => {
  switch (status) {
    case 'Pending Review':
      return { color: 'blue' };
    case 'Approved':
      return { color: 'green' };
    case 'Pending Document':
      return { color: 'red' };
    default:
      return {};
  }
};
