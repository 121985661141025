import { EllipsisOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Flex,
  Menu,
  Row,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useGetRejectedDocumentList } from 'features/assigned-clients/use-cases/reject-document';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Fragment, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const { Title } = Typography;
export const RejectDocumentList = ({
  year,
  month,
  searchName,
  activeTabValue,
}: // selectedValue,
any) => {
  const { id: CompanyId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const {
    data: rejectedDocument,
    isFetching,
    refetch,
  } = useGetRejectedDocumentList({
    CompanyId,
    DocumentStatusId: 3,
    Year: year,
    Month: month,
    Name: searchName,
    ClientId: ids,
    // PeriodId: selectedValue || 0,
  });
  useEffect(() => {
    if (activeTabValue == '3') {
      refetch();
    }
  }, [refetch, year, month, searchName, activeTabValue]);
  
return (
    <Fragment>
      {isFetching ? (
        <Spin className='ldrloder' />
      ) : (
        <Row className='fldrbx'>
          {Array.isArray(rejectedDocument?.rc) &&
          rejectedDocument?.rc?.length ? (
            rejectedDocument?.rc?.map((item: any, index: number) => (
              <Card key={index}>
                <Flex>
                  <Typography className='meidafolder'>
                    <FileViewer documentPath={item.documentPath} />
                  </Typography>

                  <Typography className='cvnu'>
                    <Title className='crdttl' level={3}>
                      {item.documentName}asd
                      <dt className='stsbx'>
                        <dt
                          className={
                            item.documentStatus === 'Approved'
                              ? 'approved'
                              : item.documentStatus == 'Pending'
                              ? 'pending'
                              : 'rejected'
                          }
                        >
                          {item?.documentStatus == 'Reject'
                            ? 'Rejected'
                            : item?.documentStatus}
                        </dt>
                      </dt>
                      <dt className='mnubdrp'>
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key='download'>
                                <a
                                  href={item.documentPath}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  Download
                                </a>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['click']}
                          placement='bottomRight'
                        >
                          <Button type='text' icon={<EllipsisOutlined />} />
                        </Dropdown>
                      </dt>
                    </Title>
                    <dt className='tmrbx'>
                      {item?.totalComments
                        ? `${item?.totalComments} Comments`
                        : '0 Comments'}
                    </dt>
                    {item.documentSize != null ? (
                      <dt className='tmrbx'>Size : {item.documentSize} </dt>
                    ) : (
                      ''
                    )}

                    <dt className='tmrbx'>
                      Last Update:{' '}
                      {dayjs(item.lastUpdatedDate).format(
                        'DD MMM YYYY | hh:mm A',
                      )}
                      {/* 24 Sep, 2024 | 03:11 PM */}
                    </dt>
                  </Typography>
                </Flex>
              </Card>
            ))
          ) : (
            <Col span={24}>
              <Empty description='No Rejected Documents Found' />
            </Col>
          )}
        </Row>
      )}
    </Fragment>
  );
};
