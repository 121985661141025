import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { AddRoleModal } from 'features/role-management/components/modal/add-role';
import { RoleFilterFormValuesPaginated } from 'features/role-management/components/role-filter-field/type';
import { useDeleteRole } from 'features/role-management/use-cases/delete-role';
import { usePermissions } from 'features/users';
import { FilePenLine, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from 'types/role';
// ../modal/add-role
type RoleTableProps = {
  roleList: Role[];
  isRoleListLoading: boolean;
  tableParams: RoleFilterFormValuesPaginated;
  setEditRoleModal: any;
  setEditRoleData: any;
};

export const RoleItemView = ({
  roleList,
  isRoleListLoading,
  tableParams,
  setEditRoleModal,
  setEditRoleData,
}: RoleTableProps) => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const { t } = useTranslation();
  const permissions = usePermissions();
  const { mutate } = useDeleteRole();
  if (!permissions) return null;

  const handleOpenModal = (record: any) => {
    setEditRoleModal(true);
    setEditRoleData(record);
  };
  const onChangeDelete = (data: any) => {
    mutate(data.Id); // Call mutate with data.id
  };

  return (
    <StyledTableWrapper className='card grid_secton'>
      {/* <StyledTableHeader></StyledTableHeader> */}

      <StyledTableHeader className='gs_head'>
        <h3>
          Role Management{' '}
          {/* <span>{dataList?.length > 0 ? dataList[0].totalRecords : '0'}</span> */}
        </h3>

        <div className='buttongroup'>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => setShowAddUserModal(true)}
            style={{ backgroundColor: '#223666', color: 'white' }}
          >
            Add New Role
          </Button>
          {showAddUserModal && (
            <AddRoleModal onClose={() => setShowAddUserModal(false)} />
          )}
        </div>
      </StyledTableHeader>

      <div className='cstmtblbx'>
        <div className='cstm_table'>
          <Table<Role>
            loading={isRoleListLoading}
            dataSource={roleList}
            rowKey='id'
            // onChange={handleTableChange}
            pagination={{
              current: tableParams.PageIndex,
              defaultPageSize: tableParams.RecordPerPage,
              total: 10,
            }}
            components={{
              header: {
                cell: (
                  props: React.HTMLAttributes<HTMLTableHeaderCellElement>,
                ) => <th {...props} />,
              },
            }}
          >
            <Column<Role> title={'Name'} dataIndex={'RoleName'} />
            <Column<Role>
              width='125px'
              title={t('usermanagement.table.status')}
              dataIndex={'role'}
              render={(_, record: any) => (
                <Space>
                  <Typography
                    style={{
                      color: record.IsActive == true ? '#155724' : 'red', // Green for "Active", Red for "Inactive"
                    }}
                  >
                    {record.IsActive == true ? 'Active' : 'Inactive'}
                  </Typography>
                </Space>
              )}
            />
            <Column<Role>
              width='150px'
              title={t('usermanagement.table.action')}
              dataIndex={'Id'}
              render={(_, record: any) => (
                <Space className='grdbtngroup'>
                  <Button
                    className='btn'
                    type='link'
                    onClick={() => handleOpenModal(record)}
                  >
                    <FilePenLine height={16} />
                  </Button>
                  <Popconfirm
                    title='Delete the Role'
                    description='Are you sure to delete this Role?'
                    onConfirm={() => onChangeDelete(record)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button className='btn' type='link'>
                      <Trash2 stroke='#E04343' height={16} />
                    </Button>
                  </Popconfirm>
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </StyledTableWrapper>
  );
};
