import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { categorymaster } from 'types/master';
export interface RoleListParams extends PaginatedRequestParams {
  full_user_name?: string;
  phone_number?: string;
  roles?: string[];
  retry?: boolean;
  company_id?: string;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}
export type CreateCategoryDTO = {
  categoryName?: string;
  id?: string;
};
export type CreateSubCategoryDTO = {
  categoryName?: string;
  id?: string;
};
export type CreateClassDTO = {
  className?: string;
  subCategoryId?: string;
  categoryId?: string;
  frequencyId?: string;
};
export type CategoryResponse = PaginatedResponse<categorymaster>;

export const DashBoardApiService = () => {
  const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
  const getDashBoard = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/AdminDashboard/DashboardStats`,
      params,
    });

    return response.data;
  };
  const getRegistrationGraph = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/AdminDashboard/ClientRegistrationGraph`,
      params,
    });

    return response.data;
  };
  const getRegistrationGraphDrildown = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/AdminDashboard/ClientRegistrationDrillDownGraph`,
      params,
    });

    return response.data;
  };
  const getClientStatusGraph = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/AdminDashboard/ClientStatusGraph`,
      params,
    });

    return response.data;
  };
  const getDocumentStatusGraph = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/AdminDashboard/DocumentStatusGraph`,
      params,
    });

    return response.data;
  };
  const GetNotifications = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/AdminDashboard/GetNotifications`,
      params,
    });

    return response.data;
  };
  const GetNotificationsCount = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/AdminDashboard/GetNotificationsCount`,
      params,
    });

    return response.data;
  };

  return {
    getDashBoard,
    getRegistrationGraph,
    getClientStatusGraph,
    getDocumentStatusGraph,
    GetNotifications,
    getRegistrationGraphDrildown,
    GetNotificationsCount,
  };
};
