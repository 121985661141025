import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AuthApiService } from 'features/auth/api/auth-api.service';

const { ResendOtpApi } = AuthApiService();

export const useCreateResendOtp = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return ResendOtpApi(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        if (data?.rm == 2) {
          message.error(data?.rm);
        } else {
          message.success(data?.rm);
        }
      },
    },
  );
};
