import {
  Button,
  Col,
  Form,
  message,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import { ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { StyledWrapper } from 'components/content-top-bar/top-content-bar.styles';
import { AddCoustmizeModal } from 'features/new-clients/components/modal/coustmize-view';
import { useCreateClients } from 'features/new-clients/use-cases/create.clients';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { useUserContext } from 'features/users';
import { t } from 'i18next';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';

import { AddNewInfo } from './add-new-clients-info';
import { AssignFormField, ClientsFormValues } from './type';
// import { useNavigate } from 'react-router-dom';
const { Title } = Typography;
type AddAssignProps = {
  onCancel: () => void;
  onSuccess: () => void;
};
type cityName = {
  text: string;
  value: number;
};
export const AddNewClients = ({ onCancel }: AddAssignProps) => {
  // const naivate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [subpayload, setSubCategoryPayload] = useState('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const { data: Subscription } = useDropDownFlag('Subscription');
  const { user } = useUserContext();
  const [form] = Form.useForm();
  const { mutate, isLoading } = useCreateClients();
  const onFinish = (formdata: any) => {
    try {
      const file = fileList[0]?.originFileObj;
      // if (file === undefined) {
      //   message.error('Please upload Image');

      //   return;
      // }
      const formData = new FormData();
      formData.append('LoggedInUserId', user?.Id);
      formData.append('ClientName', formdata.ClientName);
      formData.append('Email', formdata.Email);
      formData.append('PhoneNumber', formdata.PhoneNumber);
      formData.append('IndustryId', formdata.IndustryId);
      formData.append('BusinessName', formdata.BusinessName);
      formData.append('BusinessWebsite', formdata.BusinessWebsite);
      formData.append('Address', formdata.Address);
      formData.append('CityId', formdata.CityId);
      formData.append('Description', formdata.Description);
      formData.append('SubscriptionId', formdata.SubscriptionId);
      formData.append('SubCategoryAccess', formdata.SubCategoryAccess);
      formData.append('SendMailAccess', formdata.SendMailAccess);
      formData.append('file', file);
      if (file?.name) {
        formData.append('FileName', file.name);
      } else {
        formData.append('FileName', '');
      }
      formData.append(
        'ServiceListTemp',
        subpayload ? JSON.stringify(subpayload) : '',
      );
      // formData.append('ServiceList', JSON.stringify(subpayload));
      mutate(formData, {
        onSuccess: (data: any) => {
          if (data?.rs == 2) {
            message.error(data?.rm);
          } else {
            onCancel();
            message.success('Client Created successfully');
          }
        },
      });
    } catch (error) {
      console.error('Error updating brand:', error);
    }
  };

  return (
    <StyledContentBackground>
      <figure
        className='cncbx'
        onClick={() => window.history.back()}
        role='button'
      >
        <ChevronLeft role='button' className='callbackarrow' />
        <ContentTopBar title='Create New Clients' />
      </figure>

      {/* <ContentTopBar title='Create New Clients' /> */}

      <StyledWrapper className='card'>
        <Form<ClientsFormValues>
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete='off'
          layout={'vertical'}
        >
          <AddNewInfo
            fileList={fileList}
            setFileList={setFileList}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
          />

          <Row gutter={24}>
            <Col xs={24} lg={4}>
              &nbsp;
            </Col>
            <Col className='sssbx' xs={24} lg={20}>
              <Title level={3}>Subscription information</Title>

              <Row gutter={24}>
                <Col xs={24} lg={6}>
                  <Form.Item
                    className=''
                    label={'Subscription'}
                    rules={[
                      { required: true, message: 'Subscription is required' },
                    ]}
                    name={AssignFormField.SubscriptionId}
                  >
                    <Select
                      options={Subscription?.data?.rc.map((item: cityName) => ({
                        label: item.text, // Use `label` for the display value
                        value: item.value, // Use `value` for the actual value
                      }))}
                      placeholder='Please Select'
                    />
                  </Form.Item>

                  <Form.Item
                    label={'Sub-Category access'}
                    name='SubCategoryAccess'
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error('Please enable Sub-Category Access.'),
                              ),
                      },
                    ]}
                    valuePropName='checked' // Required for Switch to bind the boolean value
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    label={'Send Mail'}
                    name='SendMailAccess'
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error('Please enable Send Mail Access.'),
                              ),
                      },
                    ]}
                    valuePropName='checked' // Binds the `checked` value to Form data
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item label={'Customize Access'}>
                    <Button type='primary' onClick={() => setShowModal(true)}>
                      Select
                    </Button>
                  </Form.Item>

                  <Space className='button_group' size={20}>
                    <Form.Item wrapperCol={{}}>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={isLoading}
                      >
                        {t('newclient.formFields.submitbutton.button')}
                      </Button>
                    </Form.Item>
                    <Form.Item wrapperCol={{}}>
                      <Button onClick={() => form.resetFields()}>
                        {t('newclient.formFields.cancel.button')}
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        {/* Modal for send Mail */}
        {showModal && (
          <AddCoustmizeModal
            onClose={() => setShowModal(false)}
            setSubCategoryPayload={setSubCategoryPayload}
          />
        )}
      </StyledWrapper>
    </StyledContentBackground>
  );
};
