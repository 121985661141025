import { EditOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Grid,
  Image,
  Input,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { DocumentView } from 'features/assigned-clients/components/assign-details/documents/document-view';
import { RejectDocumentList } from 'features/assigned-clients/components/assign-details/reject-document/reject-document';
import { RequestDocumentView } from 'features/assigned-clients/components/requested-documents/requested-documents';
import { useDocumentsCount } from 'features/assigned-clients/use-cases/document-count';
import { useGetAssignDetails } from 'features/assigned-clients/use-cases/get-assignId';
import { useGetMarkFinished } from 'features/assigned-clients/use-cases/get-markFinished';
import { ChevronLeft } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { disabledDate, getRoute } from 'routes/utils';

import { AttentionDocumentView } from './attention-document/attention-document';
import { CommentsItemView } from './comments/add-comments';
import { AddDocumentsModal } from './documents-modal/add-document';
import { ManagementReportView } from './management-report/management-report';
const { Title } = Typography;

export const AssignViewInfoDetails = ({ Id }: any) => {
  const naivate = useNavigate();
  const location = useLocation();
  const searchParams: any = new URLSearchParams(location.search);
  const id = searchParams.get('clsid');
  const { mutate: getMarkfinished, data: finishedValue } = useGetMarkFinished();
  const activeTabValue = searchParams.get('activeTab');
  const { data: clientData } = useGetAssignDetails(id as any);
  const clientId: any = clientData?.rc[0]?.clientId;
  const [addCommentsModal, setAddCommentsModal] = useState(false);
  const [commentsInfo, setCommentsInfo] = useState('');
  const [documentUploadModal, setDocumentUploadModal] = useState(false);
  const [year, setYear] = useState(dayjs().year().toString());
  const [month, setMonth] = useState((dayjs().month() + 1).toString()); // Month is 0-indexed
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [commentsMarkInfo, setCommentsMarkInfo] = useState('');
  const [movetoModal, setMoveToModal] = useState(false);
  const [documentInfo, setDocumentsInfo] = useState('');
  const [searchName, setSearchName] = useState('');
  const [activeTab, setActiveTab] = useState<string>(
    localStorage.getItem('activeTab') || '1',
  );
  const { mutate: getCount, data: countvalue } = useDocumentsCount();
  const getDocumentsCount = () => {
    const formdata = {
      companyId: Id,
      Year: +year,
      Month: +month,
    };
    getCount(formdata);
  };
  const handleDateChange = (date: any) => {
    if (date) {
      // Update to the selected date
      setSelectedDate(date);
      setYear(date.year().toString());
      setMonth((date.month() + 1).toString());
    } else {
      // Reset to the current date when cleared
      const currentDate = dayjs();
      setSelectedDate(currentDate);
      setYear(currentDate.year().toString());
      setMonth((currentDate.month() + 1).toString());
    }
  };
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const initilaMarkFinished = () => {
    const FinishedData: any = {
      companyId: Id,
      Year: +year,
      Month: +month,
      ClientId: id,
      // periodId: selectedValue,
    };
    getMarkfinished(FinishedData);
  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Badge count={countvalue?.rc[0]?.pendingDocument}>Documents</Badge>
      ),
      children: (
        <DocumentView
          setAddCommentsModal={setAddCommentsModal}
          setCommentsInfo={setCommentsInfo}
          setDocumentUploadModal={setDocumentUploadModal}
          setMoveToModal={setMoveToModal}
          setDocumentsInfo={setDocumentsInfo}
          movetoModal={movetoModal}
          documentInfo={documentInfo}
          searchName={searchName}
          year={year}
          month={month}
          getDocumentsCount={getDocumentsCount}
          finishedValue={finishedValue}
          initilaMarkFinished={initilaMarkFinished}
        />
      ),
    },
    {
      key: '2',
      label: (
        <Badge count={countvalue?.rc[0]?.requestedPendingDocument}>
          Requested Documents
        </Badge>
      ),
      children: (
        <RequestDocumentView
          clientId={clientId}
          setAddCommentsModal={setAddCommentsModal}
          setCommentsInfo={setCommentsInfo}
          setMoveToModal={setMoveToModal}
          setDocumentsInfo={setDocumentsInfo}
          year={year}
          month={month}
          searchName={searchName}
          // selectedValue={selectedValue}
          // selectFilterValue={selectFilterValue}
          getDocumentsCount={getDocumentsCount}
          initilaMarkFinished={initilaMarkFinished}
          finishedValue={finishedValue}
        />
      ),
    },
    {
      key: '3',
      label: (
        <Badge count={countvalue?.rc[0]?.rejectedDocument}>
          Rejected Documents
        </Badge>
      ),
      children: (
        <RejectDocumentList
          year={year}
          month={month}
          searchName={searchName}
          activeTabValue={activeTabValue}
          // selectedValue={selectedValue}
        />
      ),
    },
    {
      key: '4',
      label: (
        <Badge count={countvalue?.rc[0]?.attentionDocument}>
          Attention Documents
        </Badge>
      ),
      children: (
        <AttentionDocumentView
          year={year}
          month={month}
          searchName={searchName}
          setAddCommentsModal={setAddCommentsModal}
          setCommentsInfo={setCommentsInfo}
          setCommentsMarkInfo={setCommentsMarkInfo}
          // selectedValue={selectedValue}
        />
      ),
    },
    {
      key: '5',
      label: <Badge>Management Report</Badge>,
      children: (
        <ManagementReportView
          year={year}
          month={month}
          searchName={searchName}
          activeTabValue={activeTabValue}
        />
      ),
    },
  ];
  const handleTabChange = (key: string) => {
    setActiveTab(key);
    localStorage.setItem('activeTab', key); // Save active tab to local storage
    const searchParams1 = new URLSearchParams(location.search);
    if (key) {
      searchParams1.set('activeTab', key); // Set activeTab in URL
    } else {
      searchParams1.delete('activeTab'); // Remove activeTab from URL if empty
    }
    naivate(`${location.pathname}?${searchParams1.toString()}`); // Update URL without refreshing
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };
  useEffect(() => {
    getDocumentsCount();
  }, []);

  useEffect(() => {
    if (activeTabValue) {
      setActiveTab(activeTabValue);
    }
  }, []);
  useEffect(() => {
    initilaMarkFinished();
  }, [year, month]);

  return (
    <Fragment>
      <Row align='top' className='headbx hdwthicn'>
        <Col span={3} className='hd_media bxicon'>
          {screens.lg ? (
            <Image
              src={
                clientData?.rc[0]?.clientImage ||
                'https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg'
              }
            />
          ) : (
            <ChevronLeft
              onClick={() => naivate('/assign-client')}
              className='callbackarrow'
            />
            // <ArrowLeft  />
          )}
        </Col>

        <Col span={21} className='hd_lbl'>
          <Title onClick={() => naivate('/assign-client')} level={4}>
            {clientData?.rc[0]?.clientName}
          </Title>
          <Typography className='tg_sts'>
            <dt>{clientData?.rc[0]?.businessName}</dt>
            <dt
              className='status'
              style={{
                background: clientData?.rc[0]?.status ? '#D7E8D3' : '#D4EDDA',
                color: clientData?.rc[0]?.status ? '#61AE41' : '#721C24',
              }}
            >
              {clientData?.rc[0]?.status == true ? 'Active' : 'InActive'}
            </dt>
          </Typography>

          <Typography className='tg_dte'>
            <dt>Last Update :</dt>
            <dt>
              {
                clientData?.rc[0]?.lastUpdate
                  ? dayjs(clientData.rc[0].lastUpdate)
                      .add(5, 'day') // Add 5 days to the date
                      .format('D MMM, YYYY | h:mm A')
                  : 'N/A' // Fallback in case data is missing
              }
            </dt>
          </Typography>

          <Typography className='right_btn'>
            <Button
              className='btn '
              type='primary'
              onClick={() => {
                naivate(
                  `${getRoute(ROUTES.ASSIGNED_EDIT, Id)}?temp_clie=${id}`,
                );
              }}
            >
              <EditOutlined /> Edit
            </Button>
          </Typography>
        </Col>
      </Row>

      <Row className='card'>
        <Col span={24}>
          <Row className='crd_head acbx'>
            <Col span={12}>
              <Title level={4}>Documents</Title>
            </Col>
            <Col span={12}>
              <Flex align='flex-end' justify='end' className='srchrbx'>
                <Form.Item>
                  <Input
                    className='form-control'
                    placeholder='Search by Name'
                    value={searchName} // Controlled input value
                    onChange={handleSearchChange}
                  />
                </Form.Item>
                <Space direction='vertical' className=''>
                  <DatePicker
                    className='form-control'
                    picker='month'
                    value={selectedDate} // Controlled value
                    defaultValue={dayjs()} // Default to current month/year
                    onChange={handleDateChange as any}
                    disabledDate={disabledDate}
                    allowClear={false}
                  />
                </Space>
              </Flex>
            </Col>
          </Row>

          <Col span={24} className='cstm_tabs'>
            <Tabs
              className='tsbrow'
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
            />
          </Col>
        </Col>
      </Row>
      {addCommentsModal && (
        <CommentsItemView
          onClose={() => setAddCommentsModal(false)}
          commentsInfo={commentsInfo}
          commentsMarkInfo={commentsMarkInfo}
        />
      )}
      {documentUploadModal && (
        <AddDocumentsModal
          onClose={() => setDocumentUploadModal(false)}
          // selectedValue={selectedValue}
          year={year}
          month={month}
        />
      )}
    </Fragment>
  );
};
