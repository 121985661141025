import { CardInfo } from 'features/Dashboard/components/card-data/card-info';
import { ClientsDocuments } from 'features/Dashboard/components/clients-documents/clients-documents';
import GraphClientsInfo from 'features/Dashboard/components/graph-data/graph-data-info';
import { NotificationDocuments } from 'features/Dashboard/components/notification/notification-documents';
const DashBoardView = () => {
  return (
    <div className='dshbrd'>
      <div className='dscell'>
        <CardInfo />
        <GraphClientsInfo />
        <ClientsDocuments />
      </div>
      <div className='dscell rtbx'>
        <NotificationDocuments />
      </div>
    </div>
  );
};

export default DashBoardView;
