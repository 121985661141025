import { Form, Grid, message } from 'antd';
import { Modal } from 'components';
import { useCreateDocuments } from 'features/assigned-clients/use-cases/create-documents';
import { useGetDocumentCategoryList } from 'features/assigned-clients/use-cases/get-category-document';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DocumentsInfoFormItems } from './document-info';

const { useBreakpoint } = Grid;

export const AddDocumentsModal = ({
  onClose,
  // selectedValue,
  year,
  month,
}: {
  onClose: () => void;
  // selectedValue: any;
  year: any;
  month: any;
}) => {
  const { id } = useParams();
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFormValues>();
  const { data } = useGetCategoryList({
    CompanyId: id,
  });
  const { refetch } = useGetDocumentCategoryList(id);
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const { isLoading, mutate } = useCreateDocuments();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };

  const handleUpdate = (formValues: any) => {
    const IsClientUpload: any = false;
    const formData = new FormData();
    // Ensure the file list is not empty
    const file = fileList[0]?.originFileObj;
    if (file === undefined) {
      message.error('Please upload an image');

      return; // Stop the function execution if no file is uploaded
    }
    formData.append('DocumentName', formValues.DocumentName);
    formData.append('CompanyId', id as any);
    formData.append('IsClientUpload', IsClientUpload);
    // formData.append('PeriodId', selectedValue);
    formData.append('Month', month);
    formData.append('Year', year);
    // Determine the serviceId based on the selection priority
    let serviceId = formValues.class; // Default to class
    if (formValues.category && formValues.SubCategory) {
      serviceId = formValues.SubCategory; // If both category and subcategory are selected, use subcategory
    } else if (formValues.category) {
      serviceId = formValues.category; // If only category is selected, use category
    }
    // Override if all three are selected to use `class`
    if (formValues.category && formValues.SubCategory && formValues.class) {
      serviceId = formValues.class; // If all three are selected, prioritize class
    }
    formData.append('ServiceId', serviceId); // Use the resolved serviceId
    // Append all files to the FormData object
    fileList.forEach((fileItem: any) => {
      const file1 = fileItem.originFileObj;
      if (file1) {
        formData.append('file', file1); // Append each file object
        formData.append('FileName', file1.name); // Append the file name
      }
    });

    mutate(formData, {
      onSuccess: () => {
        onClose(); // Close modal or reset form
        refetch(); // Refetch the document list to get updated data
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={<span className='font-semibold'>Upload Documents</span>}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText={'Save'}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
          className: 'btn cstmfull',
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <DocumentsInfoFormItems
            data={data}
            setFileList={setFileList}
            setPreviewImage={setPreviewImage}
            setPreviewOpen={setPreviewOpen}
            fileList={fileList}
            previewImage={previewImage}
            previewOpen={previewOpen}
          />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
