import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';

export enum UserFilterFormField {
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
  Role = 'role',
  Company = 'company_id',
  Email = 'email',
}
export enum UserFilterFormFields {
  Name = 'Name',
  Email = 'Email',
  RoleName = 'RoleName',
  IsActive = 'IsActive',
  DateAdded = 'CreatedDate',
}
export type UserFilterFormsValues = {
  [UserFilterFormFields.Name]: string | undefined;
  [UserFilterFormFields.Email]: string | undefined;
  [UserFilterFormFields.RoleName]: string | undefined;
  [UserFilterFormFields.IsActive]: string | undefined;
  [UserFilterFormFields.DateAdded]: string | undefined;
};
export type UserFiltersFormValuesPaginated = UserFilterFormsValues &
  PaginatedRequestParams;

export type UserFilterFormValues = {
  [UserFilterFormField.FullUserName]: string | undefined;
  [UserFilterFormField.PhoneNumber]: string | undefined;
  [UserFilterFormField.Email]: string | undefined;
  [UserFilterFormField.Role]: string | undefined;
  [UserFilterFormField.Company]: string | undefined;
};

export type UserFilterFormValuesPaginated = UserFilterFormValues &
  PaginatedRequestParams;

export enum EndUserFilterFormField {
  Date = 'date',
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
  Email = 'email',
}

export type EndUserFilterFormValues = {
  [EndUserFilterFormField.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
  [EndUserFilterFormField.FullUserName]: string | undefined;
  [EndUserFilterFormField.PhoneNumber]: string | undefined;
  [EndUserFilterFormField.Email]: string | undefined;
};

export type EndUserFilterFormValuesPaginated = EndUserFilterFormValues &
  PaginatedRequestParams;

export enum UserFormField {
  UserName = 'UserName',
  Role = 'RoleId',
  Email = 'Email',
  UserProfile = 'profile',
}
export enum UserFormFieldData {
  user = 'User',
  Li3ibAdmin = 'Li3ibAdmin',
}

export type UserFormValues = {
  [UserFormField.UserName]: string;
  [UserFormField.Role]: string;
  [UserFormField.Email]: string;
  [UserFormField.UserProfile]: string;
};

export enum EndUserFormField {
  FirstName = 'first_name',
  LastName = 'last_name',
  Gender = 'gender',
  Email = 'email',
  PhoneNumber = 'phone_number',
}

export type EndUserFormValues = {
  [UserFormField.Email]: string;
};
