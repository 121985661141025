import { Form, Input, Select } from 'antd';
import { SubcategoryFormField } from 'features/master-management/components/filter-field/type';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { Fragment } from 'react';
type FrequencyName = {
  text: string;
  value: number;
};
export const SubCategoryInfoFormItems = ({ data }: any) => {
  const { data: Frequency } = useDropDownFlag('Frequency');

  return (
    <Fragment>
      <Form.Item<SubcategoryFormField>
        name={SubcategoryFormField.SubCategoryName}
        label={'Sub Category Name'}
        rules={[
          {
            required: true,
            whitespace: false,
          },
        ]}
      >
        <Input placeholder={'Enter CategoryName'} />
      </Form.Item>
      <Form.Item<SubcategoryFormField>
        name={SubcategoryFormField.CategoryId}
        label={'Category'}
      >
        <Select
          options={data?.rc?.map((item: any) => ({
            label: item.categoryName,
            value: item.id,
          }))}
          placeholder='please select'
        />
      </Form.Item>
      <Form.Item<SubcategoryFormField>
        name={SubcategoryFormField.Frequency}
        label='Frequency'
      >
        <Select
          options={Frequency?.data?.rc.map((item: FrequencyName) => ({
            label: item.text,
            value: item.value,
          }))}
          placeholder='Please select'
        />
      </Form.Item>
    </Fragment>
  );
};
