import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { categorymaster } from 'types/master';
import { Role } from 'types/role';
export interface RoleListParams extends PaginatedRequestParams {
  full_user_name?: string;
  phone_number?: string;
  roles?: string[];
  retry?: boolean;
  company_id?: string;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}
export type CreateCategoryDTO = {
  categoryName?: string;
  id?: string;
};
export type CreateSubCategoryDTO = {
  categoryName?: string;
  id?: string;
};
export type CreateClassDTO = {
  className?: string;
  subCategoryId?: string;
  categoryId?: string;
  frequencyId?: string;
};
export type CategoryResponse = PaginatedResponse<categorymaster>;

export const MasterApiService = () => {
  const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
  const createCategory = async (
    payload: CreateCategoryDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/SetCategory`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const createSubCategory = async (
    payload: CreateSubCategoryDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/SetSubCategory`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const createClass = async (
    payload: CreateClassDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/SetClass`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getRoleId = async (
    RoleId: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Role>({
      ...callConfig,
      endpoint: `/RoleManagement/GetPermissionlist?RoleId=${RoleId}`,
    });

    return response.data;
  };
  const GetCategory = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/GetCategory`,
      params,
    });

    return response.data;
  };
  const GetSubCategory = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/GetSubCategory`,
      params,
    });

    return response.data;
  };
  const GetSubCategoryById = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/GetSubCategory?${id}`,
    });

    return response.data;
  };
  const GetClass = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/GetClass`,
      params,
    });

    return response.data;
  };
  const getRolePermission = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `/RoleManagement/GetPermissionlist`,
      params,
    });

    return response.data;
  };
  const deleteCategory = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/DeleteCategory/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'DELETE',
    });

    return response.data;
  };
  const deleteSubCategory = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/DeleteSubCategory/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'DELETE',
    });

    return response.data;
  };
  const Deleteclass = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/DeleteClass/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'DELETE',
    });

    return response.data;
  };
  const classStatus = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/ChangeClassStatus/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'GET',
    });

    return response;
  };
  const classSubClassStatus = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/ChangeSubCategoryStatus/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'GET',
    });

    return response;
  };
  const classSubCategory = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/ChangeCategoryStatus/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'GET',
    });

    return response;
  };
  const classCategory = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CategoryResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Master/ChangeCategoryStatus/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'GET',
    });

    return response;
  };

  return {
    GetCategory,
    getRolePermission,
    createCategory,
    GetSubCategory,
    GetSubCategoryById,
    getRoleId,
    deleteCategory,
    createSubCategory,
    deleteSubCategory,
    GetClass,
    Deleteclass,
    createClass,
    classStatus,
    classSubClassStatus,
    classSubCategory,
    classCategory,
  };
};
