import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { RoleListParams } from 'features/role-management/api/role-api.service';
import qs from 'qs';

import { AssignClientsQueryKeys } from './query-keys';
// ../api/assign-clients-api.service

const { getCommentsList } = AssignedClientsApiService();

const getUsersQuery = (
  params: RoleListParams,
  options?: UseQueryOptions<any>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<any>;
  options?: UseQueryOptions<any>;
} => ({
  queryKey: AssignClientsQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getCommentsList(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useCommentsList = (
  params: any,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
