import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Popconfirm, Row, Typography } from 'antd';
import { Loader } from 'components';
import { StyledFiltersWrapper } from 'components/content-filters/content-filters.styles';
import { StyledTableHeader } from 'components/table/table.styled';
import { useLegalDocument } from 'features/assigned-clients/use-cases/get-legal-document';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { useApproveReject } from 'features/new-clients/use-cases/approve-reject';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { LegalApproveRejectModal } from './legal-approve-reject';
import { RemindersLegalModal } from './legal-remindermodal';
import { UploadDocumentsLegalItemModal } from './modal/upload-legal-assign';
const { Title } = Typography;

export const LegalDocument = ({ assignvalue }: any) => {
  const { id } = useParams();

  const params = {
    ClientId: id,
  };
  const { data, isFetching, refetch } = useLegalDocument(params);
  const { mutate } = useApproveReject();
  const [ApproveRjectModal, setApproveRjectModal] = useState(false);
  const [documentDetail, setDocumentDetails] = useState('');
  const [showuploadDocuments, setUploadDocuments] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clientsId = searchParams.get('temp_clie');
  const [reminderModal, setReminderModal] = useState(false);
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(date);

    return `${formattedDate} | ${formattedTime}`;
  };
  const onChangeApprove = (value: any) => {
    const formData = new FormData();
    // Note send CompanyId in ClientsId formdata
    formData.append('ClientId', id as any);
    formData.append('DocumentId', value.documentId);
    formData.append('StatusId', '2');

    mutate(formData, {
      onSuccess: () => {
        refetch();
      },
    });
  };
  useEffect(() => {
    if (assignvalue == 2) {
      refetch();
    }
  }, [assignvalue]);
  if (isFetching) return <Loader />;

  return (
    <>
      <StyledFiltersWrapper className='card editbxnew grid_secton'>
        <StyledTableHeader className='gs_head'>
          <h3>
            Legal Documents
            <span>{data?.rc.length}</span>
          </h3>

          <div className='buttongroup'>
            <Button
              type='primary'
              style={{ backgroundColor: '#223666', color: 'white' }}
              onClick={() => setUploadDocuments(true)}
            >
              Upload Document
            </Button>
          </div>
        </StyledTableHeader>
        <Fragment>
          <Row className='fldrbx'>
            {data?.rc.map((item: any, i: number) => (
              <Card key={i}>
                <Flex>
                  <div className='meidafolder meida'>
                    <FileViewer documentPath={item.documentPath} />
                  </div>
                  <Typography className='cvnu'>
                    <div className='crdttl font-medium'>
                      <dt className='stsbx'>{item.documentName}</dt>
                      <dt className='dwnicon'>
                        <a
                          href={item.documentPath}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <DownloadOutlined />
                        </a>
                      </dt>
                    </div>

                    {item?.documentSize && (
                      <dt className='tmrbx'>{item?.documentSize}</dt>
                    )}

                    {item?.status && (
                      <dt className='tmrbx pending inline rounded px-3'>
                        {item?.status}
                      </dt>
                    )}

                    {item?.comments && (
                      <dt className='tmrbx'>Comments:{item?.comments}</dt>
                    )}

                    {item?.reason && (
                      <dt className='tmrbx'>Reason:{item?.reason}</dt>
                    )}

                    {item?.uploadedOn && (
                      <dt className='tmrbx'>{formatDate(item.uploadedOn)}</dt>
                    )}

                    {item?.status !== 'Approved' &&
                      item?.status !== 'Reject' && (
                        <dt className='button_group'>
                          <div>
                            <Popconfirm
                              title='Approve the Document'
                              description='Are you sure want to approve this Document?'
                              okText='Yes'
                              onConfirm={() => onChangeApprove(item)}
                              cancelText='No'
                            >
                              <Button className='btn'>Approve</Button>
                            </Popconfirm>
                            <Button
                              className='btn'
                              onClick={() => {
                                setApproveRjectModal(true);
                                setDocumentDetails(item);
                              }}
                            >
                              Reject
                            </Button>
                          </div>
                        </dt>
                      )}
                  </Typography>
                </Flex>
                {item.status === 'Reject' && (
                  <div className='bn_grp'>
                    <dt>
                      <Title level={5}>{item?.reason}</Title>
                      <p>{item?.comments}</p>
                    </dt>
                    <Button onClick={() => setReminderModal(true)}>
                      Send Reminder
                    </Button>
                  </div>
                )}
              </Card>
            ))}
          </Row>
          {ApproveRjectModal && (
            <LegalApproveRejectModal
              onClose={() => setApproveRjectModal(false)}
              intialFetch={refetch}
              ClientsID={id}
              documentDetail={documentDetail}
            />
          )}
          {reminderModal && (
            <RemindersLegalModal
              onClose={() => setReminderModal(false)}
              ClientsID={clientsId}
            />
          )}
          {showuploadDocuments && (
            <UploadDocumentsLegalItemModal
              onClose={() => setUploadDocuments(false)}
              refetch={refetch}
            />
          )}
        </Fragment>
      </StyledFiltersWrapper>
    </>
  );
};
