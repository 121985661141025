import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Grid, Typography } from 'antd';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  StyledBackButton,
  StyledHeadingContent,
  StyledHeadingWrapper,
  StyledTitleWrapper,
  StyledWrapper,
} from './top-content-bar.styles';

interface TopContentBarProps {
  title: string;
  renderHeadingContent?: ReactNode;
  renderButtons?: ReactNode;
  hasBackButton?: string;
  ctFilter?: ReactNode;
}

const { useBreakpoint } = Grid;

export const ContentTopBar = ({
  title,
  renderHeadingContent,
  renderButtons,
  hasBackButton = '',
  ctFilter,
}: TopContentBarProps) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  return (
    <StyledWrapper className='tophead'>
      <StyledHeadingWrapper className='head'>
        <StyledTitleWrapper className='hd_ttl'>
          {hasBackButton.length ? (
            <StyledBackButton
              type='link'
              onClick={() => navigate(hasBackButton)}
            >
              <LeftOutlined />
            </StyledBackButton>
          ) : null}
          <Typography.Title
            level={3}
            ellipsis={{ tooltip: title }}
            data-testid='page-heading'
          >
            {title}
          </Typography.Title>
        </StyledTitleWrapper>

        <Breadcrumb
          className='breadcrumb'
          items={[
            {
              title: (
                <span style={{ color: '#999', cursor: 'not-allowed' }}>
                  Home
                </span>
              ), //
            },
            {
              title: title,
            },
          ]}
        />

        {renderButtons && !screens.lg && (
          <div className='mltyplbtn'>{renderButtons}</div>
        )}
        {renderHeadingContent && (
          <StyledHeadingContent>{renderHeadingContent}</StyledHeadingContent>
        )}

        {ctFilter && <StyledHeadingContent>{ctFilter}</StyledHeadingContent>}
      </StyledHeadingWrapper>

      {renderButtons && screens.lg && (
        <div className='mltyplbtn'>{renderButtons}</div>
      )}
    </StyledWrapper>
  );
};
