import { Form, Input, message, Select } from 'antd';
import { MasterApiService } from 'features/master-management/api/master-api.service';
import { ClassFormField } from 'features/master-management/components/filter-field/type';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { Fragment, useState } from 'react';
type FrequencyName = {
  text: string;
  value: number;
};
export const ClassInfoFormItems = ({ data }: any) => {
  const { data: Frequency } = useDropDownFlag('Frequency');
  const [subCaetgory, setSubCategory] = useState<any>('');
  const subCategory = async (id: string) => {
    try {
      const apiRes = await MasterApiService().GetSubCategoryById(
        `CategoryId=${id}` as any,
      );
      setSubCategory(apiRes);
    } catch (error) {
      message.error('something is wrong');
    }
  };

  return (
    <Fragment>
      <Form.Item<ClassFormField>
        name={ClassFormField.CategoryId}
        label={'Category'}
      >
        <Select
          options={data?.rc?.map((item: any) => ({
            label: item.categoryName,
            value: item.id,
          }))}
          onChange={(value) => subCategory(value)}
          placeholder='please select'
        />
      </Form.Item>
      <Form.Item<ClassFormField>
        name={ClassFormField.SubCategoryId}
        label={'Sub Category'}
      >
        <Select
          options={subCaetgory?.rc?.map((item: any) => ({
            label: item.subCategoryName,
            value: item.id,
          }))}
          disabled={!subCaetgory?.rc?.length}
          placeholder={`${
            !subCaetgory?.rc?.length ? 'No Subactegory' : 'Please Select'
          }`}
        />
      </Form.Item>
      <Form.Item<ClassFormField>
        name={ClassFormField.ClassName}
        label={'Class'}
      >
        <Input placeholder='Enter Class' />
      </Form.Item>
      <Form.Item<ClassFormField>
        name={ClassFormField.Frequency}
        label='Frequency'
      >
        <Select
          options={Frequency?.data?.rc.map((item: FrequencyName) => ({
            label: item.text, // Use `label` for the display value
            value: item.value, // Use `value` for the actual value
          }))}
          placeholder='Please select'
        />
      </Form.Item>
    </Fragment>
  );
};
