// Import the drilldown module for Highcharts
import 'highcharts/modules/drilldown';

import { message } from 'antd';
import { DashBoardApiService } from 'features/Dashboard/api/dashboard-api.service';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Fragment, useEffect, useState } from 'react';

const GraphClientsInfo = () => {
  // const { data } = useGetDrilDownList('' as any);
  const [data, setData] = useState<any>('');
  const initial = async () => {
    try {
      const apires = await DashBoardApiService().getRegistrationGraphDrildown(
        '' as any,
      );
      setData(apires);
    } catch (error: any) {
      message.error(error);
    }
  };
  const [options, setOptions] = useState<any>({});
  useEffect(() => {
    if (data?.res?.series && data?.res?.drilldownSeries) {
      const seriesData = data?.res?.series.map((item: any) => ({
        name: item.name,
        y: item.y,
        drilldown: item.drilldown,
      }));

      // Parse the drilldown series if it's a string
      const drilldownSeries = JSON.parse(data?.res?.drilldownSeries);
      setOptions({
        chart: {
          type: 'column',
        },
        title: {
          text: 'Client Registration by Year',
        },
        xAxis: {
          type: 'category',
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: 'Registrations',
            colorByPoint: true,
            data: seriesData, // Use dynamic data here
          },
        ],
        drilldown: {
          series: drilldownSeries, // Use dynamic drilldown data here
        },
      });
    }
  }, [data]);
  useEffect(() => {
    initial();
  }, []);
  
return (
    <Fragment>
      <div className='card'>
        <div className='head'>
          <h3>Client Registration</h3>
          {/* <div className="form-group">
            <Select
              defaultValue="Today"
              style={{ width: 120 }}
              options={[
                { value: 'today', label: 'Today' },
                { value: 'week', label: 'Week' },
                { value: 'month', label: 'Month' },
              ]}
            />
          </div> */}
        </div>
        <div className='grph'>
          {/* Ensure options are set before rendering Highcharts */}
          {options.series && options.series.length > 0 && (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default GraphClientsInfo;
