import {
  ClassFilterFormValuesPaginated,
  ClassFormFilterValues,
} from 'features/master-management/components/filter-field/type';
import { ClassTableItemView } from 'features/master-management/components/table/class-table/class-view';
import { SubClassTopBar } from 'features/master-management/components/top-bar/class-top';
import { useGetClassList } from 'features/master-management/use-cases/get-classmaster';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export const MasterClassItemView = () => {
  const getInitialFilterValues: ClassFormFilterValues = {
    SubCategoryId: undefined,
    CategoryId: undefined,
    ClassName: undefined,
  };
  const { params, updateParams, resetPage } =
    usePaginationParams<ClassFilterFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState('');
  const { data, isFetching } = useGetClassList(params, {
    retry: false,
  });
  const handleFiltersChange = (values: ClassFormFilterValues) => {
    updateParams(values);
    resetPage();
  };

  return (
    <>
      <SubClassTopBar
        setEditModal={setEditModal}
        editModal={editModal}
        editData={editData}
        // refetch={refetch}
        handleFilter={handleFiltersChange}
        getInitialFilter={getInitialFilterValues}
      />
      <ClassTableItemView
        isFetching={isFetching}
        dataList={data?.rc || ([] as any)}
        tableParams={params as any}
        // updateParams={updateParams}
        setEditModal={setEditModal}
        setEditData={setEditData}
      />
      <Outlet />
    </>
  );
};
