import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CategoryResponse,
  MasterApiService,
} from 'features/master-management/api/master-api.service';
import { RoleListParams } from 'features/role-management/api/role-api.service';
import { UserListParams } from 'features/users';
import qs from 'qs';

import { MasterQueryKeys } from './query-keys';

const { GetClass } = MasterApiService();

const getUsersQuery = (
  params: RoleListParams,
  options?: UseQueryOptions<CategoryResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<CategoryResponse>;
  options?: UseQueryOptions<CategoryResponse>;
} => ({
  queryKey: MasterQueryKeys.list(params),
  queryFn: ({ signal }) =>
    GetClass(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetClassList = (
  params: UserListParams,
  options?: UseQueryOptions<CategoryResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<CategoryResponse, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
