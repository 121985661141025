import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';
export enum assignFormFilterFeild {
  Name = 'Name',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  BusinessName = 'BusinessName',
  Date = 'date',
  StatusId = 'StatusId',
  AssignClientName = 'AssignClientName',
}

export enum assignFormField {
  Email = 'email',
}
export type assignFormFieldValue = {
  [assignFormField.Email]: string | undefined;
};

export type assignFormfilterField = {
  [assignFormFilterFeild.Name]: string | undefined;
};
export type assignFormFilterValue = {
  [assignFormFilterFeild.Name]: string | undefined;
  [assignFormFilterFeild.Email]: string | undefined;
  [assignFormFilterFeild.BusinessName]: string | undefined;
  [assignFormFilterFeild.StatusId]: string | undefined;
  [assignFormFilterFeild.AssignClientName]: string | undefined;
  [assignFormFilterFeild.PhoneNumber]: string | undefined;
  [assignFormFilterFeild.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
};
export type AssignFilterFormPaginated = assignFormFilterValue &
  PaginatedRequestParams;
