import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  NewClientsApiService,
  NewClientsResponse,
} from 'features/new-clients/api/new-clients-api.service';

import { NewClientsQueryKeys } from './query-keys';

const { GetClientsById } = NewClientsApiService();

const getClientsDetailsQuery = (
  id: string,
  options?: UseQueryOptions<NewClientsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<NewClientsResponse>;
  options?: UseQueryOptions<NewClientsResponse>;
} => ({
  queryKey: NewClientsQueryKeys.detail(id),
  queryFn: ({ signal }) =>
    GetClientsById(id, {
      config: { signal },
    }),
  ...options,
});

export const useGetClientsDetails = (
  id: string,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getClientsDetailsQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
