/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const DashBoardQueryKeys = {
  all: ['user'] as const,
  lists: () => [...DashBoardQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...DashBoardQueryKeys.lists(), { filters }] as const,
  details: () => [...DashBoardQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...DashBoardQueryKeys.details(), id] as const,
};
