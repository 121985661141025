import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Flex,
  Menu,
  message,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import dayjs from 'dayjs';
// import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { useDeleteReport } from 'features/assigned-clients/use-cases/delete-management-report';
import { useGetManagementReportList } from 'features/assigned-clients/use-cases/get-management';
import { useGetMarkFinished } from 'features/assigned-clients/use-cases/get-markFinished';
import { useUpdateReportMarkFinished } from 'features/assigned-clients/use-cases/update-report-mark';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { useUserContext } from 'features/users';
import { CirclePlus } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getFileExtension } from 'routes/utils';

import { AddReportView } from './upload-report';

export const ManagementReportView = ({
  year,
  month,
  searchName,
  activeTabValue,
}: any) => {
  const { Title } = Typography;
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const { permissions }: any = useUserContext();
  // const [loading, setLoading] = useState(false)
  const permissionMarkFinished = permissions?.rc?.find(
    (item: any) => item.Id === 2,
  );
  const [reportModal, setReportModal] = useState(false);
  // const [reportData, setReportData] = useState<any>('');
  const params = {
    PageIndex: 1,
    RecordPerPage: 10,
  };
  const { mutate: getMarkfinished, data } = useGetMarkFinished();
  const { mutate } = useDeleteReport();
  const statusValue = data?.rc[0]?.status;
  const { mutate: UpdateMarkFinished, isLoading } =
    useUpdateReportMarkFinished();
  const initilaMarkFinished = () => {
    const FinishedData: any = {
      companyId: id,
      Year: +year,
      Month: +month,
      ClientId: ids,
      // periodId: selectedValue,
    };
    getMarkfinished(FinishedData);
  };
  // const initial = async () => {
  //   setLoading(true)
  //   try {
  //     const apiRes = await AssignedClientsApiService().getManagementReport({
  //       PageIndex: params?.PageIndex,
  //       RecordPerPage: params?.RecordPerPage,
  //       CompanyId: id,
  //       Year: year,
  //       Month: month,
  //       Name: searchName,
  //       // PeriodId: selectedValue,
  //     } as any);
  //     setReportData(apiRes);
  //   } catch (error: any) {
  //     message.error(error);
  //   } finally {
  //     setLoading(false)
  //   }
  // };
  const {
    data: reportData,
    refetch,
    isFetching: loading,
  } = useGetManagementReportList({
    PageIndex: params?.PageIndex,
    RecordPerPage: params?.RecordPerPage,
    CompanyId: id,
    Year: year,
    Month: month,
    Name: searchName,
  });
  const onChangeDelete = (valueInfo: any) => {
    const formData = new FormData();
    formData.append('DocumentId', valueInfo?.documentId);
    mutate(formData, {
      onSuccess: () => {
        refetch(); // Ensure refetch is called to reload data
      },
    });
  };
  const handleMenuClick = ({ key }: any, item: any) => {
    if (key === 'download') {
      message.success('Downloading ' + item.documentName);
      const link = document.createElement('a');
      link.href = item.documentPath; // File path or URL
      link.target = '_blank'; // Open in a new tab
      link.download = item.documentName; // Suggested file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (key === 'preview') {
      // Open the preview in a new tab
      Modal.info({
        title: 'Document View',
        width: '100%',
        icon: null,
        closable: true,
        closeIcon: <CloseOutlined />,
        style: { top: '0px', maxWidth: '500px' },
        content: (
          <div style={{ textAlign: 'start' }}>
            {(() => {
              const extension = getFileExtension(item.documentPath);

              if (['jpg', 'jpeg', 'png', 'gif'].includes(extension!)) {
                // If it's an image, display it directly
                return <iframe src={item.documentPath} width='100%' />;
              } else if (
                [
                  'doc',
                  'docx',
                  'xls',
                  'xlsx',
                  'ppt',
                  'pptx',
                  'csv',
                  'pdf',
                ].includes(extension!)
              ) {
                // Use Google Docs Viewer for documents
                return (
                  <iframe
                    src={`https://docs.google.com/gview?url=${item.documentPath}&embedded=true`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              } else {
                return (
                  <iframe
                    src={`${item.documentPath}`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              }
            })()}
          </div>
        ),
        okButtonProps: { style: { display: 'none' } },
      });
    } else if (key === 'Delete') {
      onChangeDelete(item);
    }
  };
  const renderMenu = (item: any) => (
    <Menu
      onClick={({ key }) => handleMenuClick({ key }, item)}
      items={[
        { label: 'Download', key: 'download' },
        { label: 'Preview', key: 'preview' },
        { label: 'Delete', key: 'Delete' },
      ]}
    />
  );
  const handleMarkFinished = () => {
    const value = data?.rc[0].status == 1 ? 2 : 1;
    const periodId = data?.rc[0]?.periodId;
    const UpdateMark = {
      companyId: id,
      statusId: value,
      // Year:+year,
      // Month:+month,
      periodId: periodId,
    };
    UpdateMarkFinished(UpdateMark, {
      onSuccess: (data1: any) => {
        refetch();
        initilaMarkFinished();
        if (data1?.rs == 1) {
          message.success(data?.rm);
        }
      },
    });
  };
  useEffect(() => {
    if (activeTabValue == '5') {
      refetch();
    }
  }, [refetch, year, month, searchName, activeTabValue]);
  useEffect(() => {
    initilaMarkFinished();
  }, [year, month]);

  return (
    <Fragment>
      <Flex className='crdsubhed' justify='space-between'>
        <Title level={3}>
          Management Report
          {permissionMarkFinished?.IsChecked && data?.rc[0]?.status === 1 && (
            <Button
              style={{ background: '#445b9d', color: '#ffffff' }}
              className='btn'
              type='primary'
              loading={isLoading}
              onClick={handleMarkFinished}
            >
              Mark As Finished
            </Button>
          )}
          {permissionMarkFinished?.IsChecked && data?.rc[0]?.status == 2 && (
            <Button
              className='btn'
              loading={isLoading}
              onClick={handleMarkFinished}
            >
              Re-Open Month
            </Button>
          )}
          {/* Optional: Adjust placement if needed */}
        </Title>
        {permissionMarkFinished?.IsChecked && data?.rc[0]?.status === 1 && (
          <ButtonGroup>
            <Button
              className='btn btn-primary flex align-center'
              type='primary'
              onClick={() => setReportModal(true)}
            >
              <CirclePlus height={'18px'} className='mr-2' />
              Upload New Report
            </Button>
          </ButtonGroup>
        )}
        {data?.rc == 0 && (
          <ButtonGroup>
            <Button
              className='btn btn-primary flex align-center'
              type='primary'
              onClick={() => setReportModal(true)}
            >
              <CirclePlus height={'18px'} className='mr-2' />
              Upload New Report
            </Button>
          </ButtonGroup>
        )}
      </Flex>
      {loading ? (
        <Spin className='ldrloder' />
      ) : (
        <Row className='fldrbx'>
          {Array.isArray(reportData?.rc) && reportData?.rc?.length ? (
            reportData?.rc?.map((item: any, index: number) => {
              return (
                <Card role='button' key={index}>
                  <Flex>
                    <div className='meidafolder meida'>
                      <FileViewer documentPath={item.documentPath} />
                    </div>

                    {/* <Typography className='meida'>
                  <FileViewer documentPath={item.documentPath} />                  
                </Typography> */}

                    <Typography className='cvnu'>
                      <div className='crdttl font-medium'>
                        <dt className='stsbx'>{item.documentName}</dt>
                        {statusValue == 1 && (
                          <dt className='dwnicon'>
                            <Dropdown
                              overlay={renderMenu(item)}
                              trigger={['click']}
                            >
                              <MoreOutlined
                                style={{ fontSize: '24px', cursor: 'pointer' }}
                              />
                            </Dropdown>
                          </dt>
                        )}
                      </div>
                      <dt className='tmrbx'>{item?.documentSize}</dt>
                      <dt className='tmrbx'>
                        {dayjs(item?.lastUpdatedDate).format(
                          'DD-MMM-YYYY | hh:mm A',
                        )}
                      </dt>
                      <dt className='tmrbx'>
                        {item?.totalComments
                          ? `${item?.totalComments} Comments`
                          : '0 Comments'}
                      </dt>
                    </Typography>
                  </Flex>
                </Card>
              );
            })
          ) : (
            <Col span={24}>
              <Empty description='No Management Report Found' />
            </Col>
          )}
        </Row>
      )}
      {reportModal && (
        <AddReportView
          onClose={() => setReportModal(false)}
          refetch={refetch}
          year={year}
          month={month}
          // selectedValue={selectedValue}
          initilaMarkFinished={initilaMarkFinished}
        />
      )}
    </Fragment>
  );
};
