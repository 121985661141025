import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';

export enum UsersWithWithdrawFilterFormField {
  FullUserName = 'user_name',
  PhoneNumber = 'phone_number',
  ReferenceNumber = 'refrence_no',
  status = 'status_id',
  Remark = 'remark',
  date = 'date',
}
export enum reminderFormField {
  CustomerId = 'CustomerId',
  Email = 'Email',
  Message = 'Message',
}
export enum mailFormField {
  CustomerId = 'CustomerId',
  Email = 'Email',
}
export type mailformValue = {
  [mailFormField.CustomerId]: string | undefined;
  [reminderFormField.Email]: string;
};

export type reminderFormValue = {
  [reminderFormField.CustomerId]: string | undefined;
  [reminderFormField.Email]: string;
  [reminderFormField.Message]: string;
};

export type UsersWithWithdrawFilterFormValues = {
  [UsersWithWithdrawFilterFormField.FullUserName]: string | undefined;
  [UsersWithWithdrawFilterFormField.PhoneNumber]: string | undefined;
  [UsersWithWithdrawFilterFormField.ReferenceNumber]: string | undefined;
  [UsersWithWithdrawFilterFormField.status]: string | undefined;
  [UsersWithWithdrawFilterFormField.Remark]: string | undefined;
  [UsersWithWithdrawFilterFormField.date]:
    | [Dayjs | undefined, Dayjs | undefined]
    | null;
};
type UsersWithWithdrawSort = {
  order_by_transaction?: string;
  order_by_balance?: string;
};

export type UsersWithWithdrawFilterFormPaginated =
  UsersWithWithdrawFilterFormValues &
    UsersWithWithdrawSort &
    PaginatedRequestParams;
