export enum LoginFormField {
  Email = 'Email',
  Password = 'Password',
  Remember = 'RememberLogin',
}

export type LoginFormValues = {
  [LoginFormField.Email]: string;
  [LoginFormField.Password]: string;
  [LoginFormField.Remember]: boolean;
};

export enum VerifyOtpField {
  UserId = 'UserId',
  VerificationCode = 'VerificationCode',
}
export type VerifyOtpFormValues = {
  [VerifyOtpField.UserId]: string;
  [VerifyOtpField.VerificationCode]: string;
};
