import { NoData } from 'features/no-data/no-data';
import { RoleFormFilterValues } from 'features/role-management/components/role-filter-field/type';
import { RoleItemView } from 'features/role-management/components/Role-table/role-table-view';
import { RoleTopBar } from 'features/role-management/components/top-bar/role-top';
import { useGetRoleList } from 'features/role-management/use-cases/get-roles';
import { useUserContext } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export const RoleView = () => {
  const { menuItem }: any = useUserContext();
  const getInitialFilterValues: RoleFormFilterValues = {
    id: undefined,
    RoleName: undefined,
    isActive: undefined,
  };
  const { params, updateParams, resetPage } = usePaginationParams<any>({
    PageIndex: 1,
    RecordPerPage: 10,
    UserType: 1,
    ...getInitialFilterValues,
  });
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [editRoleData, setEditRoleData] = useState('');
  const { data, isFetching } = useGetRoleList(params, {
    retry: false,
  });
  const handleFiltersChange = (values: RoleFormFilterValues) => {
    updateParams(values);
    resetPage();
  };
  if (menuItem?.rc?.length === 0) {
    return <NoData />;
  }
  
return (
    <>
      <RoleTopBar
        setEditRoleModal={setEditRoleModal}
        editRoleModal={editRoleModal}
        editRoleData={editRoleData}
        // refetch={refetch}
        handleFilter={handleFiltersChange}
        getInitialFilter={getInitialFilterValues}
      />
      <RoleItemView
        isRoleListLoading={isFetching}
        roleList={data?.rc || []}
        tableParams={params}
        // updateParams={updateParams}
        setEditRoleModal={setEditRoleModal}
        setEditRoleData={setEditRoleData}
      />
      <Outlet />
    </>
  );
};
