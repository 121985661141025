import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { RoleApiService } from 'features/role-management/api/role-api.service';
import { CreateUserDTO } from 'features/users/api/user-api.service';

import { RoleQueryKeys } from './query-keys';

const { createRolePermission } = RoleApiService();

export const useCreateRole = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateUserDTO) => {
      return createRolePermission(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        queryClient.invalidateQueries({
          queryKey: RoleQueryKeys.lists(),
        });
        if (data?.rs == 1) {
          message.success('Role created successfully');
        } else {
          message.error(data?.rm);
        }
      },
    },
  );
};
