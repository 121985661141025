import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { Gender, User } from 'types';
import { Subscription } from 'types/subscription';

export interface SubListParams extends PaginatedRequestParams {
  id?: string;
  subscriptionName?: string;
  price?: string; // ISO8601_FORMAT
}

export type CreateSubDTO = {
  id?: string;
  name?: string;
};

export type UpdateUserDTO = {
  company_id?: string;
  email?: string | null;
  first_name?: string;
  gender?: Gender | null;
  id: string;
  last_name?: string;
  password?: string;
  phone_number?: string;
  is_active?: boolean;
  role?: string;
};

export type SubscriptionResponse = PaginatedResponse<Subscription>;
export const SubscriptionApiService = () => {
  const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
  const getSubscription = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<SubscriptionResponse>({
      ...callConfig,
      endpoint: `${baseURL}/SubscriptionManagement/GetSubscriptionList`,
      params,
    });

    return response.data;
  };

  const createSubscription = async (
    payload: CreateSubDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `${baseURL}/SubscriptionManagement/CreateUpdateSubscription`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const deleteSubscription = async (
    id: string, // Assuming the `id` is passed to identify which subscription to delete
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `${baseURL}/SubscriptionManagement/DeleteSubscription/${id}`, // Update the endpoint to reflect the delete action and pass `id`
      method: 'DELETE',
    });

    return response.data;
  };

  return {
    createSubscription,
    getSubscription,
    deleteSubscription,
  };
};
