import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { NewClientsResponse } from 'features/new-clients/api/new-clients-api.service';

import { AssignClientsQueryKeys } from './query-keys';
// ../api/assign-clients-api.service

const { dropDownByFlag } = AssignedClientsApiService();
export const useDropDownFlag = (
  flag: string, // Accept 'city' or other flags
  parentId: string, // Include ParentId as a parameter
  options?: UseQueryOptions<NewClientsResponse, Error>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    [AssignClientsQueryKeys.all, flag, parentId], // Include parentId in the query key
    () => dropDownByFlag(flag, parentId), // Pass both flag and parentId to dropDownByFlag
    {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);
        message.error(uiMessage);
      },
      ...options,
    },
  );
};
