import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateReminder,
  NewClientsApiService,
} from 'features/new-clients/api/new-clients-api.service';
const { createReminder } = NewClientsApiService();

export const useCreateReminder = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: CreateReminder) => {
      return createReminder(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        if (data?.rs == 0) {
          message.warning(data?.rm);
        } else {
          message.success(data?.rm);
        }
      },
    },
  );
};
