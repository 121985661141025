import {
  SubCategoryFilterFormValuesPaginated,
  SubCategoryFormFilterValues,
} from 'features/master-management/components/filter-field/type';
import { SubTableItemView } from 'features/master-management/components/table/sub-category-table/sub-category-table';
import { SubCategoryTopBar } from 'features/master-management/components/top-bar/sub-category';
import { useGetSubCategoryList } from 'features/master-management/use-cases/get-subCategory';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export const SubCategoryItemView = () => {
  const getInitialFilterValues: SubCategoryFormFilterValues = {
    SubCategoryName: undefined,
    CategoryId: undefined,
  };
  const { params, updateParams, resetPage } =
    usePaginationParams<SubCategoryFilterFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState('');
  const { data, isFetching } = useGetSubCategoryList(params, {
    retry: false,
  });
  const handleFiltersChange = (values: SubCategoryFormFilterValues) => {
    updateParams(values);
    resetPage();
  };

  return (
    <>
      <SubCategoryTopBar
        setEditModal={setEditModal}
        editModal={editModal}
        editData={editData}
        // refetch={refetch}
        handleFilter={handleFiltersChange}
        getInitialFilter={getInitialFilterValues}
      />
      <SubTableItemView
        isFetching={isFetching}
        CategoryList={data?.rc || []}
        tableParams={params as any}
        // updateParams={updateParams}
        setEditModal={setEditModal}
        setEditData={setEditData}
      />
      <Outlet />
    </>
  );
};
