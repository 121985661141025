import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps, Grid, Spin, Switch, theme } from 'antd';
import { Modal } from 'components';
import { useGetSubCategoryCotmizeList } from 'features/new-clients/use-cases/sub-category-access';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CSSProperties } from 'styled-components';

const { useBreakpoint } = Grid;

export const EditCoustmizeModal = ({
  onClose,
  setSubCategoryPayload,
}: {
  onClose: () => void;
  setSubCategoryPayload: any;
}) => {
  const screens = useBreakpoint();
  const { id } = useParams();
  const { data, isLoading } = useGetSubCategoryCotmizeList({
    CompanyId: id,
  } as any);
  const { token } = theme.useToken();

  // State for categories, subcategories, and class categories
  const [categoryChecks, setCategoryChecks] = useState<Record<string, boolean>>(
    {},
  );
  const [subCategoryChecks, setSubCategoryChecks] = useState<
    Record<string, boolean>
  >({});
  const [classCategoryChecks, setClassCategoryChecks] = useState<
    Record<string, boolean>
  >({});

  // Set initial state when data is loaded
  useEffect(() => {
    if (data) {
      const initialCategoryChecks: Record<string, boolean> = {};
      const initialSubCategoryChecks: Record<string, boolean> = {};
      const initialClassCategoryChecks: Record<string, boolean> = {};

      data.rc.forEach((category: any) => {
        initialCategoryChecks[category.Id] = category.IsChecked;

        category.SubCategoryList.forEach((subCategory: any) => {
          initialSubCategoryChecks[subCategory.Id] = subCategory.IsChecked;

          subCategory.ClassCategoryList.forEach((classCategory: any) => {
            initialClassCategoryChecks[classCategory.Id] =
              classCategory.IsChecked;
          });
        });
      });

      setCategoryChecks(initialCategoryChecks);
      setSubCategoryChecks(initialSubCategoryChecks);
      setClassCategoryChecks(initialClassCategoryChecks);
    }
  }, [data]);

  // Handle main category switch
  const handleCategorySwitchChange = (
    checked: boolean,
    categoryId: string,
    subCategories: any[],
  ) => {
    setCategoryChecks((prev) => ({ ...prev, [categoryId]: checked }));

    // Toggle all subcategories and class categories under this main category
    const updatedSubCategoryChecks = { ...subCategoryChecks };
    const updatedClassCategoryChecks = { ...classCategoryChecks };

    subCategories.forEach((subCategory) => {
      updatedSubCategoryChecks[subCategory.Id] = checked;
      subCategory.ClassCategoryList.forEach((classCategory: any) => {
        updatedClassCategoryChecks[classCategory.Id] = checked;
      });
    });

    setSubCategoryChecks(updatedSubCategoryChecks);
    setClassCategoryChecks(updatedClassCategoryChecks);
  };

  // Handle subcategory switch
  const handleSubCategorySwitchChange = (
    checked: boolean,
    subCategoryId: string,
    classCategories: any[],
  ) => {
    setSubCategoryChecks((prev) => ({ ...prev, [subCategoryId]: checked }));

    // Toggle all class categories under this subcategory
    const updatedClassCategoryChecks = { ...classCategoryChecks };
    classCategories.forEach((classCategory: any) => {
      updatedClassCategoryChecks[classCategory.Id] = checked;
    });

    setClassCategoryChecks(updatedClassCategoryChecks);
  };

  const panelStyle: CSSProperties = { backgroundColor: '#fff' };

  const getItems: (panelStyle1: CSSProperties) => CollapseProps['items'] = (
    panelStyle1,
  ) =>
    data?.rc?.map((category: any) => ({
      key: String(category.Id),
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <span>{category.CategoryName}</span>
          <Switch
            checked={categoryChecks[category.Id] || false}
            onChange={(checked) =>
              handleCategorySwitchChange(
                checked,
                category.Id,
                category.SubCategoryList,
              )
            }
          />
        </div>
      ),
      children: (
        <div>
          {category.SubCategoryList.map((subCategory: any) => (
            <div key={subCategory.Id}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                  marginLeft: '24px',
                }}
              >
                <span className='fw-500'>{subCategory.SubCategory}</span>
                <Switch
                  checked={subCategoryChecks[subCategory.Id] || false}
                  onChange={(checked) =>
                    handleSubCategorySwitchChange(
                      checked,
                      subCategory.Id,
                      subCategory.ClassCategoryList,
                    )
                  }
                />
              </div>

              {/* Class categories */}
              {subCategory.ClassCategoryList.length > 0 && (
                <div>
                  {subCategory.ClassCategoryList.map((classCategory: any) => (
                    <div
                      key={classCategory.Id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '10px',
                        marginLeft: '40px',
                      }}
                    >
                      <span>{classCategory.ClassCategory}</span>
                      <Switch
                        checked={classCategoryChecks[classCategory.Id] || false}
                        onChange={(checked) =>
                          setClassCategoryChecks((prev) => ({
                            ...prev,
                            [classCategory.Id]: checked,
                          }))
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ),
      style: panelStyle1,
    }));

  // Updated handleUpdate function to send only IDs
  const handleUpdate = () => {
    const payload: { ServiceId: string; categoryName: string }[] = []; // Initialize payload array
    data?.rc.forEach((category: any) => {
      // Check if the main category switch is checked
      if (categoryChecks[category.Id]) {
        // Include the main category if its switch is checked
        payload.push({ ServiceId: category.Id } as any);

        category.SubCategoryList.forEach((subCategory: any) => {
          // Include subcategory only if its switch is checked
          if (subCategoryChecks[subCategory.Id]) {
            payload.push({ ServiceId: subCategory.Id } as any);
          }

          subCategory.ClassCategoryList.forEach((classCategory: any) => {
            // Include class category only if its switch is checked
            if (classCategoryChecks[classCategory.Id]) {
              payload.push({ ServiceId: classCategory.Id } as any);
            }
          });
        });
      } else {
        // If the main category switch is unchecked, skip it along with its subcategories and class categories
        category.SubCategoryList.forEach((subCategory: any) => {
          if (subCategoryChecks[subCategory.Id]) {
            payload.push({ ServiceId: subCategory.Id } as any);
          }

          subCategory.ClassCategoryList.forEach((classCategory: any) => {
            if (classCategoryChecks[classCategory.Id]) {
              payload.push({ ServiceId: classCategory.Id } as any);
            }
          });
        });
      }
    });

    // Set the payload for further processing
    setSubCategoryPayload(payload);
    onClose(); // Close the modal after saving
  };

  return (
    <StyledUserModalWrapper className='rightmodal'>
      <Modal
        open
        centered
        title={'Select Sub-Categories'}
        onCancel={onClose}
        getContainer={false}
        onOk={handleUpdate}
        okText={'Save'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
      >
        <h3>Select the sub-categories to display for Business Name</h3>

        {isLoading ? (
          <Spin
            size='large'
            style={{ display: 'block', margin: '20px auto' }}
          />
        ) : (
          <Collapse
            bordered={false}
            defaultActiveKey={[1]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            style={{ background: token.colorBgContainer }}
            items={getItems(panelStyle)}
          />
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
