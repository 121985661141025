import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AuthApiService,
  setAccessToken,
  setRefreshToken,
  VerifyOtp,
} from 'features/auth';

const { OtpApi } = AuthApiService();

type UseOtpPayload = VerifyOtp & { persistSession: boolean };

export const useOtpVerfiy = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UseOtpPayload) => {
      const { UserId, VerificationCode } = payload;

      return OtpApi({ UserId, VerificationCode });
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any, variables) => {
        setAccessToken(data?.res?.token, variables.persistSession);
        setRefreshToken(data?.res?.token, variables.persistSession);
      },
    },
  );
};
