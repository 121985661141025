// import { Grid } from 'antd';
import { ContentFilters, ContentTopBar } from 'components';
import { SubCategoryFilterForm } from 'features/master-management/components/filter-field/subCategory-filter-form';
import { SubCategoryFormFilterValues } from 'features/master-management/components/filter-field/type';
import { EditSubCategoryViewModal } from 'features/master-management/components/modal/sub-category/subcategory-edit.view';
import { useState } from 'react';

// const { useBreakpoint } = Grid;

type RoleProps = {
  setEditModal: any;
  editModal: boolean;
  editData: any;
  handleFilter: (values: SubCategoryFormFilterValues) => void;
  getInitialFilter: SubCategoryFormFilterValues;
};

export const SubCategoryTopBar = ({
  setEditModal,
  editModal,
  editData,
  handleFilter,
  getInitialFilter,
}: RoleProps) => {
  useState<any>();
  // const [showAddModal, setShowAddModal] = useState(false);
  // const screens = useBreakpoint();
  const formId = 'role-filters-form';

  return (
    <>
      <ContentTopBar
        title={'Sub Categories List'}
        renderButtons={
          <>
            {/* <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => setShowAddModal(true)}
              style={{ backgroundColor: '#223666', color: 'white' }}
            >
              Add Sub Category Name
            </Button>
            {showAddModal && (
              <AddSubCategoryModal onClose={() => setShowAddModal(false)} />
            )} */}
            {editModal && (
              <EditSubCategoryViewModal
                onClose={() => setEditModal(false)}
                editData={editData}
              />
            )}
          </>
        }
      />
      <ContentFilters formId={formId}>
        <SubCategoryFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilter}
        />
      </ContentFilters>
    </>
  );
};
