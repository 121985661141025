import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Form, Image, Input, Upload } from 'antd';
import { Select } from 'components';
import { UserFormField } from 'features/users/components/users-filter-form';
import { useGetRoleList } from 'features/users/use-cases/get-role';
import { t } from 'i18next';
import { Fragment } from 'react';
import { validateImageBeforeUpload } from 'routes/utils';

export const UserInfoFormItems = ({
  fileList,
  setFileList,
  previewImage,
  setPreviewImage,
  previewOpen,
  setPreviewOpen,
  Id,
  type,
}: any) => {
  const { data } = useGetRoleList({
    UserType: 1,
    IsActive: true,
  } as any);
  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Fragment>
      <Form.Item<UserFormField>
        name={UserFormField.UserName}
        label={t('usermanagement.formFields.userName.label')}
        rules={[
          {
            required: true,
            whitespace: false,
          },
        ]}
      >
        <Input
          disabled={type == 2 ? true : false}
          placeholder={t('usermanagement.formFields.userName.placeholder')}
        />
      </Form.Item>
      {/* disabled */}
      <Form.Item<UserFormField>
        label={t('usermanagement.formFields.email.label')}
        name={UserFormField.Email}
        rules={[
          {
            required: true,
            whitespace: false,
          },
        ]}
      >
        <Input
          disabled={type == 2 ? true : false}
          placeholder={t('usermanagement.formFields.email.placeholder')}
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('usermanagement.formFields.role.label')}
        name={UserFormField.Role}
        rules={[
          {
            required: true,
            whitespace: false,
          },
        ]}
      >
        <Select
          options={data?.rc?.map((item: any) => ({
            value: item.Id,
            label: item.RoleName,
          }))}
          placeholder={t('usermanagement.formFields.role.placeholder')}
        />
      </Form.Item>
      {!Id && (
        <Form.Item
          label={t('usermanagement.formFields.profile.label')}
          name={UserFormField.UserProfile}
        >
          <div style={{ width: '100%' }}>
            <Upload
              action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
              listType='picture-card'
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              maxCount={1}
              beforeUpload={validateImageBeforeUpload}
              style={{ width: '100%' }} // Ensure the Upload takes 100% width
            >
              {fileList?.length >= 1 ? null : (
                <div style={{ width: '100%' }}>
                  {' '}
                  {/* Upload button full width */}
                  {uploadButton}
                </div>
              )}
            </Upload>
          </div>
          {previewImage && (
            <Image
              wrapperStyle={{ display: 'none' }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </Form.Item>
      )}
    </Fragment>
  );
};
