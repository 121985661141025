import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';
export enum clientsFormFilterFeild {
  Name = 'Name',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  BusinessName = 'BusinessName',
  Date = 'date',
  StatusId = 'StatusId',
}
export type clientsFormFilterValue = {
  [clientsFormFilterFeild.Name]: string | undefined;
  [clientsFormFilterFeild.Email]: string | undefined;
  [clientsFormFilterFeild.BusinessName]: string | undefined;
  [clientsFormFilterFeild.StatusId]: string | undefined;
  [clientsFormFilterFeild.PhoneNumber]: string | undefined;
  [clientsFormFilterFeild.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
};
export type ClientsFilterFormPaginated = clientsFormFilterValue &
  PaginatedRequestParams;
