// import { Grid } from 'antd';
import { ContentFilters, ContentTopBar } from 'components';
import { ClassFilterForm } from 'features/master-management/components/filter-field/class-filter-form';
import { ClassFormFilterValues } from 'features/master-management/components/filter-field/type';
import { EditClassViewModal } from 'features/master-management/components/modal/class-master/class-edit-view';
import { useState } from 'react';

// const { useBreakpoint } = Grid;

type RoleProps = {
  setEditModal: any;
  editModal: boolean;
  editData: any;
  handleFilter: (values: ClassFormFilterValues) => void;
  getInitialFilter: ClassFormFilterValues;
};

export const SubClassTopBar = ({
  setEditModal,
  editModal,
  editData,
  handleFilter,
  getInitialFilter,
}: RoleProps) => {
  useState<any>();
  // const [showAddModal, setShowAddModal] = useState(false);
  // const screens = useBreakpoint();
  const formId = 'role-filters-form';

  return (
    <>
      <ContentTopBar
        title={'Class List'}
        renderButtons={
          <>
            {/* <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => setShowAddModal(true)}
              style={{ backgroundColor: '#223666', color: 'white' }}
            >
              Add Class Name
            </Button>

            
            {showAddModal && (
              <AddClassModal onClose={() => setShowAddModal(false)} />
            )} */}
            {editModal && (
              <EditClassViewModal
                onClose={() => setEditModal(false)}
                editData={editData}
              />
            )}
          </>
        }
      />
      <ContentFilters formId={formId}>
        <ClassFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilter}
        />
      </ContentFilters>
    </>
  );
};
