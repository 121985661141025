import { Form, Grid, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Modal } from 'components';
import { CategoryFormValues } from 'features/master-management/components/filter-field/type';
import { useApproveReject } from 'features/new-clients/use-cases/approve-reject';
import { useDropDownClientsFlag } from 'features/new-clients/use-cases/clients-drop';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { UserFormField } from 'features/users/components/users-filter-form';
import { useState } from 'react';
const { useBreakpoint } = Grid;

export const ApproveRejectModal = ({
  onClose,
  documentDetail,
  Clientsdata,
  intialFetch,
}: {
  onClose: () => void;
  documentDetail: any;
  Clientsdata: any;
  intialFetch: any;
}) => {
  const formName = 'add-activate-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CategoryFormValues>();
  const { data: rejectdata } = useDropDownClientsFlag('RejectReason');
  const { isLoading, mutate } = useApproveReject();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };
  const handleUpdate = (formValue: any) => {
    const formData = new FormData();
    formData.append('ClientId', Clientsdata?.rc[0]?.companyId);
    formData.append('DocumentId', documentDetail?.documentId);
    formData.append('StatusId', '3');
    formData.append('Reason', formValue?.Reason);
    formData.append('Comments', formValue?.Comments);
    mutate(formData, {
      onSuccess: () => {
        intialFetch();
        onClose();
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Reject'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Save'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<CategoryFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => setValidateTrigger(['onChange'])}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item
            label='Region'
            name='Reason'
            rules={[
              {
                required: true,
                message: 'Select Reason',
              },
            ]}
          >
            <Select
              options={rejectdata?.data.rc?.map((item: any) => ({
                label: item.text,
                value: item.text,
              }))}
              placeholder='please select'
            />
          </Form.Item>

          <Form.Item
            label='Comments'
            name={'Comments'}
            rules={[
              {
                required: true,
                message: 'Enter your comments',
              },
            ]}
          >
            <TextArea placeholder='Please enter Comments' />
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
