import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AuthApiService, ResetPasswordDTO } from 'features/auth';

const { ResetPassword } = AuthApiService();

type ResetPasswordPayload = ResetPasswordDTO & { persistSession: boolean };

export const useResetPassword = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: ResetPasswordPayload) => {
      const { Password, UniqueId } = payload;

      return ResetPassword({ Password, UniqueId });
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
    },
  );
};
