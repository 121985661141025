import { AddNewClients } from 'features/new-clients/components/add-new-clients/add-new-clients';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const AddClientView = () => {
  const navigate = useNavigate();

  const navigateToList = () => {
    navigate(ROUTES.CLIENTS);
  };

  return <AddNewClients onCancel={navigateToList} onSuccess={navigateToList} />;
};
