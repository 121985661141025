import { Empty } from 'antd';
import { Fragment } from 'react';

export const NoData = () => {
  return (
    <Fragment>
      <div className='center-body'>
        <Empty description={'No permission'} />
      </div>
    </Fragment>
  );
};
