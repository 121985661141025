import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  ClientsListParams,
  NewClientsApiService,
  NewClientsResponse,
} from 'features/new-clients/api/new-clients-api.service';
import qs from 'qs';

import { NewClientsQueryKeys } from './query-keys';
const { GetClass } = NewClientsApiService();

const getUsersQuery = (
  params: ClientsListParams,
  options?: UseQueryOptions<NewClientsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<NewClientsResponse>;
  options?: UseQueryOptions<NewClientsResponse>;
} => ({
  queryKey: NewClientsQueryKeys.list(params),
  queryFn: ({ signal }) =>
    GetClass(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetClientsClassUserList = (
  params: any,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
