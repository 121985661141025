import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Switch } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { CategoryFilterFormValuesPaginated } from 'features/master-management/components/filter-field/type';
import { AddCategoryModal } from 'features/master-management/components/modal/add-category';
import { useStatusCategory } from 'features/master-management/use-cases/category-status';
import { useDeleteCategory } from 'features/master-management/use-cases/delete-category';
import { usePermissions } from 'features/users';
import { FilePenLine, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { categorymaster } from 'types/master';
import { Role } from 'types/role';
// ../../modal/add-category
type CategoryTableProps = {
  CategoryList: categorymaster[];
  isFetching: boolean;
  tableParams: CategoryFilterFormValuesPaginated;
  setEditModal: any;
  setEditData: any;
};

export const CategoryTableItemView = ({
  CategoryList,
  isFetching,
  tableParams,
  setEditModal,
  setEditData,
}: CategoryTableProps) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { t } = useTranslation();
  const permissions = usePermissions();
  const { mutate } = useDeleteCategory();
  const { mutate: CategoryStatus, isLoading: globalStatusLoading } =
    useStatusCategory();
  const [loadingStatus, setLoadingStatus] = useState<{
    [key: number]: boolean;
  }>({});
  if (!permissions) return null;
  const handleOpenModal = (record: any) => {
    setEditModal(true);
    setEditData(record);
  };
  const onChangeDelete = (data: any) => {
    mutate(data.id); // Call mutate with data.id
  };
  const handleStatusChange = (id: string) => {
    // Set the loading state for the specific category
    setLoadingStatus((prev) => ({ ...prev, [id]: true }));
    // Trigger the mutate function to change the status
    CategoryStatus(id, {
      onSuccess: () => {
        // Stop the loading after the mutation is successful
        setLoadingStatus((prev) => ({ ...prev, [id]: false }));
      },
      onError: () => {
        // Optionally stop the loading on error too
        setLoadingStatus((prev) => ({ ...prev, [id]: false }));
      },
    });
  };

  return (
    <StyledTableWrapper className='card grid_secton'>
      {/* <StyledTableHeader></StyledTableHeader> */}
      <StyledTableHeader className='gs_head'>
        <h3>
          Category List{' '}
          {/* <span>{dataList?.length > 0 ? dataList[0].totalRecords : '0'}</span> */}
        </h3>

        <div className='buttongroup'>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => setShowAddModal(true)}
            style={{ backgroundColor: '#223666', color: 'white' }}
          >
            Add New Category
          </Button>
          {showAddModal && (
            <AddCategoryModal onClose={() => setShowAddModal(false)} />
          )}
        </div>
      </StyledTableHeader>
      <div className='cstmtblbx'>
        <div className='cstm_table'>
          <Table<categorymaster>
            loading={isFetching}
            dataSource={CategoryList}
            rowKey='id'
            // onChange={handleTableChange}
            pagination={{
              current: tableParams.PageIndex,
              defaultPageSize: tableParams.RecordPerPage,
              total: 10,
            }}
            // scroll={{ x: 1440 }}
            components={{
              header: {
                cell: (
                  props: React.HTMLAttributes<HTMLTableHeaderCellElement>,
                ) => <th {...props} />,
              },
            }}
          >
            <Column<categorymaster>
              width='125px'
              title={'Sr.No'}
              render={(_, __, index) => index + 1}
            />
            <Column<categorymaster>
              title={'Category Name'}
              dataIndex={'categoryName'}
            />
            <Column<Role>
              width='125px'
              title={t('usermanagement.table.status')}
              dataIndex={'role'}
              render={(_, record: any) => (
                <Space>
                  <Typography
                    style={{
                      color: record.isActive === true ? '#155724' : 'red', // Green for "Active", Red for "Inactive"
                    }}
                  >
                    {record.isActive === true ? 'Active' : 'Inactive'}
                  </Typography>
                </Space>
              )}
            />
            <Column<categorymaster>
              width='180px'
              title={t('usermanagement.table.action')}
              dataIndex={'Id'}
              render={(_, record: any) => (
                <Space className='grdbtngroup'>
                  <Button
                    className='btn'
                    type='link'
                    onClick={() => handleOpenModal(record)}
                  >
                    <FilePenLine height={16} />
                  </Button>
                  <Popconfirm
                    title='Delete the Category'
                    description='Are you sure to delete this Category?'
                    onConfirm={() => onChangeDelete(record)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button className='btn' type='link'>
                      <Trash2 stroke='#E04343' height={16} />
                    </Button>
                  </Popconfirm>

                  <Switch
                    disabled={loadingStatus[record.id] || globalStatusLoading} // Disable switch if loading
                    loading={loadingStatus[record.id]} // Show loading for specific category
                    onClick={() => handleStatusChange(record.id)}
                    checked={record?.isActive === true}
                  />
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </StyledTableWrapper>
  );
};
