import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Skeleton, Space } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { AddSubModal } from 'features/subscription-management/components/modal/add-subscription';
import { SubFilterFormValuesPaginated } from 'features/subscription-management/components/subscription-filter-field/type';
import { useDeleteSubscription } from 'features/subscription-management/use-cases/delete-subscription';
import { usePermissions } from 'features/users';
import { FilePenLine, Trash2 } from 'lucide-react';
// ../modal/add-subscription
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from 'types/role';
import { Subscription } from 'types/subscription';
type SubTableProps = {
  subList: any;
  isRoleListLoading: boolean;
  tableParams: SubFilterFormValuesPaginated;
  setEditSubscriptionModal: any;
  setEditSubData: any;
};

export const SubscriptionItemView = ({
  subList,
  isRoleListLoading,
  tableParams,
  setEditSubscriptionModal,
  setEditSubData,
}: SubTableProps) => {
  const [showAddSubModal, setShowAddSubModal] = useState(false);
  const { t } = useTranslation();
  const permissions = usePermissions();
  const { mutate } = useDeleteSubscription();
  if (!permissions) return null;
  const handleOpenModal = (record: any) => {
    setEditSubscriptionModal(true);
    setEditSubData(record);
  };
  const onChangeDelete = (data: any) => {
    mutate(data.id); // Call mutate with data.id
  };

  return (
    <StyledTableWrapper className='card grid_secton'>
      {/* <StyledTableHeader></StyledTableHeader> */}

      <StyledTableHeader className='gs_head'>
        <h3>
          Subscription Management{' '}
          <span>{subList?.length > 0 ? subList[0].totalRecords : '0'}</span>
        </h3>

        <div className='buttongroup'>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => setShowAddSubModal(true)}
            style={{ backgroundColor: '#223666', color: 'white' }}
          >
            Add New Subscription
          </Button>
          {showAddSubModal && (
            <AddSubModal onClose={() => setShowAddSubModal(false)} />
          )}
        </div>
      </StyledTableHeader>

      <div className='cstmtblbx'>
        <div className='cstm_table'>
          <Table<Subscription>
            loading={isRoleListLoading}
            dataSource={subList}
            rowKey='id'
            // onChange={handleTableChange}
            pagination={{
              current: tableParams.PageIndex,
              defaultPageSize: tableParams.RecordPerPage,
              total: subList?.totalRecords,
            }}
            components={{
              header: {
                cell: (
                  props: React.HTMLAttributes<HTMLTableHeaderCellElement>,
                ) => <th {...props} />,
              },
            }}
          >
            <Column<Subscription>
              width='125px'
              title={'SrNo'}
              render={(_, record, index) =>
                isRoleListLoading ? (
                  <Skeleton.Input active style={{ width: 50 }} />
                ) : (
                  index + 1
                )
              }
            />
            <Column<Subscription>
              title={'Name'}
              dataIndex={'subscriptionName'}
              render={(subscriptionName) =>
                isRoleListLoading ? (
                  <Skeleton.Input active style={{ width: 50 }} />
                ) : (
                  subscriptionName
                )
              }
            />
            {/* <Column<Subscription>
          title={'Price'}
          dataIndex={'price'}
        /> */}
            <Column<Subscription>
              width='125px'
              title={t('usermanagement.table.status')}
              dataIndex={'role'}
              render={(_, record: any) =>
                isRoleListLoading ? (
                  <Skeleton.Input active style={{ width: 80 }} />
                ) : (
                  <Space>
                    <Typography
                      style={{
                        color: record.isActive == true ? '#155724' : 'red',
                      }}
                    >
                      {record.isActive == true ? 'Active' : 'Inactive'}
                    </Typography>
                  </Space>
                )
              }
            />
            <Column<Role>
              width='150px'
              title={t('usermanagement.table.action')}
              dataIndex={'Id'}
              render={(_, record) =>
                isRoleListLoading ? (
                  <Skeleton.Button active />
                ) : (
                  <Space className='grdbtngroup'>
                    <Button
                      className='btn'
                      type='link'
                      onClick={() => handleOpenModal(record)}
                    >
                      <FilePenLine height={16} />
                    </Button>

                    <Popconfirm
                      title='Delete the Subscription'
                      description='Are you sure to delete this Subscription?'
                      onConfirm={() => onChangeDelete(record)}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button className='btn' type='link'>
                        <Trash2 stroke='#E04343' height={16} />
                      </Button>
                    </Popconfirm>
                  </Space>
                )
              }
            />
          </Table>
        </div>
      </div>
    </StyledTableWrapper>
  );
};
