import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  SubListParams,
  SubscriptionApiService,
  SubscriptionResponse,
} from 'features/subscription-management/api/subscription-api.service';
import qs from 'qs';

import { subscriptionQueryKeys } from './query-keys';

const { getSubscription } = SubscriptionApiService();

const getUsersQuery = (
  params: SubListParams,
  options?: UseQueryOptions<SubscriptionResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<SubscriptionResponse>;
  options?: UseQueryOptions<SubscriptionResponse>;
} => ({
  queryKey: subscriptionQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getSubscription(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetSubscriptionList = (
  params: SubListParams,
  options?: UseQueryOptions<SubscriptionResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<SubscriptionResponse, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
