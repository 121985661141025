import { message, Upload } from 'antd';
import dayjs from 'dayjs';

import { RoutePaths } from './constants';

export const getRoute = (route: RoutePaths, id: string | number) => {
  return route.replace(`:id`, id.toString());
};

export const reload = () => {
  window.location.reload();
};
export const validateFileBeforeUpload = (file: File) => {
  // Allowed file types
  const allowedTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'text/csv',
  ];

  // Video MIME types to exclude
  const videoTypes = [
    'video/mp4',
    'video/x-msvideo', // .avi
    'video/x-matroska', // .mkv
    'video/mpeg',
    'video/webm',
  ];

  if (videoTypes.includes(file.type)) {
    message.error('Video files are not allowed!');

    return Upload.LIST_IGNORE; // Reject video files
  }

  const isValidType = allowedTypes.includes(file.type);

  if (!isValidType) {
    message.error(
      'You can only upload PNG, JPG, JPEG, CSV, PDF, Excel, or Word files!',
    );
  }

  return isValidType || Upload.LIST_IGNORE; // Allow valid files or ignore invalid ones
};
export const validateImageBeforeUpload = (file: File) => {
  // Allowed image file types
  const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

  const isValidType = allowedTypes.includes(file.type);

  if (!isValidType) {
    message.error('You can only upload PNG, JPG, JPEG, or WEBP images!');

    return Upload.LIST_IGNORE; // Reject invalid files
  }

  return false; // Accept valid files
};
export const MonthValue = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const disabledDate = (current: dayjs.Dayjs) => {
  const today = dayjs();
  const endOfNextTwoMonths = today.add(2, 'month').endOf('month'); // End of the next two months

  // Disable future months beyond the next two months
  return current.isAfter(endOfNextTwoMonths, 'month');
};
export const getFileExtension = (url: string): string | null => {
  const pathname = new URL(url).pathname; // Extract the path from the URL
  const match = pathname.match(/\.(\w+)(?:\?|$)/); // Match file extension
  
return match ? match[1].toLowerCase() : null;
};
