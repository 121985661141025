import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AssignClientsResponse,
  AssignedClientsApiService,
  ListLegalDocument,
} from 'features/assigned-clients/api/assign-clients-api.service';
import qs from 'qs';

import { AssignClientsQueryKeys } from './query-keys';
const { getClientRequiredDocumentList } = AssignedClientsApiService();

const getLegalDocumentQuery = (
  params: ListLegalDocument,
  options?: UseQueryOptions<AssignClientsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<AssignClientsResponse>;
  options?: UseQueryOptions<AssignClientsResponse>;
} => ({
  queryKey: AssignClientsQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getClientRequiredDocumentList(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useLegalDocument = (
  params: any,
  options?: UseQueryOptions<AssignClientsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<AssignClientsResponse, Error>(
    getLegalDocumentQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

//   export const useLegalDocument = () => {
//     const { message } = App.useApp();

//     return useMutation(
//       (payload: ListLegalDocument) => {
//         const { Id } = payload;
//         console.log('Idddddd'+Id);
//         return getClientRequiredDocumentList({ Id });
//       },
//       {
//         onError: (error) => {
//           const uiMessage = handleError(error);

//           message.error(uiMessage);
//         },
//       },
//     );
//   };
