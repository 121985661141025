import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AssignClientsResponse,
  AssignedClientsApiService,
  ListParams,
} from 'features/assigned-clients/api/assign-clients-api.service';
import qs from 'qs';

import { AssignClientsQueryKeys } from './query-keys';
const { getManageUsers } = AssignedClientsApiService();

const getUsersQuery = (
  params: ListParams,
  options?: UseQueryOptions<AssignClientsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<AssignClientsResponse>;
  options?: UseQueryOptions<AssignClientsResponse>;
} => ({
  queryKey: AssignClientsQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getManageUsers(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetManageUserList = (
  params: any,
  options?: UseQueryOptions<AssignClientsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
