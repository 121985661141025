import { Menu } from 'antd';
import styled from 'styled-components';

export const SideBarWrapper = styled.div`
  z-index: 10; // to expand on top of content on mobile
  position: fixed;
  display: flex;
  overflow: auto;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  background: #282938;

  @media (max-width: 900px) {
    & .ant-layout-sider-below:after {
      content: '';
      position: fixed;
      background: hsl(0deg 0% 0% / 75%);
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      z-index: -1;
    }
    & .ant-layout-sider-collapsed.ant-layout-sider-below:after {
      display: none;
    }
  }

  // customization to match the designs

  .side-bar-sider {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    padding-top: ${({ theme }) => theme.constants.headerHeight};

    background: ${({ theme }) => theme.colors.leftmenu};

    .ant-layout-sider-trigger {
      border-top: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary};
      display: flex;
      align-items: center;
      justify-content: center;

      .side-bar-trigger-button {
        background-color: transparent;
        max-width: 48px;
        padding-left: 13px;
        padding-right: 13px;
      }

      .side-bar-trigger-collapsed {
        transform: rotate(180deg);
      }
    }
  }

  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
`;

export const StyledMenu = styled(Menu)`
  // customization to match the designs
  background: transparent;

  &.side-bar-menu {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .ant-menu-item,
    .ant-menu-submenu,
    .ant-menu-submenu-title {
      padding: 0px;
      margin-inline: 0;
      margin-block: 0;
      width: 100%;
      border-radius: 0;
    }
    .ant-menu-title-content {
      a {
        color: ${({ theme }) => theme.colors.leftmenudefault};
        &.active {
          color: #fff;
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-item {
        margin-block: 0;
        background-color: '#000000';
      }
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-item,
      & > .ant-menu-item-selected,
      .ant-menu-submenu-title {
        padding-inline: calc(50% - 0.8rem);

        .ant-menu-item-icon {
          padding-left: 0;
        }
      }
    }

    .ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
      background-color: ${({ theme }) => theme.colors.greenExtraLight};
    }
  }
`;
