import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Dropdown,
  Flex,
  Menu,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useDeleteDocuments } from 'features/assigned-clients/use-cases/delete.documents';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Folder, FolderPlus } from 'lucide-react';
import { Fragment, useState } from 'react';
import { getFileExtension } from 'routes/utils';

import { ThirdSubCategory } from './third-sub-category';
const { Title } = Typography;
export const SecondCategoryDocumentView = ({
  secondCategoryDocument,
  setCategoryList,
  setShowSubCategory,
  subCategoryName,
  setShowSecondCategory,
  setShowSubSecondCategory,
  setMoveToModal,
  setDocumentsInfo,
  setCommentsInfo,
  setAddCommentsModal,
}: {
  secondCategoryDocument: any;
  setCategoryList: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubCategory: React.Dispatch<React.SetStateAction<boolean>>;
  subCategoryName: string | undefined;
  setShowSecondCategory: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubSecondCategory: React.Dispatch<React.SetStateAction<boolean>>;
  setMoveToModal: any;
  setDocumentsInfo: any;
  setCommentsInfo: any;
  setAddCommentsModal: any;
}) => {
  const [showThirdCategory, setShowThirdCategory] = useState(false);
  const [showSubThirdCategory, setShowSubThirdCategory] = useState(true);
  const [thirdCategoryData, setThirdCategoryData] = useState({});
  const showCategory = () => {
    setCategoryList(true);
    setShowSubCategory(false);
    setShowSecondCategory(false);
    setShowSubSecondCategory(false);
  };
  const { mutate } = useDeleteDocuments();
  const showSubCatgoryDocument = () => {
    setCategoryList(false);
    setShowSubCategory(true);
    setShowSecondCategory(false); //
    setShowSubSecondCategory(true);
  };
  const showThirdCategoryData = (data: any) => {
    setShowSubThirdCategory(false);
    setShowThirdCategory(true);
    setThirdCategoryData(data);
  };
  const onChangeDelete = (valueInfo: any) => {
    const formvalue: any = {
      DocumentId: valueInfo?.documentId,
      IsAdmin: true,
    };
    //  return
    mutate(formvalue, {
      onSuccess: (data: any) => {
        if (data.rs === 1) {
          message.success('Deleted successfully');
          // Reload the current page to show updated data
          window.location.reload(); // This will refresh the current page
        } else {
          message.error('Failed to delete the document');
        }
      },
    });
  };
  const handleMenuClick = ({ key }: any, item: any) => {
    if (key === 'download') {
      message.success('Downloading ' + item.documentName);
      const link = document.createElement('a');
      link.href = item.documentPath; // File path or URL
      link.target = '_blank'; // Open in a new tab
      link.download = item.documentName; // Suggested file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (key === 'preview') {
      // Open the preview in a new tab
      Modal.info({
        title: 'Document View',
        width: '100%',
        icon: null,
        closable: true,
        closeIcon: <CloseOutlined />,
        style: { top: '0px', maxWidth: '500px' },
        content: (
          <div style={{ textAlign: 'start' }}>
            {(() => {
              const extension = getFileExtension(item.documentPath);

              if (['jpg', 'jpeg', 'png', 'gif'].includes(extension!)) {
                // If it's an image, display it directly
                return <iframe src={item.documentPath} width='100%' />;
              } else if (
                ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'].includes(
                  extension!,
                )
              ) {
                // Use Google Docs Viewer for documents
                return (
                  <iframe
                    src={`https://docs.google.com/gview?url=${item.documentPath}&embedded=true`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              } else {
                return (
                  <iframe
                    src={`${item.documentPath}`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              }
            })()}
          </div>
        ),
        okButtonProps: { style: { display: 'none' } },
      });
    } else if (key === 'Move To') {
      setMoveToModal(true);
      setDocumentsInfo(item);
    } else if (key === 'Delete') {
      onChangeDelete(item);
    } else if (key === 'Add Comment') {
      setAddCommentsModal(true);
      setCommentsInfo(item);
    }
  };
  const renderMenu = (item: any) => (
    <Menu
      onClick={({ key }) => handleMenuClick({ key }, item)}
      items={[
        { label: 'Download', key: 'download' },
        { label: 'Preview', key: 'preview' },
        { label: 'Move To', key: 'Move To' },
        { label: 'Add Comment', key: 'Add Comment' },
        { label: 'Delete', key: 'Delete' },
      ]}
    />
  );

  return (
    <Fragment>
      <Typography className='ctgrybx'>
        {showSubThirdCategory && (
          <ul>
            <li>
              <a href='javascript:void();' onClick={() => showCategory()}>
                Categories
              </a>
            </li>
            <li>
              <a
                href='javascript:void()'
                onClick={() => showSubCatgoryDocument()}
              >
                {subCategoryName}
              </a>{' '}
            </li>
            <li>{secondCategoryDocument.subCategory}</li>
          </ul>
        )}
      </Typography>

      {/* <Row className='fldrbx'>
        {Array.isArray(secondCategoryDocument.documentList) &&
          secondCategoryDocument.documentList.length
          ? secondCategoryDocument.documentList?.map(
            (item: any, index: number) => (
              <Card key={index}>
                <Flex>
                  <Typography className='meidafolder'>
                    <FileViewer documentPath={item.documentPath} />
                  </Typography>

                  <Typography className='cvnu'>
                    <Title className='crdttl' level={3}>
                      {item.documentName}

                      <dt className='stsbx'>
                        <dt
                          className={
                            item.documentStatus === 'Approved'
                              ? 'approved'
                              : item.documentStatus == 'Pending'
                                ? 'pending'
                                : 'rejected'
                          }
                        >
                          {item?.documentStatus == 'Reject'
                            ? 'Document Rejected'
                            : item?.documentStatus}
                        </dt>
                      </dt>

                      <dt className='mnubdrp'>
                        <Dropdown
                          overlay={renderMenu(item)}
                          trigger={['click']}
                        >
                          <MoreOutlined
                            style={{
                              fontSize: '24px',
                              cursor: 'pointer',
                            }}
                          />
                        </Dropdown>
                      </dt>
                    </Title>
                    <span>
                      {item?.totalComments
                        ? `${item?.totalComments} Comments`
                        : '0 Comments'}
                    </span>
                    {item.documentSize != null ? (
                      <dt className='tmrbx'>Size : {item.documentSize} </dt>
                    ) : (
                      ''
                    )}

                    <dt className='tmrbx'>
                      Last Update:{' '}
                      {dayjs(item.LastUpdated).format(
                        'DD MMM YYYY | hh:mm A',
                      )}
                    </dt>
                  </Typography>
                </Flex>
              </Card>
            ),
          )
          : ''}
      </Row> */}
      {showSubThirdCategory && (
        <Row className='fldrbx'>
          {Array.isArray(secondCategoryDocument.classCategoryList) &&
          secondCategoryDocument.classCategoryList.length
            ? secondCategoryDocument.classCategoryList?.map(
                (item: any, index: number) => (
                  <Card
                    hoverable
                    key={index}
                    role='button'
                    onClick={() => {
                      if (item.noOfDocuments > 0) {
                        showThirdCategoryData(item);
                      }
                    }}
                  >
                    <Flex>
                      <div className='meidafolder'>
                        {item.noOfDocuments > 0 ? (
                          <Folder
                            fill='#7D819E'
                            stroke='#7D819E'
                            className='meidafolder'
                          />
                        ) : (
                          <FolderPlus
                            stroke='#7D819E'
                            className='meidafolder'
                          />
                        )}
                      </div>

                      <Typography className='cvnu'>
                        <Title className='crdttl' level={3}>
                          {item.classCategory}
                        </Title>
                        {item.noOfDocuments > 0 ? (
                          <dt className='tmrbx'>
                            {item.noOfDocuments} Documents
                          </dt>
                        ) : (
                          <dt className='tmrbx'>Empty Folder </dt>
                        )}

                        {item.lastUpdatedDate && (
                          <dt className='tmrbx'>
                            Last Update{' '}
                            {dayjs(item.lastUpdatedDate).format(
                              'DD MMM YYYY | hh:mm A',
                            )}
                          </dt>
                        )}
                      </Typography>
                    </Flex>
                  </Card>
                ),
              )
            : ''}
        </Row>
      )}
      {showSubThirdCategory && (
        <Col span={24}>
          {secondCategoryDocument.documentList.length > 0 && (
            <Title level={4}>Document</Title>
          )}
          <Fragment>
            <Row className='fldrbx'>
              {Array.isArray(secondCategoryDocument.documentList) &&
              secondCategoryDocument.documentList.length
                ? secondCategoryDocument.documentList?.map((item: any) => (
                    <Card key={item?.documentId}>
                      <Flex>
                        <Typography className='meidafolder meida'>
                          {/* <Image src={item.documentPath} /> */}
                          <FileViewer documentPath={item.documentPath} />
                        </Typography>
                        <Typography className='cvnu'>
                          <Title className='crdttl' level={3}>
                            {item.documentName}

                            <dt className='stsbx'>
                              <dt
                                className={
                                  item.documentStatus === 'Approved'
                                    ? 'approved'
                                    : item.documentStatus == 'Pending'
                                    ? 'pending'
                                    : 'rejected'
                                }
                              >
                                {item?.documentStatus == 'Reject'
                                  ? 'Document Rejected'
                                  : item?.documentStatus}
                              </dt>
                            </dt>

                            <dt className='mnubdrp'>
                              <dt className='mnubdrp'>
                                <Dropdown
                                  overlay={renderMenu(item)}
                                  trigger={['click']}
                                >
                                  <MoreOutlined
                                    style={{
                                      fontSize: '24px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Dropdown>
                              </dt>
                            </dt>
                          </Title>
                          <dt className='tmrbx'>
                            {item?.totalComments
                              ? `${item?.totalComments} Comments`
                              : '0 Comments'}
                          </dt>
                          {item.documentSize != null ? (
                            <dt className='tmrbx'>
                              Size : {item.documentSize}{' '}
                            </dt>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </Flex>
                    </Card>
                  ))
                : ''}
            </Row>
          </Fragment>
        </Col>
      )}
      {showThirdCategory && (
        <ThirdSubCategory
          thirdCategoryData={thirdCategoryData}
          setCategoryList={setCategoryList}
          setShowSubCategory={setShowSubCategory}
          subCategoryName={subCategoryName}
          setShowSecondCategory={setShowSecondCategory}
          setShowSubSecondCategory={setShowSubSecondCategory}
          setCommentsInfo={setCommentsInfo}
          setAddCommentsModal={setAddCommentsModal}
          secondCategoryDocument={secondCategoryDocument}
          setShowSubThirdCategory={setShowSubThirdCategory}
          setShowThirdCategory={setShowThirdCategory}
        />
      )}
    </Fragment>
  );
};
