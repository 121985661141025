import { Form, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { UserInfoFormItems } from 'features/users/components/modals/user-info-form-items';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useGetUser, useUpdateUser } from 'features/users/use-cases';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EditAdminUserModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const formName = 'edit-user-form';
  const [form] = Form.useForm<UserFormValues>();
  const { t } = useTranslation();

  // Fetch user details
  const {
    isLoading: isLoadingGetUser,
    isFetching: isFetchingGetUser,
    data: userData,
    isError: isErrorUser,
  } = useGetUser(id, {
    cacheTime: 0,
  });

  // Mutation for updating user details
  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUser();

  // Combined loading state
  const isLoading = {
    loading: isLoadingGetUser || isFetchingGetUser || isLoadingUpdateUser,
    isLoadingGetUser,
    isLoadingUpdateUser,
  };

  const handleUpdate = (formData: UserFormValues) => {
    const payload: any = {
      UserId: id,
      RoleId: formData.RoleId,
    };

    mutateUpdateUser(payload, {
      onSuccess: () => {
        onSuccess();
        form.resetFields(); // Reset form after success
      },
      onError: (error) => {
        console.error('Update user failed:', error);
      },
    });
  };

  const getInitialValues = {
    [UserFormField.UserName]: userData?.rc?.[0]?.Name || '',
    [UserFormField.Email]: userData?.rc?.[0]?.Email || '',
    [UserFormField.Role]: userData?.rc?.[0]?.RoleId || '',
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={t('adminUsers.userInfo')}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('adminUsers.updateUser')}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
          loading: isLoadingUpdateUser, // Show loading only during update
        }}
      >
        {isLoadingGetUser ? (
          <Loader />
        ) : isErrorUser ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form<UserFormValues>
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              initialValues={getInitialValues}
              onFinish={handleUpdate}
              requiredMark='optional'
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading.loading}
            >
              <UserInfoFormItems Id={id} type={2} />
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
