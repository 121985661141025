import { Col } from 'antd';
import Title from 'antd/es/typography/Title';
import logoimg from 'assets/images/logo.png';
import { LoginForm } from 'features/auth/components';
import { useTranslation } from 'react-i18next';

import { StyledBackground, StyledCard, StyledRow } from './login.styles';

export const LoginView = () => {
  const { t } = useTranslation();

  return (
    <StyledBackground className='lgnbx'>
      <Col xs={24} sm={18} md={12} lg={8}>
        <Col
          className='ant-row ant-row-center ant-typography-h1 lgllogo'
          style={{ maxWidth: '500px' }}
        >
          <img src={logoimg} alt='' />
        </Col>

        <StyledCard>
          <StyledRow justify='center'>
            <Col className='lhead'>
              <Title level={1}>{t('login.loginHeading')}</Title>
              <Title level={5}>{t('login.description')}</Title>
            </Col>
          </StyledRow>

          <StyledRow justify='center'>
            <Col xs={24}>
              <LoginForm />
            </Col>
          </StyledRow>
        </StyledCard>
      </Col>
    </StyledBackground>
  );
};
