import { Button } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS, hideScrollbar } from 'styles';

export const StyledWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledHeadingWrapper = styled.div`
  ${BREAKPOINTS.laptop} {
  }
`;

export const StyledTitleWrapper = styled.div``;

export const StyledBackButton = styled(Button)`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.colorPrimaryText};
  padding: 0;
  margin: 0;
  display: flex;
`;

export const StyledHeadingContent = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  overflow: auto;
  ${hideScrollbar}

  ${BREAKPOINTS.laptop} {
    width: initial;
  }
`;
