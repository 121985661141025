import { Form, Grid } from 'antd';
import { Modal } from 'components';
import {
  SubFormField,
  SubFormValues,
} from 'features/subscription-management/components/subscription-filter-field/type';
import { useCreateSubscription } from 'features/subscription-management/use-cases/create-subscription';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';

import { SubInfoFormItems } from './subscription-info-item';
//
const { useBreakpoint } = Grid;

export const AddSubModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<SubFormValues>();

  const { isLoading, mutate } = useCreateSubscription();
  const getInitialValues = {
    [SubFormField.SubscriptionName]: undefined,
  };
  const handleUpdate = (FormData: SubFormValues) => {
    mutate(FormData, { onSuccess: onClose });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Create Subscription'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Create Subscription'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<SubFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <SubInfoFormItems />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
