import { Button, Card, Flex, Popconfirm, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { RejectDocumentModal } from 'features/assigned-clients/components/modals/reject-document-modal';
import { useClientDocumentApproveReject } from 'features/assigned-clients/use-cases/approve-reject-document';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Fragment, useState } from 'react';

const { Title } = Typography;
export const PendingDocumentView = ({
  pendingDocument,
  companyId,
  reFetchPendingDocument,
  documentsRefetch,
  getDocumentsCount,
}: // setCommentsInfo,
// setAddCommentsModal,
{
  pendingDocument: any;
  companyId: any;
  reFetchPendingDocument: () => void;
  documentsRefetch: any;
  getDocumentsCount: any;
  // setCommentsInfo: any;
  // setAddCommentsModal: any;
}) => {
  const { mutate: approveRejectDocuemnt } =
    useClientDocumentApproveReject(companyId);
  const [ApproveRjectModal, setApproveRjectModal] = useState(false);
  const [documentDetail, setDocumentDetails] = useState('');

  const onChnageApprove = (document: any) => {
    const formData = new FormData();
    formData.append('CompanyId', document.companyId);
    formData.append('DocumentId', document.documentId);
    formData.append('ServiceId', document.serviceId);
    formData.append('StatusId', '2');
    formData.append('Reason', '');
    formData.append('Comment', '');
    approveRejectDocuemnt(formData, {
      onSuccess: () => {
        reFetchPendingDocument();
        documentsRefetch();
        getDocumentsCount();
      },
    });
  };

  return (
    <Fragment>
      <Row className='fldrbx'>
        {Array.isArray(pendingDocument?.rc) && pendingDocument?.rc?.length
          ? pendingDocument?.rc?.map((item: any, index: number) => (
              <Card key={index}>
                <Flex>
                  <Typography className='meidafolder meida'>
                    <FileViewer documentPath={item.documentPath} />
                  </Typography>
                  <Typography className='cvnu'>
                    <Title className='crdttl' level={3}>
                      {item.documentName}

                      <dt className='stsbx'>
                        <dt
                          className={
                            item.documentStatus === 'approved'
                              ? 'approved'
                              : item.documentStatus == 'pending'
                              ? 'pending'
                              : 'rejected'
                          }
                        >
                          {item?.documentStatus == 'Reject'
                            ? 'Document Rejected'
                            : item?.documentStatus}
                        </dt>
                      </dt>
                    </Title>
                    <dt className='tmrbx flex'>
                      <dt>{item?.categoryName}</dt>
                      {item.documentSize != null ? (
                        <dt className='tmrbx ml-3'>
                          Size : {item.documentSize}{' '}
                        </dt>
                      ) : (
                        ''
                      )}
                    </dt>

                    {/* <span>
                      {item?.totalComments
                        ? `${item?.totalComments} Comments`
                        : '0 Comments'}
                    </span> */}

                    <dt className='tmrbx'>
                      Last Update:{' '}
                      {dayjs(item.lastUpdatedDate).format(
                        'DD MMM YYYY | hh:mm A',
                      )}
                      {/* 24 Sep, 2024 | 03:11 PM */}
                    </dt>

                    <dt className='button_group'>
                      {item?.documentStatus !== 'Approved' &&
                        item?.documentStatus !== 'Reject' && (
                          <div>
                            <Popconfirm
                              title='Approve the Document'
                              description='Are you sure want to approve this Document?'
                              onConfirm={() => onChnageApprove(item)}
                              okText='Yes'
                              cancelText='No'
                            >
                              <Button className='btn'>Approve</Button>
                            </Popconfirm>
                            <Button
                              className='btn'
                              onClick={() => {
                                setApproveRjectModal(true);
                                setDocumentDetails(item);
                              }}
                            >
                              Reject
                            </Button>
                          </div>
                        )}
                    </dt>
                  </Typography>
                </Flex>
              </Card>
            ))
          : ''}

        {ApproveRjectModal && (
          <RejectDocumentModal
            onClose={() => {
              setApproveRjectModal(false);
              reFetchPendingDocument();
            }}
            documentDetail={documentDetail}
            getDocumentsCount={getDocumentsCount}
            // Clientsdata={Clientsdata}
          />
        )}

        {/* {reminderModal && (
            <ReminderViewModal
              onClose={() => setReminderModal(false)}
              reminderId={companyId}
            />
          )} */}
      </Row>
    </Fragment>
  );
};
