import { apiClient } from 'api';

export type LoginDTO = {
  Email: string;
  Password: string;
  UserType: number;
};
export type VerifyOtp = {
  UserId: string;
  VerificationCode: string;
};

export type ForgotPasswordDTO = {
  Email: string;
  IsAdmin: boolean;
  UserType: number;
};

export type ResetPasswordDTO = {
  Password: string;
  UniqueId: string;
};

export type LoginResponse = {
  // access_token: string;
  token: string;
  refresh_token: string;
  type: string;
};

export type RefreshTokenDTO = {
  grant_type: 'refresh_token';
  token: string;
};

export const AuthApiService = () => {
  const login = async (payload: LoginDTO) => {
    const response = await apiClient<LoginResponse>({
      endpoint: '/Auth/login',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };

  const refreshToken = async (payload: RefreshTokenDTO) => {
    const response = await apiClient<LoginResponse>({
      endpoint: '/admin/login/refresh',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: false,
      },
    });

    return response.data;
  };
  const OtpApi = async (payload: VerifyOtp) => {
    const response = await apiClient<LoginResponse>({
      endpoint: '/Auth/verifyotpemail',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };
  const ResendOtpApi = async (payload: any) => {
    const response = await apiClient<LoginResponse>({
      endpoint: '/Auth/ResendOtp',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };
  const ForgotPassword = async (payload: ForgotPasswordDTO) => {
    const response = await apiClient({
      endpoint: '/Auth/forgotpasswordemail',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };

  const ResetPassword = async (payload: ResetPasswordDTO) => {
    const response = await apiClient({
      endpoint: '/UserManagement/SetPassword',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };

  return {
    login,
    refreshToken,
    OtpApi,
    ForgotPassword,
    ResetPassword,
    ResendOtpApi,
  };
};
