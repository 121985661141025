import { ContentFilters, ContentTopBar } from 'components';
import { EditSubViewModal } from 'features/subscription-management/components/modal/edit-subscription';
import { SubscriptionFilterForm } from 'features/subscription-management/components/subscription-filter-field/subscription-filter-form';
import { SubFormFilterValues } from 'features/subscription-management/components/subscription-filter-field/type';
import { useState } from 'react';
// const { useBreakpoint } = Grid;

type RoleProps = {
  setEditSubscriptionModal: any;
  editSubscriptionModal: boolean;
  editSubData: any;
  handleFilter: (values: SubFormFilterValues) => void;
  getInitialFilter: SubFormFilterValues;
};

export const SubscriptionTopBar = ({
  setEditSubscriptionModal,
  editSubscriptionModal,
  editSubData,
  handleFilter,
  getInitialFilter,
}: RoleProps) => {
  useState<any>();
  // const [showAddSubModal, setShowAddSubModal] = useState(false);
  // const screens = useBreakpoint();
  const formId = 'role-filters-form';

  return (
    <>
      <ContentTopBar
        title={'Subscription Management'}
        renderButtons={
          <>
            {/* <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => setShowAddSubModal(true)}
              style={{ backgroundColor: '#223666', color: 'white' }}
            >
              Add New Subscription
            </Button>
            {showAddSubModal && (
              <AddSubModal onClose={() => setShowAddSubModal(false)} />
            )} */}
            {editSubscriptionModal && (
              <EditSubViewModal
                onClose={() => setEditSubscriptionModal(false)}
                editSubData={editSubData}
              />
            )}
          </>
        }
      />
      <ContentFilters formId={formId}>
        <SubscriptionFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilter}
        />
      </ContentFilters>
    </>
  );
};
