import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateSubCategoryDTO,
  MasterApiService,
} from 'features/master-management/api/master-api.service';

import { MasterQueryKeys } from './query-keys';
const { createSubCategory } = MasterApiService();

export const useCreateSubCategory = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateSubCategoryDTO) => {
      return createSubCategory(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: MasterQueryKeys.lists(),
        });
      },
    },
  );
};
