import { Col, Empty, Form, Input, Space, Spin, Switch } from 'antd';
import { RoleFormField } from 'features/role-management/components/role-filter-field/type';
import { t } from 'i18next';
import { Fragment } from 'react';
export const RoleInfoFormItems = ({
  data,
  isFetching,
  switchStates,
  setSwitchStates,
}: any) => {
  const handleSwitchChange = (checked: boolean, id: string) => {
    setSwitchStates((prev: any) => ({
      ...prev,
      [id]: checked,
    }));
  };

  return (
    <Fragment>
      <Form.Item<RoleFormField>
        name={RoleFormField.RoleName}
        label={t('rolemanagement.formfilter.label')}
        rules={[
          {
            required: true,
            whitespace: false,
          },
        ]}
      >
        <Input placeholder={t('rolemanagement.formfilter.placeholder')} />
      </Form.Item>

      <Form.Item name={RoleFormField.PermissionIds}>
        {isFetching ? (
          <Spin className='ldrloder' /> // Display loading spinner while fetching
        ) : Array.isArray(data?.rc) && data?.rc?.length ? (
          data?.rc?.map((item: any, index: number) => (
            <Space
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 15,
              }}
            >
              {/* Display the permission name */}
              <span>{item?.Permission}</span>

              {/* Switch for toggling permission */}
              <Switch
                checked={switchStates[item.Id]} // Control the switch state
                onChange={(checked) => handleSwitchChange(checked, item.Id)} // Update the state on change
              />
            </Space>
          ))
        ) : (
          <Col span={24}>
            <Empty description='No Role Documents Found' />
          </Col> // Show empty message if no data
        )}
      </Form.Item>
    </Fragment>
  );
};
