import { Form, Input } from 'antd';
import { SubFormField } from 'features/subscription-management/components/subscription-filter-field/type';
import { Fragment } from 'react';

export const SubInfoFormItems = () => {
  return (
    <Fragment>
      <Form.Item<SubFormField>
        name={SubFormField.SubscriptionName}
        label={'Subscription Name'}
        rules={[
          {
            required: true,
            whitespace: false,
          },
        ]}
      >
        <Input placeholder={'Enter Subscription'} />
      </Form.Item>
    </Fragment>
  );
};
