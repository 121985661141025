import { Card, Col, Empty, Flex, Row, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { useGetAttentionDocumentList } from 'features/assigned-clients/use-cases/get-attention-documents';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Fragment, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
const { Title } = Typography;

export const AttentionDocumentView = ({
  month,
  year,
  setAddCommentsModal,
  setCommentsInfo,
  setCommentsMarkInfo,
  searchName,
}: any) => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const params = {
    PageIndex: 1,
    RecordPerPage: 10,
  };
  const {
    data: attentionDocuments,
    isFetching,
    refetch,
  } = useGetAttentionDocumentList({
    PageIndex: params?.PageIndex,
    RecordPerPage: params?.RecordPerPage,
    CompanyId: id,
    Year: year,
    Month: month,
    Name: searchName,
    ClientId: ids,
  } as any);

  const handleCardClick = (item: any) => {
    setAddCommentsModal(true);
    setCommentsInfo(item);
  };

  const handleFileViewerClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevents the card click from firing
  };

  const activeTab: any = localStorage.getItem('activeTab'); // Read the latest value each time
  useEffect(() => {
    if (activeTab === '4') {
      refetch(); // Trigger refetch if the active tab is 4
    }
  }, [refetch, year, month, searchName, activeTab]);
  
return (
    <Fragment>
      {isFetching ? (
        <Spin className='ldrloder' />
      ) : (
        <Row className='fldrbx'>
          {Array.isArray(attentionDocuments?.rc) &&
          attentionDocuments?.rc?.length ? (
            attentionDocuments?.rc?.map((item: any, index: number) => (
              <Card
                role='button'
                key={index}
                onClick={() => {
                  handleCardClick(item);
                  setCommentsMarkInfo('Attention');
                }}
              >
                <Flex>
                  <div className='meidafolder' onClick={handleFileViewerClick}>
                    <FileViewer documentPath={item.documentPath} />
                  </div>
                  <Typography className='cvnu'>
                    <Title className='crdttl' level={3}>
                      {item.documentName}
                    </Title>
                    <dt className='tmrbx'>{item?.documentSize}</dt>
                    <dt className='tmrbx'>
                      Last Update{' '}
                      {dayjs(item.lastUpdatedDate).format(
                        'DD MMM YYYY | hh:mm A',
                      )}{' '}
                    </dt>
                    <dt className='tmrbx'>
                      {item?.totalComments
                        ? `${item?.totalComments} Comments`
                        : '0 Comments'}
                    </dt>
                  </Typography>
                </Flex>
              </Card>
            ))
          ) : (
            <Col span={24}>
              <Empty description='No Attention Documents Found' />
            </Col>
          )}
        </Row>
      )}
    </Fragment>
  );
};
