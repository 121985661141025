import { Form, Grid, message } from 'antd';
import { Modal } from 'components';
import { SubCategoryFormValues } from 'features/master-management/components/filter-field/type';
import { useCreateSubCategory } from 'features/master-management/use-cases/create-subCategory';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { UserFormField } from 'features/users/components/users-filter-form';
import { useState } from 'react';

import { SubCategoryInfoFormItems } from './sub-category-info';
const { useBreakpoint } = Grid;
export const AddSubCategoryModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<SubCategoryFormValues>();
  const { data } = useGetCategoryList('' as any);
  const { isLoading, mutate } = useCreateSubCategory();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };
  const handleUpdate = (formData: any) => {
    mutate(formData, {
      onSuccess: (data1: any) => {
        if (data1?.rs == 1) {
          message.success('SubCategory Created Successfully');
        } else {
          message.error(data1?.rm);
        }
        onClose();
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Create Sub-Category'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Create SubCategory'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<SubCategoryFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <SubCategoryInfoFormItems data={data} />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
