import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
export interface ListParams extends PaginatedRequestParams {
  name?: string;
  email?: string;
  bussinessName?: string;
  retry?: boolean;
  lastUpdated?: string;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}
export type ListCategoryDocument = {
  CompanyId: string;
  Year?: number;
  Month?: number;
};

export type AssignedClientPendingDocumentList = {
  CompanyId: string;
  DocumentStatusId: number;
  Year?: number;
  Month?: number;
};
export type ListLegalDocument = {
  ClientId: string;
};
export type fetchFalg = {
  Flag: string;
};
export type CreateReminder = {
  CustomerId: string;
  Email: string;
  Message: string;
};
export type updateEmail = {
  CustomerId: string;
  Email: string;
};
export type AssignClientsResponse = PaginatedResponse<any>;

export const AssignedClientsApiService = () => {
  const createClients = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Client/CreateClient`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const clientsApproveReject = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Admin/ApproveRejectRequiredDocument`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const updateAssignMail = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Client/UpdateEmail`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const activateClients = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Client/ClientActivated`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const createReminder = async (
    payload: CreateReminder,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<AssignClientsResponse>({
      ...callConfig,
      endpoint: `/Client/ReminderMail`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getAssignClients = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<AssignClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/GetAssignClient`,
      params,
    });

    return response.data;
  };
  const GetAssignById = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<AssignClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/GetCompanyDetail/${id}`,
    });

    return response.data;
  };

  const UploadRequestDocuments = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/AdminDocuments/RequestForDocument`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const dropDownByFlag = async (
    Flag: any,
    ParentId: any, // Pass ParentId as a parameter
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<AssignClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Dropdown/Get?Flag=${Flag}&ParentId=${
        ParentId || ''
      }`,
    });

    return response;
  };
  const dropDownByClassFlag = async (
    params: any, // Pass ParentId as a parameter
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<AssignClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Dropdown/Get`,
      params,
    });

    return response;
  };
  const getRequestDocument = async (
    Id: string,
    params: any, // Include pagination parameters
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/GetAdminRequestedClientDocumentList?CompanyId=${Id}`,
      params,
    });

    return response.data;
  };

  const getClientRequiredDocumentList = async (
    params: ListLegalDocument, // Include pagination parameters
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/GetClientRequiredDocumentList`,
      params,
    });

    return response.data;
  };

  const getAssignedClientDocumentList = async (
    params: ListCategoryDocument, // Include pagination parameters
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/GetClientAssignedServices`,
      params,
    });

    return response.data;
  };
  const getAssignedClientPendingDocumentList = async (
    params: AssignedClientPendingDocumentList, // Include pagination parameters
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/GetClientDocumentList`,
      params,
    });

    return response.data;
  };

  const clientsDocumentApproveReject = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/AdminDocuments/ApproveNRejectDocument`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const adminRequestDocumentList = async (
    params: AssignedClientPendingDocumentList, // Include pagination parameters
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/GetAdminRequestedClientDocumentList`,
      params,
    });

    return response.data;
  };
  const getManagementReport = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/GetReportDocumentList`,
      params,
    });

    return response.data;
  };
  const UploadManagementReportAdmin = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/ClientDocuments/UploadReportDocuments`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const MarkAsFinished = async (payload: any) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      endpoint: `${baseURL}/ClientDocuments/UpdateMarkAsFinished`,
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };
  const getCommentsList = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/GetClientDocumentDetails`,
      params,
    });

    return response.data;
  };
  const AddComments = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/ClientDocuments/AddDocumentComment`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const createDocuments = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/ClientDocuments/UploadDocuments`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const getAttentionDocument = async (
    params: any, // Include pagination parameters
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/GetClientAttentionRequiredDocumentList`,
      params,
    });

    return response.data;
  };
  const MarkAsResolved = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/AdminDocuments/MarkAsResolved`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const CnacelCreateSubScription = async (payload: any) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      endpoint: `${baseURL}/Admin/CancelSubscription`,
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };
  const getManageUsers = async (
    params: any, // Include pagination parameters
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientUser/GetUserList`,
      params,
    });

    return response.data;
  };
  const createMoveDocuments = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/ClientDocuments/DocumentMoveTo`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const DeleteDocuments = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/ClientDocuments/DeleteDocument`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const UpdateAssign = async (payload: any) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      endpoint: `${baseURL}/Admin/UpdateAssignClient`,
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };
  const DocumentsCountValue = async (payload: any) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      endpoint: `${baseURL}/AdminDocuments/GetAdminDocumentTypeCount`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const RoleManagement = async (
    params: any, // Pass ParentId as a parameter
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await apiClient<AssignClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/RoleManagement/GetRoleList`,
      params,
    });

    return response;
  };
  const updateUser = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientUser/UpdateUser`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const updateStatus = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/UserManagement/UpdateUserStatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getMarkFinished = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/GetMarkAsFinished`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const updateReportMarkFinished = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/ClientDocuments/UpdateReportMarkAsFinished`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const deletReportDocument = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/AdminDocuments/DeleteReportDocument`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const notificatioRead = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/AdminDashboard/UpdateNotificationSeen`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const categoryUserDeleteStatus = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/DeleteAndUpdateCategoryFromClient`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const createClientUser = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/ClientUser/CreateUser`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const userManagementService = async (
    params: any, // Pass ParentId as a parameter
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await apiClient<AssignClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Client/GetClientUserServices`,
      params,
    });

    return response;
  };
  const createDashBoard = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/ClientDocuments/UploadRequiredDocuments`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  
return {
    getAssignClients,
    createClients,
    createReminder,
    updateAssignMail,
    activateClients,
    clientsApproveReject,
    GetAssignById,
    UploadRequestDocuments,
    dropDownByFlag,
    getRequestDocument,
    getClientRequiredDocumentList,
    getAssignedClientDocumentList,
    getAssignedClientPendingDocumentList,
    clientsDocumentApproveReject,
    adminRequestDocumentList,
    UploadManagementReportAdmin,
    getManagementReport,
    MarkAsFinished,
    getCommentsList,
    AddComments,
    createDocuments,
    getAttentionDocument,
    MarkAsResolved,
    CnacelCreateSubScription,
    getManageUsers,
    createMoveDocuments,
    DeleteDocuments,
    UpdateAssign,
    dropDownByClassFlag,
    DocumentsCountValue,
    RoleManagement,
    updateUser,
    updateStatus,
    getMarkFinished,
    updateReportMarkFinished,
    deletReportDocument,
    notificatioRead,
    categoryUserDeleteStatus,
    createClientUser,
    userManagementService,
    createDashBoard,
  };
};
