import { useSessionContext } from 'features/auth';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useGetUser } from './use-cases';
import { useGetMenu } from './use-cases/get-menu';
import { useGetPermissionList } from './use-cases/get-permission';
export type ContextValue =
  | { user: undefined; status: 'error' }
  | { user: undefined; status: 'loading' }
  | { user: any; status: 'success' };

export const UserContext = createContext<ContextValue | null>(null);
export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      'You tried to use UserContext outside the scope of the UserContextProvider.',
    );
  }

  return context;
};
export function UserContextProvider({ children }: { children: ReactNode }) {
  const { data } = useSessionContext();
  const userQueryResult: any = useGetUser(data?.userId as string, {
    enabled: Boolean(data?.userId),
    retry: false,
    onError: (error) => {
      console.error(error);
    },
  });
  const userQueryResultMenu: any = useGetMenu({
    enabled: Boolean(data?.userId),
    retry: false,
  } as any);
  //  permission api
  const userQueryResultPersmission: any = useGetPermissionList({
    RoleId: userQueryResult?.data?.rc[0]?.RoleId,
    enabled: Boolean(userQueryResult?.data?.rc[0]?.RoleId),
    UserType: 1,
    retry: false,
  } as any);

  const contextValue = useMemo(
    () =>
      ({
        status: userQueryResult.status,
        user: userQueryResult?.data?.rc[0],
        menuItem: userQueryResultMenu?.data,
        permissions: userQueryResultPersmission?.data,
      } as ContextValue),
    [
      userQueryResult.status,
      userQueryResult?.data?.rc[0],
      userQueryResultMenu?.data,
      userQueryResultPersmission?.data,
    ],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
