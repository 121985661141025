import { Button, Col, Form, Input, message } from 'antd';
import Title from 'antd/es/typography/Title';
import { ForgotPasswordDTO } from 'features/auth/api/auth-api.service';
import { useForgotPassword } from 'features/auth/use-cases';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import { StyledBackground, StyledCard, StyledRow } from './login.styles';

export const ResetView = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { mutate, isLoading } = useForgotPassword();

  const handleSubmit = ({
    Email,
    IsAdmin = true,
    UserType = 1,
  }: ForgotPasswordDTO) => {
    mutate(
      { Email, IsAdmin, persistSession: true, UserType },
      {
        onSuccess: (data: any) => {
          // console.log('data_response'+JSON.stringify(data));
          if (data?.rs == 2) {
            message.error(data?.rm);
          } else {
            message.success(data?.rm);
            navigate(ROUTES.LOGIN);
          }
        },
      },
    );
  };

  return (
    <StyledBackground>
      <Col xs={24} sm={18} md={12} lg={8}>
        <StyledCard>
          <StyledRow>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Title level={2}>{t('login.resetPassword.heading.label')}</Title>
              <Title level={5}>
                {t('login.resetPassword.heading.description')}
              </Title>
              <Title level={5}>
                {t('login.resetPassword.heading.required')}
              </Title>
            </Col>
          </StyledRow>
          <StyledRow justify='center'>
            <Col xs={24}>
              <Form form={form} layout='vertical' onFinish={handleSubmit}>
                <Form.Item
                  label={t('login.resetPassword.formFields.label')}
                  name={'Email'}
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input
                    placeholder={t(
                      'login.resetPassword.formFields.placeholder',
                    )}
                  />
                </Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  block
                  loading={isLoading}
                >
                  {t('login.resetPassword.button.label')}
                </Button>
              </Form>
            </Col>
          </StyledRow>
        </StyledCard>
      </Col>
    </StyledBackground>
  );
};
