import { Layout } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

type StyledLayoutProps = {
  $collapsed: boolean;
};

export const StyledLayout = styled(Layout)<StyledLayoutProps>`
  .layout-content {
    padding: ${({ theme }) =>
      theme.constants.smallMobileContentPadding().shorthand};
    margin: 0;
    background: ${({ theme }) => theme.colors.gray3};
    min-height: ${({ theme }) =>
      `calc(100vh - ${theme.constants.headerHeight})`};
    margin-top: ${({ theme }) => theme.constants.headerHeight};

    @media (max-width: 900px) {
      & {
        padding: 16px;
      }
    }

    ${BREAKPOINTS.mobile} {
      padding: 15px;
      margin-left: ${({ $collapsed }) => ($collapsed ? '48px' : '250px')};
      transition: margin-left 0.2s;
    }
  }

  .layout-space {
    height: 100%;
    width: 100%;

    & > .ant-space-item {
      height: 100%;
      width: 100%;
      @media (max-width: 900px) {
        position: relative;
      }
    }
  }
`;
