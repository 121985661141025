import { Checkbox, Col, Form, Input, message, Row } from 'antd';
import { Button } from 'components';
import { useLogin } from 'features/auth/use-cases';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import { LoginFormField, LoginFormValues } from './types';
export const LoginForm = () => {
  const { mutate, isLoading } = useLogin();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const { t } = useTranslation();
  const [form] = Form.useForm<LoginFormValues>();
  const navigate = useNavigate();

  const handleLogin = ({
    Email,
    Password,
  }: // RememberLogin,
  LoginFormValues) => {
    // Perform login mutation
    mutate(
      { Email, Password, persistSession: true, UserType: 1 },
      {
        onSuccess: (data: any) => {
          if (data?.rs == 1) {
            const userId = data?.res?.UserId;
            localStorage.setItem('userId', userId);
            localStorage.setItem('userEmail', Email);
            navigate(`${ROUTES.OTP}`, { replace: true });
          } else {
            message.error(data?.rm);
          }
        },
      },
    );
  };

  return (
    <Form
      className='formbx'
      form={form}
      layout='vertical'
      onFinish={handleLogin}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      wrapperCol={{ span: 24 }}
      validateTrigger={validateTrigger}
    >
      <Form.Item
        className='fw-500'
        label={t('login.formFields.email.label')}
        name={LoginFormField.Email}
        rules={[
          { required: true, message: 'Email is required' }, // Required validation
          { type: 'email', message: 'Please enter a valid email address' }, // Email format validation
        ]}
      >
        <Input
          className='form-control'
          type='text'
          autoComplete='username'
          placeholder={'Enter Email'}
        />
      </Form.Item>

      <Form.Item
        className='fw-500'
        label={t('login.formFields.password.label')}
        name={LoginFormField.Password}
        rules={[
          {
            required: true,
            message: 'Password is required',
          },
          // {
          //   pattern: /^\S*$/,
          //   message: 'Password cannot contain spaces'
          // },
          // {
          //   pattern: /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
          //   message: 'Password must include at least one uppercase letter, one number, and one special character.'
          // }
        ]}
      >
        <Input.Password
          className='form-control'
          autoComplete='current-password'
          placeholder={t('login.formFields.password.placeholder')}
        />
      </Form.Item>

      <Row justify='space-between' align='middle' className='checkbx'>
        <Col>
          <Form.Item
            name={LoginFormField.Remember}
            valuePropName='checked'
            initialValue={true}
          >
            <Checkbox>{t('login.formFields.rememberMe.label')}</Checkbox>
          </Form.Item>
        </Col>
        <Col>
          <Link className='default-color' to={ROUTES.Forgot_PASSWORD}>
            {t('login.formFields.passwordForget.label')}
          </Link>
        </Col>
      </Row>

      <Button
        className='btn default_gradient padding-tb-7'
        type='primary'
        htmlType='submit'
        block
        loading={isLoading}
      >
        {t('login.submit')}
      </Button>
    </Form>
  );
};
