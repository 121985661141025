import { ContentFilters, ContentTopBar } from 'components';
import { UserFilterFormsValues } from 'features/users/components/users-filter-form/types';
import { UsersFilterForm } from 'features/users/components/users-filter-form/users-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// ../users-filter-form

type UserProps = {
  showAddUserModal: boolean; // `showAddUserModal` should be a boolean since it's likely used to control modal visibility
  setShowAddUserModal: React.Dispatch<React.SetStateAction<boolean>>; // Function to toggle the modal state
  handleFilter: (values: UserFilterFormsValues) => void;
  getInitialFilterValues: UserFilterFormsValues;
};
export const UsersTopBar = ({
  handleFilter,
  getInitialFilterValues,
}: UserProps) => {
  useState<any>();
  const { t } = useTranslation();
  const formId = 'users-filters-form';

  return (
    <>
      <ContentTopBar
        title={t('usermanagement.heading')}
        renderButtons={
          <>
            {/* <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => setShowAddUserModal(true)}
              style={{ backgroundColor: '#223666', color: 'white' }}
            >
              {t('usermanagement.button.adduser')}
            </Button>
            {showAddUserModal && (
              <AddAdminUserModal
                onClose={() => setShowAddUserModal(false)}
                type={1}
              />
            )} */}
          </>
        }
      />
      <ContentFilters formId={formId}>
        <UsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
