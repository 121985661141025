import { PlusCircleOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Button, Space, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import dayjs from 'dayjs';
import { AssignFilterFormPaginated } from 'features/assigned-clients/components/form-filter/type';
import { usePermissions, useUserContext } from 'features/users';
import { FilePenLine } from 'lucide-react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Assined } from 'types/assign';

type AssignTableProps = {
  isListLoading: boolean;
  dataList: Assined[];
  itemsCount: number;
  tableParams: any;
  updateParams: Dispatch<SetStateAction<AssignFilterFormPaginated>>;
  setAssinedData: Dispatch<SetStateAction<string>>;
  setShowModal: any;
  setSubscriptionModal: any;
};

export const AssignTable = ({
  dataList,
  isListLoading,
  // itemsCount,
  tableParams,
  updateParams,
  setAssinedData,
  setShowModal,
  setSubscriptionModal,
}: AssignTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const navigate = useNavigate();
  const { permissions: permissionClients }: any = useUserContext();
  const permissionClientsData = permissionClients?.rc?.find(
    (item: any) => item?.Id === 5,
  );
  if (!permissions) return null;
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Assined> | SorterResult<Assined>[],
  ) => {
    updateParams({
      ...tableParams,
      PageIndex: pagination.current,
      RecordPerPage: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  return (
    <StyledTableWrapper className='card grid_secton'>
      <StyledTableHeader className='gs_head'>
        <h3>
          Assign List{' '}
          <span>{dataList?.length > 0 ? dataList[0].totalRecords : '0'}</span>
        </h3>

        <div className='buttongroup'>
          {permissionClientsData?.IsChecked && (
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => navigate(ROUTES.CLIENTS_ADD)}
              style={{ backgroundColor: '#223666', color: 'white' }}
            >
              {t('assignedClient.button.addClient')}
            </Button>
          )}
        </div>
      </StyledTableHeader>

      <div className='cstmtblbx'>
        <div className='cstm_table'>
          <Table<Assined>
            loading={isListLoading}
            dataSource={dataList}
            rowKey='id'
            onChange={handleTableChange}
            pagination={{
              current: tableParams.PageIndex,
              defaultPageSize: tableParams.RecordPerPage,
              total:
                dataList && dataList.length > 0 ? dataList[0]?.totalRecords : 0,
            }}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  navigate(
                    `${getRoute(
                      ROUTES.ASSIGNED_DETAILS,
                      record.companyId,
                    )}?clsid=${record.id}`,
                  );
                },
                style: { cursor: 'pointer' },
              };
            }}
            components={{
              header: {
                cell: (
                  props: React.HTMLAttributes<HTMLTableHeaderCellElement>,
                ) => <th {...props} />,
              },
            }}
          >
            <Column
              title={'Sr No'}
              dataIndex={'Sr No'}
              render={(_, record, index) =>
                (tableParams.PageIndex - 1) * tableParams.RecordPerPage +
                index +
                1
              }
            />

            <Column
              title={t('assignedClient.table.clientName')}
              dataIndex={'name'}
            />
            <Column
              title={t('assignedClient.table.clientEmail')}
              dataIndex={'email'}
            />

            <Column
              title={t('assignedClient.table.businessName')}
              dataIndex={'businessName'}
            />
            <Column
              title={'Assigned Admin'}
              dataIndex={'assignAdmin'}
              render={(_, record: any) => (
                <span>
                  {record?.assignAdminData
                    ?.map((item: any) => item.name)
                    .join(', ')}
                </span>
              )}
            />
            <Column
              title={'Subscription type'}
              dataIndex={'subscriptionName'}
            />
            <Column
              title={t('assignedClient.table.LastUpdate')}
              dataIndex={'lastUpdated'}
              render={(_, record: any) => {
                return dayjs(record?.lastUpdated).format('DD MMM YYYY');
              }}
            />
            <Column title={'Status'} dataIndex={'status'} />
            <Column
              title={t('assignedClient.table.action')}
              dataIndex={'user'}
              render={(_, record: any) => (
                <Space className='grdbtngroup'>
                  <Button
                    className='btn'
                    type='link'
                    onClick={(e) => {
                      setAssinedData(record);
                      e.stopPropagation(); // Prevents row click event
                      setShowModal(true);
                    }}
                    icon={<FilePenLine height={16} />}
                  />
                  <Button
                    className='btn'
                    type='link'
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents row click event
                      setSubscriptionModal(true);
                      setAssinedData(record);
                    }}
                    icon={<PoweroffOutlined />}
                  />
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </StyledTableWrapper>
  );
};
