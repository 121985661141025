import { ClientsView } from 'features/new-clients/components/clients-details/clients-view';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
// import { ROUTES } from 'routes/constants';
export const ClientsDetailsView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const navigateToList = () => {
    navigate(ROUTES.CLIENTS);
  };

  // Id={id} onSuccess={navigateToList}
  return <ClientsView Id={id} onSuccess={navigateToList} />;
};
