import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Popconfirm,
  Row,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
// import { NewClientsApiService } from 'features/new-clients/api/new-clients-api.service';
import { useApproveReject } from 'features/new-clients/use-cases/approve-reject';
// import { useApproveReject } from 'features/new-clients/use-cases/approve-reject';
import { Fragment } from 'react';

// import { useParams } from 'react-router-dom';
import FileViewer from './file-viewer';

const { Title } = Typography;

export const ClientsViewActivateInfo = ({
  setShowModal,
  setApproveRjectModal,
  setDocumentDetails,
  // documentDetail,
  setReminderModal,
  intialFetch,
  documentList,
  Clientsdata,
}: // documentDetail,
// setReminderModal,
// Clientsdata,
any) => {
  // const { id } = useParams();
  // const { data:documentList } = useGetDocumentList(id as any);
  // const [documentList, setDocumentList] = useState<any>('');
  // const intialFetch = async () => {
  //   try {
  //     const apiRes = await NewClientsApiService().getDocument(id as any);
  //     setDocumentList(apiRes as any);
  //   } catch (error: any) {
  //     message.error(error);
  //   }
  // };
  const { mutate } = useApproveReject();

  const onChangeApprove = (value: any) => {
    const formData = new FormData();
    formData.append('ClientId', Clientsdata?.rc[0]?.companyId);
    formData.append('DocumentId', value.documentId);
    formData.append('StatusId', '2');

    mutate(formData, {
      onSuccess: () => {
        intialFetch();
      },
    });
  };

  // useEffect(() => {
  //   intialFetch();
  // }, []);

  return (
    <Fragment>
      <Row className='crd_head' justify='space-between'>
        <Col>
          <Title level={4}>Documents</Title>
        </Col>
        <Col>
          <Button type='primary' onClick={() => setShowModal(true)}>
            Activate Account
          </Button>
        </Col>
      </Row>
      <Row className='fldrbx'>
        {Array.isArray(documentList?.rc) && documentList?.rc?.length ? (
          documentList?.rc?.map((item: any, index: number) => (
            <Card key={index}>
              <Flex>
                <Typography className='meida meidafolder'>
                  <FileViewer documentPath={item.documentPath} />
                </Typography>
                <Typography className='cvnu'>
                  <Title className='crdttl' level={3}>
                    {item.documentName}

                    <dt className='stsbx'>
                      <dt
                        style={{
                          color: item.status === 'Approved' ? 'white' : 'red',
                          backgroundColor:
                            item.status === 'Approved'
                              ? 'green'
                              : item.status === 'Pending'
                              ? 'lightcoral'
                              : 'lightpink',
                          padding: '2px',
                          borderRadius: '4px',
                        }}
                      >
                        {item?.status === 'Reject' ? 'Rejected' : item?.status}
                      </dt>
                    </dt>
                  </Title>

                  {item.documentSize != null ? (
                    <dt className='tmrbx'>Size : {item.documentSize} </dt>
                  ) : (
                    ''
                  )}

                  <dt className='tmrbx'>
                    Last Update:{' '}
                    {dayjs(item.lastUpdatedDate).format(
                      'DD MMM YYYY | hh:mm A',
                    )}
                    {/* 24 Sep, 2024 | 03:11 PM */}
                  </dt>

                  <dt className='button_group'>
                    {item?.status !== 'Approved' &&
                      item?.status !== 'Reject' && (
                        <div>
                          <Popconfirm
                            title='Approve the Document'
                            description='Are you sure want to approve this Document?'
                            okText='Yes'
                            onConfirm={() => onChangeApprove(item)}
                            cancelText='No'
                          >
                            <Button className='btn'>Approve</Button>
                          </Popconfirm>
                          <Button
                            className='btn'
                            onClick={() => {
                              setApproveRjectModal(true);
                              setDocumentDetails(item);
                            }}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                  </dt>
                </Typography>
              </Flex>
              {item.status === 'Reject' && (
                <div className='bn_grp'>
                  <dt>
                    <Title level={5}>{item?.reason}</Title>
                    <p>{item?.comments}</p>
                  </dt>
                  <Button onClick={() => setReminderModal(true)}>
                    Send Reminder
                  </Button>
                </div>
              )}
            </Card>
          ))
        ) : (
          <Col span={24}>
            <Empty description='No Clients activate Documents Found' />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};
