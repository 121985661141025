import { Form, Grid, message } from 'antd';
import { Modal } from 'components';
import { CreateCategoryDTO } from 'features/master-management/api/master-api.service';
import {
  CategoryFormField,
  CategoryFormValues,
} from 'features/master-management/components/filter-field/type';
import { useCreateCategory } from 'features/master-management/use-cases/create-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';

import { CategoryInfoFormItems } from './category-info-item';

const { useBreakpoint } = Grid;
export const EditCategoryViewModal = ({
  onClose,
  editData,
}: {
  onClose: () => void;
  editData: any;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CategoryFormValues>();
  const { isLoading, mutate } = useCreateCategory();
  const getInitialValues = {
    [CategoryFormField.CategoryName]: editData.categoryName,
  };
  const handleUpdate = (values: CategoryFormValues) => {
    const payload: CreateCategoryDTO = {
      [CategoryFormField.Id]: editData?.id,
      [CategoryFormField.CategoryName]: values.categoryName,
    };
    mutate(payload, {
      onSuccess: (data: any) => {
        onClose();
        if (data?.rs == 1) {
          message.success('Category Edit successfully');
        } else {
          message.error(data?.rm);
        }
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Edit Category'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Edit Category'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<CategoryFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <CategoryInfoFormItems />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
