import { Button, Form, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { StyledTableWrapper } from 'components/table/table.styled';
import {
  CategoryFormFilterField,
  CategoryFormFilterValues,
} from 'features/master-management/components/filter-field/type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type RoleFilterFormProps = {
  formId: string;
  handleFilter: (values: CategoryFormFilterValues) => void;
  initialValues: CategoryFormFilterValues;
  closeModal?: () => void;
};

export const CategoryClientsFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: RoleFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CategoryFormFilterValues>();
  const { t } = useTranslation();
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };
  const onFinish = (formValues: CategoryFormFilterValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <StyledTableWrapper className='card filter_card inrfltr'>
      <Form<CategoryFormFilterValues>
        id={formId}
        name={formId}
        className='filters-form'
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        validateTrigger={validateTrigger}
      >
        <ContentFilters.FormItemsWrapper>
          <StandardItemsWrapper>
            <Form.Item<CategoryFormFilterField>
              label={'Category Name'}
              name={CategoryFormFilterField.CategoryName}
            >
              <Input placeholder={'Enter Category Name'} />
            </Form.Item>
          </StandardItemsWrapper>
        </ContentFilters.FormItemsWrapper>

        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{'Clear'}</Button>
        </ContentFilters.ButtonsWrapper>
      </Form>
    </StyledTableWrapper>
  );
};
