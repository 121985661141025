export enum AssignFormField {
  Clients = 'ClientName',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  IndustryId = 'IndustryId',
  BusinessName = 'BusinessName',
  BusinessWebsite = 'BusinessWebsite',
  Address = 'Address',
  CityId = 'CityId',
  Description = 'Description',
  SubscriptionId = 'SubscriptionId',
  SubCategoryAccess = 'SubCategoryAccess',
  SendMailAccess = 'SendMailAccess',
  file = 'file',
  FileName = 'FileName',
}
export type ClientsFormValues = {
  [AssignFormField.Clients]: string | undefined;
  [AssignFormField.Email]: string | undefined;
  [AssignFormField.PhoneNumber]: string | undefined;
  [AssignFormField.IndustryId]: string | undefined;
  [AssignFormField.BusinessName]: string | undefined;
  [AssignFormField.BusinessWebsite]: string | undefined;
  [AssignFormField.Address]: string | undefined;
  [AssignFormField.CityId]: string | undefined;
  [AssignFormField.Description]: string | undefined;
  [AssignFormField.SubCategoryAccess]: string | undefined;
  [AssignFormField.SendMailAccess]: string | undefined;
  [AssignFormField.file]: string | undefined;
  [AssignFormField.FileName]: string | undefined;
};
