import { ContentFilters, ContentTopBar } from 'components';
import { ClientsFilterForm } from 'features/new-clients/components/form-filter/clients-filter-form';
import { clientsFormFilterValue } from 'features/new-clients/components/form-filter/type';
import { EmailViewModal } from 'features/new-clients/components/modal/email-modal';
import { ReminderViewModal } from 'features/new-clients/components/modal/reminder-modal';
import { useTranslation } from 'react-i18next';

type ClientsProps = {
  reminderModal: boolean;
  mailModal: boolean;
  setMailModal: React.Dispatch<React.SetStateAction<boolean>>;
  reminderId: string;
  setReminderModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleFilters: (values: clientsFormFilterValue) => void;
  getInitialFilterValues: clientsFormFilterValue;
};

export const ClientsTopBar = ({
  reminderModal,
  setReminderModal,
  reminderId,
  mailModal,
  handleFilters,
  getInitialFilterValues,
  setMailModal,
}: ClientsProps) => {
  const { t } = useTranslation();
  const formId = 'new-clients-filters-form';

  return (
    <>
      <ContentTopBar
        title={t('newclient.heading')}
        renderButtons={
          <>
            {/* <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => navigate(ROUTES.CLIENTS_ADD)}
              style={{ backgroundColor: '#223666', color: 'white' }}
            >
              {t('assignedClient.button.addClient')}
            </Button> */}
            {reminderModal && (
              <ReminderViewModal
                onClose={() => setReminderModal(false)}
                reminderId={reminderId}
              />
            )}
            {mailModal && (
              <EmailViewModal
                onClose={() => setMailModal(false)}
                reminderId={reminderId}
              />
            )}
          </>
        }
      />
      <ContentFilters formId={formId}>
        <ClientsFilterForm
          formId={formId}
          handleFilter={handleFilters}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
