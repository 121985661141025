import { Button, Form, Grid, Input, message, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { MasterApiService } from 'features/master-management/api/master-api.service';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { classFormFilterField, ClassFormFilterValues } from './type';
const { useBreakpoint } = Grid;
type SubFilterFormProps = {
  formId: string;
  handleFilter: (values: ClassFormFilterValues) => void;
  initialValues: ClassFormFilterValues;
  closeModal?: () => void;
};

export const ClassFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: SubFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<ClassFormFilterValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { data } = useGetCategoryList('' as any);
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };
  const onFinish = (formValues: ClassFormFilterValues) => {
    handleFilter(formValues);
    closeModal?.();
  };
  const [subCaetgory, setSubCategory] = useState<any>('');
  const subCategory = async (id: any) => {
    try {
      const apiRes = await MasterApiService().GetSubCategoryById(
        `CategoryId=${id}` as any,
      );
      setSubCategory(apiRes);
    } catch (error) {
      message.error('something is wrong');
    }
  };

  return (
    <Form<ClassFormFilterValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<classFormFilterField>
            label={'Category Name'}
            name={classFormFilterField.CategoryId}
          >
            <Select
              options={data?.rc.map((item) => ({
                label: item.categoryName, // Correctly returning an object with label and value
                value: item.id,
              }))}
              onChange={(value) => subCategory(value)}
              placeholder='please select'
            />
          </Form.Item>

          <Form.Item<classFormFilterField>
            label={'SubCategory Name'}
            name={classFormFilterField.SubCategoryId}
          >
            <Select
              options={subCaetgory?.rc?.map((item: any) => ({
                label: item.subCategoryName, // Correctly returning an object with label and value
                value: item.id,
              }))}
              placeholder='please Select'
            />
          </Form.Item>
          <Form.Item<classFormFilterField>
            name={classFormFilterField.ClassName}
            label='Class Name'
          >
            <Input placeholder='Enter Class Name' />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
