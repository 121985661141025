import { Button, Col, Form, message, Row, Select, Space, Switch } from 'antd';
import { StyledFiltersWrapper } from 'components/content-filters/content-filters.styles';
import { useGetAssignDetails } from 'features/assigned-clients/use-cases/get-assignId';
import { EditCoustmizeModal } from 'features/new-clients/components/modal/coustmize-edit';
// import { AddCoustmizeModal } from 'features/new-clients/components/modal/coustmize-view';
import { useUpdateClients } from 'features/new-clients/use-cases/create.clients';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { useGetSubCategoryCotmizeList } from 'features/new-clients/use-cases/sub-category-access';
import { useUserContext } from 'features/users';
import { t } from 'i18next';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { EditNewClientsInfo } from './edit-new-clients-info';
import { editAssignFormField, EditAssignFormValues } from './type';

export const ClientsProfile = () => {
  const [form] = Form.useForm<EditAssignFormValues>();
  const location = useLocation();
  const { id } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('temp_clie');
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [subpayload, setSubCategoryPayload] = useState('');
  const { data: Subscription } = useDropDownFlag('Subscription');
  const { user } = useUserContext();
  const [showModal, setShowModal] = useState(false);
  const { mutate, isLoading } = useUpdateClients();
  const { data: subcategoryaccess } = useGetSubCategoryCotmizeList({
    CompanyId: id,
  } as any);
  const QueryResult: any = useGetAssignDetails(ids as any, {
    cacheTime: 0,
  });
  const preparePayload = (data: any) => {
    const serviceList: { ServiceId: number }[] = [];
    // Loop through each category to gather ServiceIds for categories, subcategories, and class categories
    data.rc.forEach((category: any) => {
      if (category.IsChecked) {
        // Add main category ServiceId
        serviceList.push({ ServiceId: category.Id });
        category.SubCategoryList?.forEach((sub: any) => {
          if (sub.IsChecked) {
            // Add subcategory ServiceId
            serviceList.push({ ServiceId: sub.Id });

            sub.ClassCategoryList?.forEach((cls: any) => {
              if (cls.IsChecked) {
                // Add class category ServiceId
                serviceList.push({ ServiceId: cls.Id });
              }
            });
          }
        });
      }
    });

    return serviceList;
  };

  const {
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
    data,
  } = QueryResult;
  useEffect(() => {
    if (data?.rc?.[0]?.clientName) {
      form.setFieldsValue({
        [editAssignFormField.Clients]: data.rc[0].clientName,
        [editAssignFormField.IndustryId]: data.rc[0].industryId,
        [editAssignFormField.BusinessName]: data.rc[0].businessName,
        [editAssignFormField.CityId]: data.rc[0].cityId,
        [editAssignFormField.PhoneNumber]: data.rc[0].phoneNumber,
        [editAssignFormField.Email]: data.rc[0].email,
        [editAssignFormField.Address]: data.rc[0].address,
        [editAssignFormField.BusinessWebsite]: data.rc[0].businessWebsite,
        [editAssignFormField.Description]: data.rc[0].description,
        [editAssignFormField.SubscriptionId]: data?.rc[0].subscriptionId,
        [editAssignFormField.SubCategoryAccess]: data?.rc[0].subCategoryAccess,
        [editAssignFormField.SendMailAccess]: data?.rc[0].sendMailAccess,
      });
    }
  }, [data, form]);

  const onFinish = (formdata: any) => {
    try {
      const file = fileList[0]?.originFileObj;
      const formData = new FormData();
      formData.append('LoggedInUserId', user?.Id);
      formData.append('CompanyId', data.rc[0].companyId);
      formData.append('ClientId', data.rc[0].clientId);
      formData.append('ClientName', formdata.clientName);
      formData.append('Email', formdata.Email);
      formData.append('PhoneNumber', formdata.PhoneNumber);
      formData.append('IndustryId', formdata.IndustryId);
      formData.append('BusinessName', formdata.BusinessName);
      formData.append('BusinessWebsite', formdata.BusinessWebsite);
      formData.append('Address', formdata.Address);
      formData.append('CityId', formdata.CityId);
      formData.append('Description', formdata.Description);

      formData.append('SubscriptionId', formdata.subscriptionId);

      formData.append('SubCategoryAccess', formdata.SubCategoryAccess);
      if (formdata?.SendMailAccess) {
        formData.append('SendMailAccess', formdata.SendMailAccess);
      } else {
        formData.append('SendMailAccess', 'false');
      }

      if (file) {
        formData.append('file', file);
      } else {
        formData.append('file', 'null');
      }

      if (file?.name) {
        formData.append('FileName', file.name);
      } else {
        formData.append('FileName', '');
      }
      const payload = preparePayload(subcategoryaccess);
      // Ensure subpayload is correctly set
      const serviceListPayload = subpayload ? subpayload : payload;
      formData.append('ServiceListTemp', JSON.stringify(serviceListPayload));

      // formData.append(
      //   'ServiceListTemp',
      //   subpayload ? JSON.stringify(subpayload) :payload,
      // );
      mutate(formData, {
        onSuccess: (dataSuccess) => {
          if (dataSuccess?.rs == 1) {
            message.success('Client Update Succussfully');
          }
        },
      });
      //  { onSuccess: onCancel }
    } catch (error: any) {
      message.error('Error updating brand:', error);
    }
  };

  useEffect(() => {
    if (data?.rc?.[0]?.clientImage) {
      setFileList([
        {
          uid: '-1', // Unique identifier for existing file
          name: 'clientImage', // Display name
          status: 'done',
          url: data.rc[0].clientImage, // Existing image URL from backend
        },
      ]);
    }
  }, [data]);

  return (
    <Fragment>
      <Form<EditAssignFormValues>
        form={form}
        autoComplete='off'
        layout='vertical'
        disabled={isLoadingGet || isFetchingGet}
        onFinish={onFinish}
      >
        <StyledFiltersWrapper className='card editbxnew'>
          <EditNewClientsInfo
            fileList={fileList}
            setFileList={setFileList}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
          />
          <Row gutter={24}>
            <Col className='' xs={24} lg={4}>
              &nbsp;
            </Col>
            <Col className='sssbx' xs={24} lg={20}>
              <Row gutter={24}>
                <Col xs={24} lg={6}>
                  <Form.Item
                    label='Subscription'
                    rules={[
                      { required: true, message: 'Subscription is required' },
                    ]}
                    name={editAssignFormField.SubscriptionId}
                  >
                    <Select
                      options={Subscription?.data?.rc.map((item: any) => ({
                        label: item.text,
                        value: item.value,
                      }))}
                      placeholder='Please Select'
                    />
                  </Form.Item>

                  <Form.Item
                    label={'Sub-Category access'}
                    name='SubCategoryAccess'
                    valuePropName='checked'
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    label='Send Mail'
                    name='SendMailAccess'
                    valuePropName='checked'
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item label='Customize Access'>
                    <Button type='primary' onClick={() => setShowModal(true)}>
                      Select
                    </Button>
                  </Form.Item>

                  <Space className='button_group' size={20}>
                    <Form.Item>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={isLoading}
                      >
                        {t('newclient.formFields.submitbutton.button')}
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType='button'
                        onClick={() => form.resetFields()}
                      >
                        {t('newclient.formFields.cancel.button')}
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledFiltersWrapper>
      </Form>
      {/* {showModal && (
        <AddCoustmizeModal
          onClose={() => setShowModal(false)}
          setSubCategoryPayload={setSubCategoryPayload}
        />
      )} */}
      {showModal && (
        <EditCoustmizeModal
          onClose={() => setShowModal(false)}
          setSubCategoryPayload={setSubCategoryPayload}
        />
      )}
    </Fragment>
  );
};
