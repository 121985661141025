import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SubCategoryFormFilterField,
  SubCategoryFormFilterValues,
} from './type';
const { useBreakpoint } = Grid;
type SubFilterFormProps = {
  formId: string;
  handleFilter: (values: SubCategoryFormFilterValues) => void;
  initialValues: SubCategoryFormFilterValues;
  closeModal?: () => void;
};

export const SubCategoryFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: SubFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<SubCategoryFormFilterValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };
  const onFinish = (formValues: SubCategoryFormFilterValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <Form<SubCategoryFormFilterValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<SubCategoryFormFilterField>
            label={'Sub Category Name'}
            name={SubCategoryFormFilterField.SubCategoryName}
          >
            <Input placeholder={'Enter Sub Category'} />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
