import { Table as AntTable } from 'antd';
import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  /* Table Css*/
`;

export const StyledTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledTable = styled(AntTable)`
  .ant-table-tbody {
    > .ant-table-row { }
      .ant-table-cell {
        vertical-align: middle;

        &.cell-direction-rtl {
          font-size: 1.1rem;
          direction: rtl;
        }

        // updated rows background for better distinction
        &.ant-table-cell-row-hover {
          background-color: ${({ theme }) => theme.colors.gray3};
        }
        // fixed columns need separate background update
        &.ant-table-cell-fix-right {
          &.ant-table-cell-row-hover {
            background-color: ${({ theme }) => theme.colors.gray3};
          }
        }
      }
    }
  }
` as typeof AntTable;
