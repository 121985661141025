import {
  CategoryFilterFormValuesPaginated,
  CategoryFormFilterValues,
} from 'features/master-management/components/filter-field/type';
import { CategoryTableItemView } from 'features/master-management/components/table/category-table/category-table-view';
import { CategoryTopBar } from 'features/master-management/components/top-bar/category-top';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export const CategoryItemView = () => {
  const getInitialFilterValues: CategoryFormFilterValues = {
    CategoryName: undefined,
  };
  const { params, updateParams, resetPage } =
    usePaginationParams<CategoryFilterFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState('');
  const { data, isFetching } = useGetCategoryList(params, {
    retry: false,
  });
  const handleFiltersChange = (values: CategoryFormFilterValues) => {
    updateParams(values);
    resetPage();
  };

  return (
    <>
      <CategoryTopBar
        setEditModal={setEditModal}
        editModal={editModal}
        editData={editData}
        // refetch={refetch}
        handleFilter={handleFiltersChange}
        getInitialFilter={getInitialFilterValues}
      />
      <CategoryTableItemView
        isFetching={isFetching}
        CategoryList={data?.rc || []}
        tableParams={params}
        setEditModal={setEditModal}
        setEditData={setEditData}
      />
      <Outlet />
    </>
  );
};
