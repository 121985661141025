import { Form, Grid, message } from 'antd';
import { Modal } from 'components';
import { CreateClassDTO } from 'features/master-management/api/master-api.service';
import { ClassFormValues } from 'features/master-management/components/filter-field/type';
import { useCreateClassCategory } from 'features/master-management/use-cases/create-class';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { UserFormField } from 'features/users/components/users-filter-form';
import { useState } from 'react';

import { ClassInfoFormItems } from './class-master-info';
const { useBreakpoint } = Grid;

export const AddClassModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<ClassFormValues>();
  const { data } = useGetCategoryList('' as any);

  const { isLoading, mutate } = useCreateClassCategory();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };
  const handleUpdate = (formData: ClassFormValues) => {
    const payload: CreateClassDTO = {
      className: formData.className,
      subCategoryId: formData.subCategoryId,
      categoryId: formData.categoryId,
      frequencyId: formData.frequencyId,
    };
    mutate(payload, {
      onSuccess: (data1: any) => {
        onClose();
        if (data1?.rs == 1) {
          message.success('Class created successfully');
        } else {
          message.error(data1?.rm);
        }
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Create Class'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Create Class'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<ClassFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <ClassInfoFormItems data={data} />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
