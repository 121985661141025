import { DashBoardApiService } from 'features/Dashboard/api/dashboard-api.service';
import { Fragment, useEffect, useState } from 'react';
import {
  Cell,
  LabelList,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const COLORS = ['#00C49F', '#0088FE', '#FF8042']; // Green for Approved, Blue for Pending, Orange for Reject

export const ClientsDocuments = () => {
  const [clientData, setClientData] = useState({
    activeClients: 0,
    inactiveClients: 0,
  });
  const [documentsData, setDocumentsData] = useState({
    approved: 0,
    pending: 0,
    reject: 0,
  });

  // Fetch the client status data from the API
  const initial = async () => {
    try {
      const apiRes = await DashBoardApiService().getClientStatusGraph(
        '' as any,
      );
      // Handle the client data for Active/Inactive clients
      const activeClients = apiRes.res.activeClients;
      const inactiveClients = apiRes.res.inactiveClients;

      setClientData({ activeClients, inactiveClients });
    } catch (error) {
      console.error('Error fetchings client status graph:', error);
    }
  };

  // Fetch document status data from the API
  const initialDocuments = async () => {
    try {
      const apiRes = await DashBoardApiService().getDocumentStatusGraph(
        '' as any,
      );
      // Handle the document data for Approved/Pending/Reject
      const approved =
        apiRes.rc.find((doc: any) => doc.documentStatusName === 'Approved')
          ?.documentCounts || 0;
      const pending =
        apiRes.rc.find((doc: any) => doc.documentStatusName === 'Pending')
          ?.documentCounts || 0;
      const reject =
        apiRes.rc.find((doc: any) => doc.documentStatusName === 'Reject')
          ?.documentCounts || 0;

      setDocumentsData({ approved, pending, reject });
    } catch (error) {
      console.error('Error fetching document status graph:', error);
    }
  };

  useEffect(() => {
    initial();
    initialDocuments();
  }, []);

  // Pie chart data for client status
  const clientPieData = [
    { name: 'Active', value: clientData.activeClients },
    { name: 'Inactive', value: clientData.inactiveClients },
  ];

  // Pie chart data for document status
  const documentPieData = [
    { name: 'Approved', value: documentsData.approved },
    { name: 'Pending', value: documentsData.pending },
    { name: 'Rejected', value: documentsData.reject },
  ];

  return (
    <Fragment>
      <div className='cmltycdr'>
        {/* Client Status Card */}
        <div className='card'>
          <div className='head'>
            <h3>Clients</h3>
            <h4>
              Total: {clientData.activeClients + clientData.inactiveClients}
            </h4>
          </div>
          <ResponsiveContainer width='100%' height={300}>
            <PieChart width={400} height={400}>
              <Pie
                data={clientPieData}
                cx='50%'
                cy='50%'
                labelLine={false}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
              >
                {clientPieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                <LabelList
                  dataKey='name'
                  position='outside'
                  offset={15}
                  fill='#000'
                  content={({ x, y, value }: any) => {
                    return (
                      <g>
                        <line
                          x1={x}
                          y1={y}
                          x2={x + 50} // Custom line length
                          y2={y}
                          stroke={
                            COLORS[
                              clientPieData.findIndex((d) => d.name === value)
                            ]
                          } // Same color as the section
                          strokeWidth={2}
                        />
                        <text x={x + 55} y={y} fill='#000' fontSize={12}>
                          {value}
                        </text>
                      </g>
                    );
                  }}
                />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <div className='status-labels'>
            <h5
              style={{
                backgroundColor: COLORS[0],
                padding: '3px 6px',
                color: '#fff',
                borderRadius: '3px',
              }}
            >
              <span> Active </span> : {clientData.activeClients}
            </h5>
            <h5
              style={{
                backgroundColor: COLORS[1],
                padding: '3px 6px',
                color: '#fff',
                borderRadius: '3px',
              }}
            >
              <span>Inactive</span> : {clientData.inactiveClients}
            </h5>
          </div>
        </div>

        {/* Document Status Card */}
        <div className='card'>
          <div className='head'>
            <h3>Documents</h3>
            <div className='form-group'>
              <select className='form-control'>
                <option>Today</option>
              </select>
            </div>
          </div>
          <ResponsiveContainer width='100%' height={300}>
            <PieChart width={400} height={400}>
              <Pie
                data={documentPieData}
                cx='50%'
                cy='50%'
                labelLine={false}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
              >
                {documentPieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                <LabelList
                  dataKey='name'
                  position='outside'
                  offset={15}
                  fill='#000'
                  content={({ x, y, value }: any) => {
                    return (
                      <g>
                        <line
                          x1={x}
                          y1={y}
                          x2={x + 50} // Custom line length
                          y2={y}
                          stroke={
                            COLORS[
                              documentPieData.findIndex((d) => d.name === value)
                            ]
                          } // Same color as the section
                          strokeWidth={2}
                        />
                        {/* <text x={x + 55} y={y} fill="#000" fontSize={12}>
                                                    {value} 
                                                </text> */}
                      </g>
                    );
                  }}
                />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <div className='status-labels'>
            <h5
              style={{
                backgroundColor: COLORS[0],
                padding: '3px 6px',
                color: '#fff',
                borderRadius: '3px',
              }}
            >
              <span>Approved </span> : {documentsData.approved}
            </h5>
            <h5
              style={{
                backgroundColor: COLORS[1],
                padding: '3px 6px',
                color: '#fff',
                borderRadius: '3px',
              }}
            >
              <span>Pending</span> : {documentsData.pending}
            </h5>
            <h5
              style={{
                backgroundColor: COLORS[2],
                padding: '3px 6px',
                color: '#fff',
                borderRadius: '3px',
              }}
            >
              <span>Rejected</span> : {documentsData.reject}
            </h5>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
