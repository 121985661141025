import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
const { clientsDocumentApproveReject } = AssignedClientsApiService();

export const useClientDocumentApproveReject = (companyId: number) => {
  const { message } = App.useApp();

  return useMutation(
    (payload: FormData) => {
      return clientsDocumentApproveReject(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        message.success(data?.rm);
        // if (false) {  // Replace `false` with any condition you need
        //   message.success(companyId);  // Won't be executed if condition is false
        // }
        console.warn(companyId);
      },
    },
  );
};
