import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

import { clientsFormFilterFeild, clientsFormFilterValue } from './type';
const { RangePicker } = DatePicker;

const { useBreakpoint } = Grid;
type ClientsFilterFormProps = {
  formId: string;
  handleFilter: (values: clientsFormFilterValue) => void;
  initialValues: clientsFormFilterValue;
  closeModal?: () => void;
};
export const ClientsFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: ClientsFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<clientsFormFilterValue>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };
  const onFinish = (formValues: clientsFormFilterValue) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <Form<clientsFormFilterValue>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<clientsFormFilterValue>
            label={'Client Name'}
            name={clientsFormFilterFeild.Name}
          >
            <Input placeholder={'Enter Client Name'} />
          </Form.Item>
          <Form.Item<clientsFormFilterValue>
            label={'Business Name'}
            name={clientsFormFilterFeild.BusinessName}
          >
            <Input placeholder={'Enter Business Name'} />
          </Form.Item>
          <Form.Item<clientsFormFilterValue>
            label={'Date Update'}
            name={clientsFormFilterFeild.Date}
          >
            <RangePicker allowEmpty={[true, true]} format={DATE_FORMATS.date} />
          </Form.Item>
          <Form.Item<clientsFormFilterFeild>
            label={'Status'}
            name={clientsFormFilterFeild.StatusId}
          >
            <Select
              options={[
                { value: 'true', label: 'Active' },
                { value: 'false', label: 'InActive' },
              ]}
              placeholder={'Select Status'}
            />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
