import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table } from 'antd';
import { Column } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { useRoleManagementList } from 'features/assigned-clients/use-cases/Role-management';
import { RoleFormFilterValues } from 'features/role-management/components/role-filter-field/type';
import { useDeleteRole } from 'features/role-management/use-cases/delete-role';
import { usePaginationParams } from 'hooks';
import { FilePenLine, Trash2 } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';

import { RoleClientsUserFilterForm } from './form-filter/Role-clients-filter';
import { EditClientsRoleViewModal } from './modal/add-edit-role';
import { EditRoleAssignViewModal } from './modal/edit-role-item-assign';

export const AssignEditRole = ({ assignvalue }: any) => {
  const getInitialFilterValues: RoleFormFilterValues = {
    id: undefined,
    RoleName: undefined,
    isActive: undefined,
  };
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [editData, setEditData] = useState('');
  const { params, updateParams, resetPage } = usePaginationParams<any>({
    PageIndex: 1,
    RecordPerPage: 10,
    UserType: 1,
    ...getInitialFilterValues,
  });
  const { mutate } = useDeleteRole();
  const handleFiltersChange = (values: RoleFormFilterValues) => {
    updateParams(values);
    resetPage();
  };

  const {
    data: dataList,
    isFetching,
    refetch,
  } = useRoleManagementList({
    ...params,
    UserType: 2,
  });
  const onChangeDelete = (data: any) => {
    mutate(data.Id, {
      onSuccess: () => {
        refetch(); // Refetch data after success
      },
    });
  };
  const formId = 'role-filters-form';
  useEffect(() => {
    if (assignvalue == 7) {
      refetch();
    }
  }, [assignvalue]);

  return (
    <Fragment>
      <RoleClientsUserFilterForm
        formId={formId}
        handleFilter={handleFiltersChange}
        initialValues={getInitialFilterValues}
      />

      <StyledTableWrapper className='card grid_secton'>
        <StyledTableHeader className='gs_head'>
          <h3>
            Role List
            {/* <span>{dataList?.totalCount || '0'}</span> */}
          </h3>
          <div className='buttongroup'>
            <Button
              icon={<PlusCircleOutlined />}
              style={{ backgroundColor: '#223666', color: 'white' }}
              onClick={() => setShowModal(true)}
              disabled={isFetching} // Prevent adding new users while loading
            >
              Add New Role
            </Button>
            {showModal && (
              <EditClientsRoleViewModal
                onClose={() => setShowModal(false)}
                refetch={refetch}
              />
            )}
          </div>
        </StyledTableHeader>
        <div className='cstmtblbx w-full'>
          <div className='cstm_table'>
            <Table
              loading={isFetching}
              dataSource={dataList?.data?.rc as any}
              rowKey='id'
            >
              <Column
                width={'125px'}
                title={'Sr No'}
                render={(_, __, index) => {
                  return index + 1;
                }}
              />
              <Column title={'Role Name'} dataIndex={'RoleName'} />
              <Column
                width={'125px'}
                title={'Status'}
                dataIndex={'status'}
                render={(_, record: any) => (
                  <span
                    style={{
                      color: record.IsActive ? '#155724' : 'red', // Green for "Active", Red for "Inactive"
                    }}
                  >
                    {record?.IsActive == true ? 'Active' : 'InActive'}
                  </span>
                )}
              />
              <Column
                width='100px'
                title={'Action'}
                dataIndex={'Id'}
                render={(_, record: any) => (
                  <Space className='grdbtngroup'>
                    <Button
                      className='btn'
                      type='link'
                      onClick={() => {
                        setEditData(record);
                        setShowEditModal(true);
                      }}
                    >
                      <FilePenLine height={16} />
                    </Button>

                    <Popconfirm
                      title='Delete the Role'
                      description='Are you sure to delete this Role?'
                      okText='Yes'
                      cancelText='No'
                      onConfirm={() => onChangeDelete(record)}
                    >
                      <Button className='btn' type='link'>
                        <Trash2 stroke='#E04343' height={16} />
                      </Button>
                    </Popconfirm>
                  </Space>
                )}
              />
            </Table>
            {showEditModal && (
              <EditRoleAssignViewModal
                editRoleData={editData}
                onClose={() => setShowEditModal(false)}
                refetch={refetch}
              />
            )}
          </div>
        </div>
      </StyledTableWrapper>
    </Fragment>
  );
};
