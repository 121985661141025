export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSpecified = 'NOT_SPECIFIED',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Li3ibAdmin = 'Li3ibAdmin',
  Li3ibAccountant = 'Li3ibAccountant',
  ClientAdmin = 'ClientAdmin',
  Accountant = 'Accountant',
  Coordinator = 'Coordinator',
  CallCenterOperative = 'CallCenterOperative',
  User = 'User',
}

export type User = {
  created_at: string;
  email: string | null;
  Name: string;
  gender: Gender | null;
  id: string;
  is_active: boolean;
  role: any;
  last_name: string;
  phone_number: string;
  /** `null` indicates Li3ib user */
  company_id: string | null;
  company_name: string | null;
};

export type Users = {
  CreatedOn: string;
  Email: string | null;
  Name: string;
  id: string;
  IsActive: boolean;
  ProfileImage: string;
};
