import { Form, Grid } from 'antd';
import { Modal } from 'components';
import { CreateSubDTO } from 'features/subscription-management/api/subscription-api.service';
import {
  SubFormField,
  SubFormValues,
} from 'features/subscription-management/components/subscription-filter-field/type';
import { useCreateSubscription } from 'features/subscription-management/use-cases/create-subscription';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubInfoFormItems } from './subscription-info-item';
const { useBreakpoint } = Grid;
export const EditSubViewModal = ({
  onClose,
  editSubData,
}: {
  onClose: () => void;
  editSubData: any;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<SubFormValues>();
  const { t } = useTranslation();
  const { isLoading, mutate } = useCreateSubscription();

  const getInitialValues = {
    [SubFormField.SubscriptionName]: editSubData?.subscriptionName,
  };
  const handleUpdate = (FormData: SubFormValues) => {
    const payload: CreateSubDTO = {
      id: editSubData.id,
      name: FormData.name,
    };
    mutate(payload, { onSuccess: onClose });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={t('rolemanagement.modal.title')}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('rolemanagement.modal.editbutton')}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<SubFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <SubInfoFormItems />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
