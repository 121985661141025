import { PaginatedRequestParams } from 'api';

export const AssignClientsQueryKeys = {
  all: ['wallet'] as const,
  lists: () => [...AssignClientsQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...AssignClientsQueryKeys.lists(), filters] as const,
  details: () => [...AssignClientsQueryKeys.all, 'detail'] as const,
  detail: (id: string, params?: PaginatedRequestParams) =>
    [...AssignClientsQueryKeys.details(), id, params] as const,
};

export const AssignDetailClientsQueryKeys = {
  all: ['wallet'] as const,
  lists: () => [...AssignDetailClientsQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...AssignDetailClientsQueryKeys.lists(), filters] as const,
  details: () => [...AssignDetailClientsQueryKeys.all, 'detail'] as const,
  detail: (id: string, params?: PaginatedRequestParams) =>
    [...AssignDetailClientsQueryKeys.details(), id, params] as const,
};

export const AssignDetailRejectedQueryKeys = {
  all: ['wallet'] as const,
  lists: () => [...AssignDetailClientsQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...AssignDetailClientsQueryKeys.lists(), filters] as const,
  details: () => [...AssignDetailClientsQueryKeys.all, 'detail'] as const,
  detail: (id: string, params?: PaginatedRequestParams) =>
    [...AssignDetailClientsQueryKeys.details(), id, params] as const,
};
