/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const RoleQueryKeys = {
  all: ['user'] as const,
  lists: () => [...RoleQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...RoleQueryKeys.lists(), { filters }] as const,
  details: () => [...RoleQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...RoleQueryKeys.details(), id] as const,
};
