/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const subscriptionQueryKeys = {
  all: ['user'] as const,
  lists: () => [...subscriptionQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...subscriptionQueryKeys.lists(), { filters }] as const,
  details: () => [...subscriptionQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...subscriptionQueryKeys.details(), id] as const,
};
