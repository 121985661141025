import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateSubDTO,
  SubscriptionApiService,
} from 'features/subscription-management/api/subscription-api.service';
import { queryClient } from 'providers';

import { subscriptionQueryKeys } from './query-keys';

const { createSubscription } = SubscriptionApiService();

export const useCreateSubscription = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: CreateSubDTO) => {
      return createSubscription(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        queryClient.invalidateQueries({
          queryKey: subscriptionQueryKeys.all,
        });
        if (data?.rs == 1) {
          message.success('Subscription Update Sucssfully');
        } else {
          message.error(data?.rm);
        }
      },
    },
  );
};
