import { useGetDocumentCategoryList } from 'features/assigned-clients/use-cases/get-category-document';
import { Fragment, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { CategoryDocumentView } from './category-document';

export const DocumentView = ({
  setAddCommentsModal,
  setCommentsInfo,
  setDocumentUploadModal,
  setMoveToModal,
  setDocumentsInfo,
  movetoModal,
  documentInfo,
  searchName,
  year,
  month,
  getDocumentsCount,
  finishedValue,
  initilaMarkFinished,
}: any) => {
  const { id: CompanyId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const { data: assignedClientDocument, refetch: documentsRefetch } =
    useGetDocumentCategoryList({
      CompanyId,
      // PeriodId: selectedValue || 1,
      ClientId: ids,
      Year: year,
      Month: month,
      Name: searchName,
    });
  useEffect(() => {
    documentsRefetch(); // Refetch documents when selectedValue changes
  }, [searchName]);
  if (!assignedClientDocument) return <div>Loading...</div>;

  return (
    <Fragment>
      <CategoryDocumentView
        Category_document={assignedClientDocument.rc}
        CompanyId={CompanyId}
        setAddCommentsModal={setAddCommentsModal}
        setCommentsInfo={setCommentsInfo}
        setDocumentUploadModal={setDocumentUploadModal}
        setMoveToModal={setMoveToModal}
        setDocumentsInfo={setDocumentsInfo}
        documentsRefetch={documentsRefetch}
        documentInfo={documentInfo}
        movetoModal={movetoModal}
        searchName={searchName}
        year={year}
        month={month}
        getDocumentsCount={getDocumentsCount}
        finishedValue={finishedValue}
        initilaMarkFinished={initilaMarkFinished}
      />
    </Fragment>
  );
};
