import { Col, Empty, Form, Grid, Input, Space, Spin, Switch } from 'antd';
import { Modal } from 'components';
import { RoleFormField } from 'features/role-management/components/role-filter-field/type';
import { useCreateRole } from 'features/role-management/use-cases/create-role';
import { useGetRolePermissionList } from 'features/role-management/use-cases/get-role-permission';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { t } from 'i18next';
import { useState } from 'react';

const { useBreakpoint } = Grid;

export const EditClientsRoleViewModal = ({
  onClose,
  refetch,
}: {
  onClose: () => void;
  refetch: any;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFormValues>();
  const { data, isFetching } = useGetRolePermissionList({ UserType: 2 } as any);
  // Maintain switch states for each permission item
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>(
    () => {
      const initialState: { [key: string]: boolean } = {};
      data?.rc?.forEach((item) => {
        initialState[item.Id] = item.IsChecked ?? false; // Provide a default value of false if undefined
      });

      return initialState;
    },
  );
  const { isLoading, mutate } = useCreateRole();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };
  const handleSwitchChange = (checked: boolean, id: string) => {
    setSwitchStates((prev: any) => ({
      ...prev,
      [id]: checked,
    }));
  };
  const handleUpdate = (values: any) => {
    const payload: any = {
      RoleName: values.RoleName, // From form input
      UserType: 2,
      PermissionIds: Object.keys(switchStates)
        .filter((id) => switchStates[id]) // Filter only checked permissions
        .map((id) => ({
          id: Number(id), // Convert id to number (if needed)
        })),
    };
    mutate(payload, {
      onSuccess: () => {
        onClose(); // Call the function when the mutation succeeds
        refetch(); // Refetch data after success
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Create Role'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Create Role'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item<RoleFormField>
            name={RoleFormField.RoleName}
            label={t('rolemanagement.formfilter.label')}
            rules={[
              {
                required: true,
                whitespace: false,
              },
            ]}
          >
            <Input placeholder={t('rolemanagement.formfilter.placeholder')} />
          </Form.Item>

          <Form.Item name={RoleFormField.PermissionIds}>
            {isFetching ? (
              <Spin className='ldrloder' /> // Display loading spinner while fetching
            ) : Array.isArray(data?.rc) && data?.rc?.length ? (
              data?.rc?.map((item: any, index: number) => (
                <Space
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 15,
                  }}
                >
                  {/* Display the permission name */}
                  <span>{item?.Permission}</span>

                  {/* Switch for toggling permission */}
                  <Switch
                    checked={switchStates[item.Id]} // Control the switch state
                    onChange={(checked) => handleSwitchChange(checked, item.Id)} // Update the state on change
                  />
                </Space>
              ))
            ) : (
              <Col span={24}>
                <Empty description='No Role Documents Found' />
              </Col> // Show empty message if no data
            )}
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
