import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { RoleApiService } from 'features/role-management/api/role-api.service';
import { queryClient } from 'providers';

import { RoleQueryKeys } from './query-keys';
const { deleteRole } = RoleApiService();

export const useDeleteRole = () => {
  const { message } = App.useApp();

  return useMutation(
    (id: string) => {
      return deleteRole(id); // Pass the subscription id to the delete function
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);
        message.error(uiMessage); // Display error message
      },
      onSuccess: (data: any) => {
        queryClient.invalidateQueries({
          queryKey: RoleQueryKeys.all, // Invalidate subscription queries to refresh the data
        });
        if (data?.rs == 1) {
          message.success('Role deleted successfully'); // Display success message
        } else {
          message.error(data?.rm); // Display success message
        }
      },
    },
  );
};
