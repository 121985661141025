export const headerHeight = '6rem';

export const smallMobileContentPadding = () => {
  const top = '24px' as const;
  const right = '16px' as const;
  const bottom = '24px' as const;
  const left = '16px' as const;
  const shorthand = `${top} ${right} ${bottom} ${left}` as const;

  return {
    top,
    right,
    bottom,
    left,
    shorthand,
  };
};

export const contentPadding = () => {
  const top = '24px' as const;
  const right = '32px' as const;
  const bottom = '32px' as const;
  const left = '32px' as const;
  const shorthand = `${top} ${right} ${bottom} ${left}` as const;

  return {
    top,
    right,
    bottom,
    left,
    shorthand,
  };
};
