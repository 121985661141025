import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Collapse,
  CollapseProps,
  Form,
  Grid,
  Image,
  Input,
  message,
  Select,
  Switch,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { Modal } from 'components';
import { useCreateUserClients } from 'features/assigned-clients/use-cases/crate-user-client';
import { useGetClientsServiceList } from 'features/assigned-clients/use-cases/get-clientsService';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useGetRoleList } from 'features/users/use-cases/get-role';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validateImageBeforeUpload } from 'routes/utils';
import { CSSProperties } from 'styled-components';
const { useBreakpoint } = Grid;

export const AddEditUserModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const { id } = useParams();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { data } = useGetRoleList({
    UserType: 2,
  } as any);
  const parmas = {
    CompanyId: id,
    // ClientId: ids,
  };
  const getUsersListQueryResponse = useGetClientsServiceList(parmas, {
    retry: false, // Disable retry if the query fails
  });
  const { isLoading, mutate } = useCreateUserClients();
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [categoryChecks, setCategoryChecks] = useState<any>({});
  const [subCategoryChecks, setSubCategoryChecks] = useState<any>({});
  const [classCategoryChecks, setClassCategoryChecks] = useState<any>({});
  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  useEffect(() => {
    if (getUsersListQueryResponse?.data?.data) {
      const initialCategoryChecks: Record<string, boolean> = {};
      const initialSubCategoryChecks: Record<string, boolean> = {};
      const initialClassCategoryChecks: Record<string, boolean> = {};

      getUsersListQueryResponse?.data?.data.rc.forEach((category: any) => {
        initialCategoryChecks[category.Id] = category.IsChecked;

        category.SubCategoryList.forEach((subCategory: any) => {
          initialSubCategoryChecks[subCategory.Id] = subCategory.IsChecked;

          subCategory.ClassCategoryList.forEach((classCategory: any) => {
            initialClassCategoryChecks[classCategory.Id] =
              classCategory.IsChecked;
          });
        });
      });

      setCategoryChecks(initialCategoryChecks);
      setSubCategoryChecks(initialSubCategoryChecks);
      setClassCategoryChecks(initialClassCategoryChecks);
    }
  }, [getUsersListQueryResponse?.data?.data]);
  const handleCategorySwitchChange = (
    checked: boolean,
    categoryId: string,
    subCategories: any[],
  ) => {
    setCategoryChecks((prev: any) => ({ ...prev, [categoryId]: checked }));

    const updatedSubCategoryChecks = { ...subCategoryChecks };
    const updatedClassCategoryChecks = { ...classCategoryChecks };

    subCategories.forEach((subCategory) => {
      updatedSubCategoryChecks[subCategory.Id] = checked;
      subCategory.ClassCategoryList.forEach((classCategory: any) => {
        updatedClassCategoryChecks[classCategory.Id] = checked;
      });
    });

    setSubCategoryChecks(updatedSubCategoryChecks);
    setClassCategoryChecks(updatedClassCategoryChecks);
  };

  const handleSubCategorySwitchChange = (
    checked: boolean,
    subCategoryId: string,
    classCategories: any[],
  ) => {
    setSubCategoryChecks((prev: any) => ({
      ...prev,
      [subCategoryId]: checked,
    }));

    const updatedClassCategoryChecks = { ...classCategoryChecks };
    classCategories.forEach((classCategory: any) => {
      updatedClassCategoryChecks[classCategory.Id] = checked;
    });

    setClassCategoryChecks(updatedClassCategoryChecks);
  };

  const getItems: (panelStyle1: CSSProperties) => CollapseProps['items'] = (
    panelStyle1,
  ) =>
    getUsersListQueryResponse?.data?.data?.rc.map((category: any) => ({
      key: String(category.Id),
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>{category.CategoryName}</span>
          <Switch
            checked={categoryChecks[category.Id] || false}
            onChange={(checked) =>
              handleCategorySwitchChange(
                checked,
                category.Id,
                category.SubCategoryList,
              )
            }
          />
        </div>
      ),
      children: (
        <div>
          {category.SubCategoryList.map((subCategory: any) => (
            <div
              key={subCategory.Id}
              style={{ marginLeft: '25px', marginBottom: '10px' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span className='fw-500'>{subCategory.SubCategory}</span>
                <Switch
                  checked={subCategoryChecks[subCategory.Id] || false}
                  onChange={(checked) =>
                    handleSubCategorySwitchChange(
                      checked,
                      subCategory.Id,
                      subCategory.ClassCategoryList,
                    )
                  }
                />
              </div>

              {/* Class categories */}
              {subCategory.ClassCategoryList.length > 0 && (
                <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                  {subCategory.ClassCategoryList.map((classCategory: any) => (
                    <div
                      key={classCategory.Id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <span>{classCategory.ClassCategory}</span>
                      <Switch
                        checked={classCategoryChecks[classCategory.Id] || false}
                        onChange={(checked) =>
                          setClassCategoryChecks((prev: any) => ({
                            ...prev,
                            [classCategory.Id]: checked,
                          }))
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ),
      style: panelStyle1,
    }));
  const handlePayloadSet = () => {
    const payload: { ServiceId: string; categoryName: string }[] = []; // Initialize payload array
    getUsersListQueryResponse?.data?.data?.rc.forEach((category: any) => {
      // Check if the main category switch is checked
      if (categoryChecks[category.Id]) {
        // Include the main category if its switch is checked
        payload.push({ ServiceId: category.Id } as any);

        category.SubCategoryList?.forEach((subCategory: any) => {
          // Include subcategory only if its switch is checked
          if (subCategoryChecks[subCategory.Id]) {
            payload.push({ ServiceId: subCategory.Id } as any);
          }

          subCategory.ClassCategoryList?.forEach((classCategory: any) => {
            // Include class category only if its switch is checked
            if (classCategoryChecks[classCategory.Id]) {
              payload.push({ ServiceId: classCategory.Id } as any);
            }
          });
        });
      } else {
        // If the main category switch is unchecked, skip it along with its subcategories and class categories
        category.SubCategoryList?.forEach((subCategory: any) => {
          if (subCategoryChecks[subCategory.Id]) {
            payload.push({ ServiceId: subCategory.Id } as any);
          }

          subCategory.ClassCategoryList?.forEach((classCategory: any) => {
            if (classCategoryChecks[classCategory.Id]) {
              payload.push({ ServiceId: classCategory.Id } as any);
            }
          });
        });
      }
    });

    // Set the payload for further processing
    return payload;
  };
  const handleUpdate = (formValues: any) => {
    try {
      const file = fileList[0]?.originFileObj;
      if (file == undefined) {
        return message.error('Please Upload Profile');
      }
      const formData = new FormData();
      formData.append('UserName', formValues.Name);
      formData.append('RoleId', formValues.RoleId);
      formData.append('CompanyId', id as any);
      formData.append('Email', formValues.Email);
      formData.append('file', file); // Include the entire file object
      formData.append('FileName', file.name);
      const payload = handlePayloadSet();
      formData.append('ServiceListTemp', JSON.stringify(payload));
      mutate(formData, { onSuccess: onClose });
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <StyledUserModalWrapper className='rightmodal'>
      <Modal
        open
        centered
        title={'Create User'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Create User'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item
            name='Name'
            label='User name'
            rules={[{ required: true, message: 'User name is required.' }]}
          >
            <Input placeholder='Enter User name' />
          </Form.Item>
          <Form.Item
            name='Email'
            label='Email'
            rules={[{ required: true, message: 'Email is required.' }]}
          >
            <Input placeholder='Enter Email' />
          </Form.Item>
          <Form.Item
            label={'Role'}
            name={'RoleId'}
            rules={[{ required: true }]}
          >
            <Select
              options={data?.rc?.map((item: any) => ({
                label: item.RoleName,
                value: item.Id,
              }))}
              placeholder='Select Role'
            />
          </Form.Item>
          <Form.Item label={'Upload Profile'} name={'Upload Profile'}>
            <div style={{ width: '100%' }}>
              <Upload
                action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
                listType='picture-card'
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={validateImageBeforeUpload}
                maxCount={1}
                style={{ width: '100%' }}
              >
                {fileList?.length >= 1 ? null : uploadButton}
              </Upload>
            </div>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
          <Collapse
            bordered={false}
            defaultActiveKey={[1]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            // style={{ background: token.colorBgContainer }}
            items={getItems({})}
          />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
