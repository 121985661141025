import { PaginatedRequestParams } from 'api';

export enum RoleFormField {
  RoleName = 'RoleName',
  RoleId = 'RoleId',
  PermissionIds = 'PermissionIds',
}

export type RoleFormValues = {
  [RoleFormField.RoleName]: string | undefined;
  [RoleFormField.RoleId]: string | undefined;
  [RoleFormField.PermissionIds]: string | undefined;
};

export enum RoleFormFilterField {
  Id = 'id',
  RoleName = 'RoleName',
  Status = 'isActive',
  UserType = 'UserType',
}
export type RoleFormFilterValues = {
  [RoleFormFilterField.Id]: string | undefined;
  [RoleFormFilterField.RoleName]: string | undefined;
  [RoleFormFilterField.Status]: string | undefined;
};

export type RoleFilterFormValuesPaginated = RoleFormFilterValues &
  PaginatedRequestParams;
