import { Button, Col, Form, Input, message, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import logoimg from 'assets/images/logo.png';
import { VerifyOtpField } from 'features/auth/components/login-form/types';
import { useCreateResendOtp } from 'features/auth/use-cases/resend-otp';
import { useOtpVerfiy } from 'features/auth/use-cases/verfiyOtp';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import { StyledBackground, StyledCard, StyledRow } from './login.styles';
const OtpView = () => {
  const [otp, setOtp] = useState(Array(6).fill('')); // Initialize OTP with 6 empty fields
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { mutate } = useOtpVerfiy();
  const { mutate: ResendOtp, isLoading } = useCreateResendOtp();
  const handleChange = (value: string, index: number) => {
    if (value.length > 1) return; // Ensure only one character is entered
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input box if a digit is entered
    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    // Handle backspace and move to the previous input box
    if (e.key === 'Backspace') {
      if (otp[index] === '' && index > 0) {
        const prevInput = document.getElementById(`otp-${index - 1}`);
        prevInput?.focus();
      }
    }
  };
  const UserId: any = localStorage.getItem('userId');
  const handleFinish = () => {
    const otpCode = otp.join(''); // Join the OTP array into a single string
    setIsSubmitting(true);
    mutate(
      { UserId, VerificationCode: otpCode, persistSession: true },
      {
        onSuccess: (data: any) => {
          if (data?.rs == 2) {
            message.error(data?.rm);
            navigate(`${ROUTES.OTP}`);
            setIsSubmitting(false);
          } else {
            setIsSubmitting(false); // Set loading false after success
            navigate(`${ROUTES.HOME}`, { replace: true });
            // message.success(data.rm);
          }
        },
        onError: (error) => {
          setIsSubmitting(false); // Set loading false after error
          message.error('Failed to verify OTP. Please try again.');
          console.error('OTP verification error:', error);
        },
      },
    );
  };
  const handleResendOtp = () => {
    const userEmail: any = localStorage.getItem('userEmail');
    const resndOtpValue = {
      Email: userEmail,
      UserType: 1,
    };
    ResendOtp(resndOtpValue);
  };
  const handleSubmit = () => {
    form.setFieldsValue({
      VerificationCode: otp.join(''), // Combine the OTP digits into one VerificationCode
    });
    form.submit(); // Submit the form after setting the value
  };

  return (
    <StyledBackground className='lgnbx otpbx'>
      <Col xs={24} sm={18} md={12} lg={8}>
        <Col
          className='ant-row ant-row-center ant-typography-h1 lgllogo'
          style={{ maxWidth: '500px' }}
        >
          <img src={logoimg} alt='' />
        </Col>

        <StyledCard>
          <StyledRow justify='center' className='mb-10'>
            <Col className='lhead'>
              <Title level={1}>OTP </Title>
              <Title level={5}>{t('login.description')}</Title>
            </Col>
          </StyledRow>

          <StyledRow justify='center'>
            <Col xs={24}>
              <Form
                className='formbx'
                layout='vertical'
                form={form}
                onFinish={handleFinish}
              >
                <Form.Item name={VerifyOtpField.VerificationCode} noStyle>
                  <Row gutter={16} className='otp-group-form'>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <Col key={index}>
                        <Input
                          className='form-control text-center'
                          id={`otp-${index}`} // Unique ID for each input
                          value={otp[index]}
                          onChange={(e) => handleChange(e.target.value, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace navigation
                          maxLength={1} // Single character per input
                          size='large'
                          autoComplete='off'
                          placeholder='-'
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Button
                    className='ant-btn ant-btn-primary ant-btn-block btn default_gradient padding-tb-7'
                    type='primary'
                    block
                    loading={isSubmitting}
                    disabled={otp.some((digit) => !digit)} // Disable button if any field is empty
                    onClick={handleSubmit} // Trigger setting the value and submitting
                    style={{ marginTop: '20px', textAlign: 'center' }}
                  >
                    Submit OTP
                  </Button>
                </Form.Item>
              </Form>
              <div className='mt-5 flex justify-center'>
                <a href={ROUTES.LOGIN}>Back to Login</a>
              </div>
              <div className='mt-5 flex justify-center'>
                <Button
                  type='primary'
                  onClick={handleResendOtp}
                  loading={isLoading}
                >
                  Resend OTP
                </Button>
              </div>
            </Col>
          </StyledRow>
        </StyledCard>
      </Col>
    </StyledBackground>
  );
};

export default OtpView;
