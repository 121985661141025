import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Card,
  Dropdown,
  Flex,
  Menu,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Fragment } from 'react';
import { getFileExtension } from 'routes/utils';
const { Title } = Typography;

export const ThirdSubCategory = ({
  thirdCategoryData,
  setCategoryList,
  setShowSubCategory,
  subCategoryName,
  setShowSecondCategory,
  setCommentsInfo,
  setAddCommentsModal,
  secondCategoryDocument,
  setShowSubThirdCategory,
  setShowThirdCategory,
  setShowSubSecondCategory,
}: any) => {
  const showCategory = () => {
    setCategoryList(true);
    setShowSubCategory(false);
    setShowSecondCategory(false);
    setShowSubSecondCategory(false);
    setShowThirdCategory(false);
    setShowSubThirdCategory(false);
  };
  const showSubCatgoryDocument = () => {
    setCategoryList(false);
    setShowSubCategory(true);
    setShowSecondCategory(false); //
    setShowSubSecondCategory(true);
    setShowThirdCategory(false);
    setShowSubThirdCategory(true);
  };
  const showSubThirdCatgoryDocuments = () => {
    setShowThirdCategory(false);
    setShowSubThirdCategory(true);
  };
  const handleMenuClick = ({ key }: any, item: any) => {
    if (key === 'download') {
      message.success('Downloading ' + item.documentName);
      const link = document.createElement('a');
      link.href = item.documentPath; // File path or URL
      link.target = '_blank'; // Open in a new tab
      link.download = item.documentName; // Suggested file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (key === 'preview') {
      // Open the preview in a new tab
      Modal.info({
        title: 'Document View',
        width: '100%',
        icon: null,
        closable: true,
        closeIcon: <CloseOutlined />,
        style: { top: '0px', maxWidth: '500px' },
        content: (
          <div style={{ textAlign: 'start' }}>
            {(() => {
              const extension = getFileExtension(item.documentPath);

              if (['jpg', 'jpeg', 'png', 'gif'].includes(extension!)) {
                // If it's an image, display it directly
                return <iframe src={item.documentPath} width='100%' />;
              } else if (
                ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(
                  extension!,
                )
              ) {
                // Use Google Docs Viewer for documents
                return (
                  <iframe
                    src={`https://docs.google.com/gview?url=${item.documentPath}&embedded=true`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              } else {
                return (
                  <iframe
                    src={`${item.documentPath}`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              }
            })()}
          </div>
        ),
        okButtonProps: { style: { display: 'none' } },
      });
    } else if (key === 'Add Comment') {
      setAddCommentsModal(true);
      setCommentsInfo(item);
    }
  };
  const renderMenu = (item: any) => (
    <Menu
      onClick={({ key }) => handleMenuClick({ key }, item)}
      items={[
        { label: 'Download', key: 'download' },
        { label: 'Preview', key: 'preview' },
        { label: 'Add Comment', key: 'Add Comment' },
      ]}
    />
  );

  return (
    <Fragment>
      <Typography className='ctgrybx'>
        <ul>
          <li>
            <a href='javascript:void();' onClick={() => showCategory()}>
              Categories
            </a>
          </li>
          <li>
            <a
              href='javascript:void()'
              onClick={() => showSubCatgoryDocument()}
            >
              {subCategoryName}
            </a>{' '}
          </li>
          <li>
            <a
              href='javascript:void()'
              onClick={() => showSubThirdCatgoryDocuments()}
            >
              {secondCategoryDocument.subCategory}
            </a>
          </li>
          <li>{thirdCategoryData?.classCategory}</li>
        </ul>
      </Typography>
      <Row className='fldrbx'>
        {Array.isArray(thirdCategoryData.documentList) &&
        thirdCategoryData.documentList.length
          ? thirdCategoryData.documentList?.map((item: any, index: number) => (
              <Card key={index}>
                <Flex>
                  <Typography className='meidafolder meida'>
                    <FileViewer documentPath={item.documentPath} />
                  </Typography>

                  <Typography className='cvnu'>
                    <Title className='crdttl' level={3}>
                      {item.documentName}

                      <dt className='stsbx'>
                        <dt
                          className={
                            item.documentStatus === 'Approved'
                              ? 'approved'
                              : item.documentStatus == 'Pending'
                              ? 'pending'
                              : 'rejected'
                          }
                        >
                          {item?.documentStatus == 'Reject'
                            ? 'Document Rejected'
                            : item?.documentStatus}
                        </dt>
                      </dt>

                      <dt className='mnubdrp'>
                        <Dropdown
                          overlay={renderMenu(item)}
                          trigger={['click']}
                        >
                          <MoreOutlined
                            style={{
                              fontSize: '24px',
                              cursor: 'pointer',
                            }}
                          />
                        </Dropdown>
                      </dt>
                    </Title>
                    <span>
                      {item?.totalComments
                        ? `${item?.totalComments} Comments`
                        : '0 Comments'}
                    </span>
                    {item.documentSize != null ? (
                      <dt className='tmrbx'>Size : {item.documentSize} </dt>
                    ) : (
                      ''
                    )}

                    <dt className='tmrbx'>
                      Last Update:{' '}
                      {dayjs(item.LastUpdated).format('DD MMM YYYY | hh:mm A')}
                    </dt>
                  </Typography>
                </Flex>
              </Card>
            ))
          : ''}
      </Row>
    </Fragment>
  );
};
