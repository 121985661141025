import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { StyledTableWrapper } from 'components/table/table.styled';
import {
  RoleFormFilterField,
  RoleFormFilterValues,
} from 'features/role-management/components/role-filter-field/type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;
type RoleFilterFormProps = {
  formId: string;
  handleFilter: (values: RoleFormFilterValues) => void;
  initialValues: RoleFormFilterValues;
  closeModal?: () => void;
};

export const RoleClientsUserFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: RoleFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<RoleFormFilterValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };
  const onFinish = (formValues: RoleFormFilterValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <StyledTableWrapper className='card filter_card'>
      <Form<RoleFormFilterValues>
        id={formId}
        name={formId}
        className='filters-form'
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        validateTrigger={validateTrigger}
      >
        <ContentFilters.FormItemsWrapper>
          <StandardItemsWrapper>
            <Form.Item<RoleFormFilterValues>
              label={'Role Name'}
              name={RoleFormFilterField.RoleName}
            >
              <Input placeholder={'Enter Role Name'} />
            </Form.Item>
            <Form.Item<RoleFormFilterValues>
              label={'Status'}
              name={RoleFormFilterField.Status}
            >
              <Select
                options={[
                  { value: 'true', label: 'Active' },
                  { value: 'false', label: 'InActive' },
                ]}
                placeholder={'Select status'}
              />
            </Form.Item>
          </StandardItemsWrapper>
        </ContentFilters.FormItemsWrapper>

        {screens.lg && (
          <ContentFilters.ButtonsWrapper>
            <Button type='primary' htmlType='submit'>
              {t('common.buttons.filter', { count: 1 })}
            </Button>
            <Button onClick={onReset}>{'Clear'}</Button>
          </ContentFilters.ButtonsWrapper>
        )}
      </Form>
    </StyledTableWrapper>
  );
};
