import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Image,
  Input,
  message,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { useGetDropDownFlagList } from 'features/assigned-clients/use-cases/drop-down-flag';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
export const DocumentsInfoFormItems = ({
  data,
  // isFetching,
  fileList,
  previewImage,
  previewOpen,
  setFileList,
  setPreviewOpen,
  setPreviewImage,
}: any) => {
  const [subCategoryValue, setSubCategoryValue] = useState('');
  const [subClassValue, setSubClassValue] = useState<any>('');
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const { data: subCategory } = useGetDropDownFlagList({
    Flag: 'SubCategory',
    ParentId: subCategoryValue,
    ClientId: ids,
    CompanyId: id,
  });
  const initialValues = async () => {
    try {
      const apires: any = await AssignedClientsApiService().dropDownByClassFlag(
        {
          Flag: 'Class',
          ParentId: subClassValue,
          ClientId: ids,
          CompanyId: id,
        },
      );
      setSubClassValue(apires);
    } catch (error: any) {
      message.error(error);
    }
  };
  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  useEffect(() => {
    if (subClassValue) {
      initialValues();
    }
  }, [subClassValue]);

  return (
    <Fragment>
      <Form.Item
        name={'DocumentName'}
        label={'Document Name'}
        rules={[
          {
            required: true,
            whitespace: false,
          },
        ]}
      >
        <Input placeholder={'Enter Documents Name'} />
      </Form.Item>
      <Form.Item
        label='Category'
        name={'category'}
        rules={[
          {
            required: true,
            message: 'Category is required',
          },
        ]}
      >
        <Select
          options={data?.rc?.map((item: any) => ({
            label: item.categoryName,
            value: item.id,
          }))}
          onChange={(value) => setSubCategoryValue(value)}
          placeholder='Please Select'
        />
      </Form.Item>
      {subCategory?.data?.rc.length > 0 && (
        <Form.Item name={'SubCategory'} label={'Sub Category'}>
          <Select
            options={subCategory?.data.rc?.map((item: any) => ({
              label: item.text,
              value: item.value,
            }))}
            onChange={(value) => setSubClassValue(value)}
            placeholder='Please Select'
          />
        </Form.Item>
      )}
      {subClassValue?.data?.rc.length > 0 && (
        <Form.Item name={'class'} label={'Class'}>
          <Select
            options={subClassValue?.data?.rc.map((item: any) => ({
              label: item.text,
              value: item.value,
            }))}
            placeholder='Please Select'
          />
        </Form.Item>
      )}
      <Form.Item label={'Upload Documents'} name={'file'}>
        <div style={{ width: '100%' }}>
          <Upload
            action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
            listType='picture-card'
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            multiple={true}
            maxCount={5} // Optional: Set a limit for the number of files
            style={{ width: '100%' }}
            accept='.jpg,.jpeg,.png,.webp,.csv,.xlsx,.xls,.pdf' // Restrict file types
            beforeUpload={(file) => {
              const isValidType = [
                'image/jpeg',
                'image/png',
                'image/webp',
                'image/jpg',
                'text/csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                'application/vnd.ms-excel', // .xls
                'application/pdf',
              ].includes(file.type);

              if (!isValidType) {
                message.error(
                  'You can only upload JPG, PNG, WebP, CSV, Excel, and PDF files!',
                );

                return Upload.LIST_IGNORE; // Prevents adding invalid file
              }

              return true;
            }}
          >
            {fileList?.length >= 50 ? null : (
              <div style={{ width: '100%' }}>{uploadButton}</div>
            )}
          </Upload>
        </div>
        {previewImage && (
          <Image
            wrapperStyle={{ display: 'none' }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(''),
            }}
            src={previewImage}
          />
        )}
      </Form.Item>
    </Fragment>
  );
};
