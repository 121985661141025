import { Layout, Result404 } from 'components';
import { ResultError } from 'components/errors';
import { MainFiltersProvider } from 'components/main-filters/main-filters.context';
import { AccountSettingsView } from 'features/account-settings';
import { AssignClientsView } from 'features/assigned-clients/routes/assign-clients';
import { AssignDetailsView } from 'features/assigned-clients/routes/assigned-clients-view';
import { EditAssignView } from 'features/assigned-clients/routes/edit-clients';
import { LoginView } from 'features/auth';
import { ChangePassword } from 'features/auth/routes/change-password';
import OtpView from 'features/auth/routes/otp-view';
import { ResetView } from 'features/auth/routes/reset-password';
import DashBoardView from 'features/Dashboard/routes/dashboard-view';
import { CategoryItemView } from 'features/master-management/routes/category-list';
import { MasterClassItemView } from 'features/master-management/routes/class-master';
import { SubCategoryItemView } from 'features/master-management/routes/sub-category-list';
import { AddClientView } from 'features/new-clients/routes/add-clients';
import { ClientsListView } from 'features/new-clients/routes/new-clients';
import { ClientsDetailsView } from 'features/new-clients/routes/new-clients-details';
import { RoleView } from 'features/role-management/routes/role-list';
import { SubscriptionView } from 'features/subscription-management/routes/subscription-view';
import { AdminUsersView, AdminUserUpdateView } from 'features/users';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { AuthenticatedRoute } from './authenticated-route';
import { ROUTES } from './constants';
import { UnauthenticatedRoute } from './unauthenticated-route';
export const routerConfig: RouteObject[] = [
  {
    errorElement: <ResultError />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <UnauthenticatedRoute>
            <LoginView />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: ROUTES.OTP,
        element: (
          // <UnauthenticatedRoute>
          <OtpView />
          // </UnauthenticatedRoute>
        ),
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: (
          // <UnauthenticatedRoute>
          // <ResetView />
          <ChangePassword />
          // </UnauthenticatedRoute>
        ),
      },
      {
        path: ROUTES.Forgot_PASSWORD,
        element: (
          // <UnauthenticatedRoute>
          <ResetView />
          // </UnauthenticatedRoute>
        ),
      },
      {
        path: ROUTES.CHANGE_PASSWORD,
        element: (
          // <UnauthenticatedRoute>
          <ChangePassword />
          // </UnauthenticatedRoute>
        ),
      },
      {
        path: ROUTES.HOME,
        element: (
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        ),
        children: [
          {
            path: ROUTES.HOME,
            element: <Navigate to={ROUTES.DASHBOARD} replace />,
          },
          {
            path: ROUTES.ACCOUNT_SETTINGS,
            element: <AccountSettingsView />,
          },
          {
            path: ROUTES.DASHBOARD,
            element: (
              <MainFiltersProvider>
                <DashBoardView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.ASSIGNED,
            element: (
              <MainFiltersProvider>
                <AssignClientsView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.ASSIGNED_DETAILS,
            element: (
              <MainFiltersProvider>
                <AssignDetailsView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.ASSIGNED_EDIT,
            element: (
              <MainFiltersProvider>
                <EditAssignView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.USERS_ADMIN,
            element: <AdminUsersView />,
            children: [
              {
                path: ROUTES.USERS_ADMIN_EDIT,
                element: <AdminUserUpdateView />,
              },
            ],
          },
          {
            path: ROUTES.CLIENTS,
            element: (
              <MainFiltersProvider>
                <ClientsListView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.CLIENTS_ADD,
            element: (
              <MainFiltersProvider>
                <AddClientView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.CLIENTS_DETAILS,
            element: (
              <MainFiltersProvider>
                <ClientsDetailsView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.ROLE_MANAGEMENT,
            element: (
              <MainFiltersProvider>
                <RoleView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.SUBSCRIPTION_MANAGEMENT,
            element: (
              <MainFiltersProvider>
                <SubscriptionView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.MASTER,
            children: [
              {
                path: ROUTES.MASTER_CATEGORY,
                element: (
                  <MainFiltersProvider>
                    <CategoryItemView />
                  </MainFiltersProvider>
                ),
              },
              {
                path: ROUTES.MASTER_SUBCATEGORY,
                element: (
                  <MainFiltersProvider>
                    <SubCategoryItemView />
                  </MainFiltersProvider>
                ),
              },
              {
                path: ROUTES.MASTER_CLASS,
                element: (
                  <MainFiltersProvider>
                    <MasterClassItemView />
                  </MainFiltersProvider>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <Result404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfig);
