import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { UserApiService, UserListParams, UserResponse } from 'features/users';
import qs from 'qs';

import { userQueryKeys } from './query-keys';
const { getRolePermission } = UserApiService();

const getUsersQuery = (
  params: UserListParams,
  options?: UseQueryOptions<UserResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<UserResponse>;
  options?: UseQueryOptions<UserResponse>;
} => ({
  queryKey: userQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getRolePermission(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetPermissionList = (
  params: UserListParams,
  options?: UseQueryOptions<UserResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<UserResponse, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
