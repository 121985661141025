import { Button, Col, Form, Input, message } from 'antd';
// import Title from 'antd/es/skeleton/Title';
import Title from 'antd/es/typography/Title';
import logoimg from 'assets/images/logo.png';
import { ResetPasswordDTO } from 'features/auth/api/auth-api.service';
import { useResetPassword } from 'features/auth/use-cases';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import { StyledBackground, StyledCard, StyledRow } from './login.styles';

export const ChangePassword = () => {
  const [form] = Form.useForm(); // Ant Design form instance for field access
  const navigate = useNavigate();
  const { mutate, isLoading } = useResetPassword();

  const { id } = useParams();

  const onFinish = ({ Password, UniqueId }: ResetPasswordDTO) => {
    mutate(
      { Password, UniqueId, persistSession: true },
      {
        onSuccess: (data: any) => {
          if (data?.rs == 1) {
            // localStorage.clear();
            navigate(ROUTES.LOGIN);
            message.success(data?.rm);
          } else {
            message.error(data?.rm);
          }
        },
      },
    );
  };

  return (
    <StyledBackground className='w-screen'>
      <figure className='w-1/2'>
        <Col className='ant-row ant-row-center ant-typography-h1 mb-10'>
          <img src={logoimg} alt='' />
        </Col>

        <Col className='ant-row ant-row-center'>
          <StyledCard>
            <StyledRow className='w-full'>
              <StyledRow justify='center' className='w-full mb-10'>
                <Col className='lhead text-center'>
                  <Title level={2} className='mb-0'>
                    {t('login.resetPasswordtitle')}
                  </Title>
                  <p>{t('login.resetPassworddescription')}</p>
                </Col>
              </StyledRow>

              <Form
                className='w-full'
                form={form} // Using form instance
                layout='vertical'
                onFinish={onFinish}
                initialValues={{ UniqueId: id }}
              >
                {/* New Password */}
                <Form.Item
                  label='New Password'
                  name='Password'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a new password',
                    },
                    {
                      pattern:
                        /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
                      message:
                        'Password must be at least 8 characters long and include one uppercase letter, one number, and one special character.',
                    },
                  ]}
                >
                  <Input.Password placeholder='Enter new password' />
                </Form.Item>
                <Form.Item
                  label='Confirm Password'
                  name='confirmPassword'
                  dependencies={['Password']} // Ensures it watches the 'password' field
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('Password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Passwords do not match!'),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder='Confirm your password' />
                </Form.Item>
                <Form.Item name='UniqueId' style={{ display: 'none' }}>
                  <Input type='hidden' />
                </Form.Item>

                <Form.Item>
                  <Button
                    type='primary'
                    className='btn default_gradient d-block w-full padding-tb-7'
                    htmlType='submit'
                    loading={isLoading}
                  >
                    Set Password
                  </Button>
                </Form.Item>
              </Form>
            </StyledRow>
          </StyledCard>
        </Col>
      </figure>
    </StyledBackground>
  );
};
