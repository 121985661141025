import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { StyledTableWrapper } from 'components/table/table.styled';
import {
  SubCategoryFormFilterField,
  SubCategoryFormFilterValues,
} from 'features/master-management/components/filter-field/type';
import { useGetClientsCetgoryList } from 'features/new-clients/use-cases/get-clients-category';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;
type SubFilterFormProps = {
  formId: string;
  handleFilter: (values: SubCategoryFormFilterValues) => void;
  initialValues: SubCategoryFormFilterValues;
  closeModal?: () => void;
};

export const SubCategoryClientsFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: SubFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<SubCategoryFormFilterValues>();
  const { data } = useGetClientsCetgoryList('');
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };
  const onFinish = (formValues: SubCategoryFormFilterValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <StyledTableWrapper className='card filter_card'>
      <Form<SubCategoryFormFilterValues>
        id={formId}
        name={formId}
        className='filters-form'
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        validateTrigger={validateTrigger}
      >
        <ContentFilters.FormItemsWrapper>
          <StandardItemsWrapper>
            <Form.Item<SubCategoryFormFilterField>
              label={'Category Name'}
              name={SubCategoryFormFilterField.CategoryId}
            >
              <Select
                options={data?.rc.map((item: any) => ({
                  label: item.categoryName, // Correctly returning an object with label and value
                  value: item.id,
                }))}
                placeholder='please select'
              />
            </Form.Item>
            <Form.Item<SubCategoryFormFilterField>
              label={'Sub Category Name'}
              name={SubCategoryFormFilterField.SubCategoryName}
            >
              <Input placeholder={'Enter Sub Category'} />
            </Form.Item>
          </StandardItemsWrapper>
        </ContentFilters.FormItemsWrapper>

        {screens.lg && (
          <ContentFilters.ButtonsWrapper>
            <Button type='primary' htmlType='submit'>
              {t('common.buttons.filter', { count: 1 })}
            </Button>
            <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
          </ContentFilters.ButtonsWrapper>
        )}
      </Form>
    </StyledTableWrapper>
  );
};
