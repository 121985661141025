type RoutesType = typeof ROUTES;
type RouteKeys = keyof RoutesType;
export type RoutePaths = RoutesType[RouteKeys];

export const ROUTES = {
  HOME: '/',
  OTP: '/otp',
  RESET_PASSWORD: '/reset-password/:id',
  Forgot_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  DASHBOARD: '/dashboard',

  ASSIGNED: '/assign-client',
  ASSIGNED_DETAILS: '/assign-client/:id/detail',
  ASSIGNED_EDIT: '/assign-client/:id/edit',

  CLIENTS: '/new-clients',
  CLIENTS_ADD: '/new-clients/create',
  CLIENTS_DETAILS: '/new-clients/:id/detail',

  BOOKINGS: '/bookings',
  BOOKINGS_CREATE: `/bookings/create`,
  BOOKINGS_EDIT: `/bookings/:id/edit`,
  FIXED_BOOKINGS: '/fixed-bookings',
  FIXED_BOOKING_DETAILS: '/fixed-bookings/:id/bookings',
  FIXED_BOOKING_DETAILS_COMMENTS: '/fixed-bookings/:id/comments',
  FIXED_BOOKING_DETAILS_INVOICE: '/fixed-bookings/:id/invoice',
  FIXED_BOOKINGS_CREATE: '/fixed-bookings/create',
  FIXED_BOOKINGS_CREATE_INVOICE: '/fixed-bookings/create-invoice',
  FIXED_BOOKINGS_EDIT: '/fixed-bookings/:id/bookings/:booking_id/edit',
  DAY_VIEW: '/day-view',
  USERS: '/users',
  USERS_APP: '/users/app',
  USERS_APP_EDIT: `/users/app/:id/edit`,
  USERS_ADMIN: '/users/admin',
  USERS_ADMIN_EDIT: `/users/admin/:id/edit`,
  WALLET: '/wallet',
  WITHDRAW: '/withdraw',
  WITHDRAW_detail_data: '/withdraw/:id/information',
  WITHDRAW_detail: '/withdraw/:id/detail',
  WITHDRAW_REJECT: '/withdraw/:id/details',
  REPORT_POST: '/community/report',
  REPORT_POST_DETAILS: `/community/report/:id/detail`,
  COMMUNITY_TC: '/community/term',
  WALLET_TRANSACTIONS: '/wallet/:id/transactions',
  WALLET_TRANSACTION_DETAILS: '/wallet/:id/transactions/:transaction_id',
  LOGIN: '/login',
  ACCOUNT_SETTINGS: '/account-settings',
  COMPANY_TC: '/company-info/term',
  COMPANY_TC_EDIT: '/company-info/term/:id/edit',
  REPORTS: '/reports',
  ROLE_MANAGEMENT: '/role',
  ROLE_MANAGEMENT_EDIT: '/role/:id/edit',
  SUBSCRIPTION_MANAGEMENT: '/subscription-management',

  MASTER: '/master',
  MASTER_CATEGORY: '/master/category',
  MASTER_SUBCATEGORY: '/master/subcategory',
  MASTER_CLASS: '/master/class',
  NOT_FOUND: '*',
} as const;
