import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Switch } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { CategoryFilterFormValuesPaginated } from 'features/master-management/components/filter-field/type';
import { AddSubCategoryModal } from 'features/master-management/components/modal/sub-category/add-subcategory';
import { useDeleteSubCategory } from 'features/master-management/use-cases/delete-subcategory';
import { useStatusSubCategory } from 'features/master-management/use-cases/sub-category-status-change';
import { usePermissions } from 'features/users';
import { FilePenLine, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { categorymaster } from 'types/master';
import { Role } from 'types/role';
// ../../modal/sub-category/add-subcategory
type CategoryTableProps = {
  CategoryList: categorymaster[];
  isFetching: boolean;
  tableParams: CategoryFilterFormValuesPaginated;
  setEditModal: any;
  setEditData: any;
};

export const SubTableItemView = ({
  CategoryList,
  isFetching,
  tableParams,
  setEditModal,
  setEditData,
}: CategoryTableProps) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { t } = useTranslation();
  const permissions = usePermissions();
  const { mutate } = useDeleteSubCategory();
  const { mutate: statusSubCategory, isLoading } = useStatusSubCategory();
  if (!permissions) return null;
  const handleOpenModal = (record: any) => {
    setEditModal(true);
    setEditData(record);
  };
  const onChangeDelete = (data: any) => {
    mutate(data.id); // Call mutate with data.id
  };

  return (
    <StyledTableWrapper className='card grid_secton'>
      {/* <StyledTableHeader></StyledTableHeader> */}

      <StyledTableHeader className='gs_head'>
        <h3>
          Sub Categories List{' '}
          {/* <span>{dataList?.length > 0 ? dataList[0].totalRecords : '0'}</span> */}
        </h3>

        <div className='buttongroup'>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => setShowAddModal(true)}
            style={{ backgroundColor: '#223666', color: 'white' }}
          >
            Add Sub Category Name
          </Button>
          {showAddModal && (
            <AddSubCategoryModal onClose={() => setShowAddModal(false)} />
          )}
        </div>
      </StyledTableHeader>

      <div className='cstmtblbx'>
        <div className='cstm_table'>
          <Table<categorymaster>
            loading={isFetching}
            dataSource={CategoryList}
            rowKey='id'
            // onChange={handleTableChange}
            pagination={{
              current: tableParams.PageIndex,
              defaultPageSize: tableParams.RecordPerPage,
              total: 10,
            }}
            // scroll={{ x: 1440 }}
            components={{
              header: {
                cell: (
                  props: React.HTMLAttributes<HTMLTableHeaderCellElement>,
                ) => <th {...props} />,
              },
            }}
          >
            <Column<categorymaster>
              width='125px'
              title={'Sr.No'}
              render={(_, __, index) => index + 1}
            />
            <Column<categorymaster>
              title={'Category Name'}
              dataIndex={'categoryName'}
            />
            <Column<categorymaster>
              title={'Sub Category Name'}
              dataIndex={'subCategoryName'}
            />
            <Column<Role>
              width='125px'
              title={t('usermanagement.table.status')}
              dataIndex={'role'}
              render={(_, record: any) => (
                <Space>
                  <Typography
                    style={{
                      color: record.isActive === true ? '#155724' : 'red', // Green for "Active", Red for "Inactive"
                    }}
                  >
                    {record.isActive === true ? 'Active' : 'Inactive'}
                  </Typography>
                </Space>
              )}
            />
            <Column<categorymaster>
              width='180px'
              title={t('usermanagement.table.action')}
              dataIndex={'Id'}
              render={(_, record: any) => (
                <Space className='grdbtngroup'>
                  <Button
                    className='btn'
                    type='link'
                    onClick={() => handleOpenModal(record)}
                  >
                    <FilePenLine height={16} />
                  </Button>
                  <Popconfirm
                    title='Delete the SubCategory'
                    description='Are you sure to delete this SubCategory?'
                    onConfirm={() => onChangeDelete(record)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button className='btn' type='link'>
                      <Trash2 stroke='#E04343' height={16} />
                    </Button>
                  </Popconfirm>

                  <Switch
                    onClick={() => {
                      statusSubCategory(record.id); // Pass the record.Id to the mutate function
                    }}
                    disabled={isLoading}
                    loading={isLoading}
                    checked={record?.isActive == true}
                  />
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </StyledTableWrapper>
  );
};
