import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { RoleApiService } from 'features/role-management/api/role-api.service';
import { Role } from 'types/role';

import { RoleQueryKeys } from './query-keys';
const { getRoleId } = RoleApiService();

const getUserQuery = (
  id: string,
  options?: UseQueryOptions<Role>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Role>;
  options?: UseQueryOptions<Role>;
} => ({
  queryKey: RoleQueryKeys.detail(id),
  queryFn: ({ signal }) => getRoleId(id, { config: { signal } }),
  ...options,
});

export const useGetRoleById = (id: string, options?: UseQueryOptions<any>) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getUserQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
