import { Layout as AntLayout, Space } from 'antd';
import { Header, SideBar } from 'components';
import { ResultError } from 'components/errors';
import { useSideBar } from 'providers/side-bar-provider';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useParams } from 'react-router-dom';

import { StyledLayout } from './layout.styles';

export const Layout = () => {
  const { collapsed } = useSideBar();
  const { Content } = AntLayout;
  const { id } = useParams();
  
return (
    <StyledLayout $collapsed={collapsed}>
      <Header />

      <AntLayout>
        <SideBar />
        <Content
          className={`layout-content main-panel ${id && 'mblvwmainpage'}`}
        >
          <ErrorBoundary FallbackComponent={ResultError}>
            <Space className='layout-space'>
              <Outlet />
            </Space>
          </ErrorBoundary>
        </Content>
      </AntLayout>
    </StyledLayout>
  );
};
