import { ContentFilters, ContentTopBar } from 'components';
import { AssignilterForm } from 'features/assigned-clients/components/form-filter/assign-filter-form';
import { assignFormFilterValue } from 'features/assigned-clients/components/form-filter/type';
import { AssignModal } from 'features/assigned-clients/components/modals/assign-modal';
import { CancelSubscriptionModal } from 'features/assigned-clients/components/modals/subscription-cancel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// ../modals/subscription-cancel
type assignprops = {
  handleFilters: (values: assignFormFilterValue) => void;
  getInitialFilterValues: assignFormFilterValue;
  setShowModal: any;
  showModal: any;
  assignedData: any;
  subscriptionModal: any;
  setSubscriptionModal: any;
};

export const AssignTopBar = ({
  handleFilters,
  getInitialFilterValues,
  setShowModal,
  showModal,
  assignedData,
  setSubscriptionModal,
  subscriptionModal,
}: assignprops) => {
  useState<any>();
  const { t } = useTranslation();
  const formId = 'assign-clients-filters-form';

  return (
    <>
      <ContentTopBar title={t('assignedClient.heading.assignedList')} />

      <ContentFilters formId={formId}>
        <AssignilterForm
          formId={formId}
          handleFilter={handleFilters}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
      {showModal && (
        <AssignModal
          onClose={() => setShowModal(false)}
          assignedData={assignedData}
        />
      )}
      {subscriptionModal && (
        <CancelSubscriptionModal
          onClose={() => setSubscriptionModal(false)}
          assignedData={assignedData}
        />
      )}
    </>
  );
};
