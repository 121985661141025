export enum AuthStorageKey {
  AccessToken = 'partnerplus__access_token',
  RefreshToken = 'partnerplus__refresh_token',
}

// Storage events
export enum AuthStorageEventType {
  AccessTokenUpdated = 'accessTokenUpdated',
  AccessTokenExpired = 'accessTokenExpired',
  UserDataFetchFailed = 'userDataFetchFailed',
}

export class AccessTokenUpdatedEvent extends Event {
  constructor() {
    super(AuthStorageEventType.AccessTokenUpdated);
  }
}

export class AccessTokenExpiredEvent extends Event {
  constructor() {
    super(AuthStorageEventType.AccessTokenExpired);
  }
}

export class UserDataFetchFailedEvent extends Event {
  constructor() {
    super(AuthStorageEventType.UserDataFetchFailed);
  }
}

interface CustomEventMap {
  accessTokenUpdated: AccessTokenUpdatedEvent;
  accessTokenExpired: AccessTokenExpiredEvent;
  userDataFetchFailed: UserDataFetchFailedEvent;
}

declare global {
  interface Window {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
  }
}

const eventListener = (e: StorageEvent) => {
  if (e.key === AuthStorageKey.AccessToken) {
    window.dispatchEvent(new AccessTokenUpdatedEvent());
  }

  // storage was cleared
  if (e.key == null) {
    window.dispatchEvent(new AccessTokenUpdatedEvent());
  }
};

export function addStorageEventListener() {
  window.addEventListener('storage', eventListener);
}

export function removeStorageEventListener() {
  window.removeEventListener('storage', eventListener);
}

// Storage operations

export function setAccessToken(value: string, persist = true) {
  const storage = persist ? localStorage : sessionStorage;

  storage.setItem(AuthStorageKey.AccessToken, value);
  window.dispatchEvent(new AccessTokenUpdatedEvent());
}

export function setRefreshToken(value: string, persist = true) {
  const storage = persist ? localStorage : sessionStorage;

  storage.setItem(AuthStorageKey.RefreshToken, value);
}

export function getAccessToken() {
  return (
    localStorage.getItem(AuthStorageKey.AccessToken) ||
    sessionStorage.getItem(AuthStorageKey.AccessToken)
  );
}

export function getRefreshToken() {
  return (
    localStorage.getItem(AuthStorageKey.RefreshToken) ||
    sessionStorage.getItem(AuthStorageKey.RefreshToken)
  );
}

export function clearStorage() {
  localStorage.removeItem(AuthStorageKey.AccessToken);
  localStorage.removeItem(AuthStorageKey.RefreshToken);
  sessionStorage.removeItem(AuthStorageKey.AccessToken);
  sessionStorage.removeItem(AuthStorageKey.RefreshToken);

  window.dispatchEvent(new AccessTokenUpdatedEvent());
}
