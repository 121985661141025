import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Col, Form, Image, Input, Row, Select, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Title from 'antd/es/typography/Title';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { t } from 'i18next';
import { Fragment } from 'react';
import { validateImageBeforeUpload } from 'routes/utils';

import { AssignFormField } from './type';

type AddNewProps = {
  fileList: any[]; // Adjust the type of fileList if you have more specific information
  setFileList: (files: any[]) => void;
  previewImage: string | null; // Assuming previewImage is a URL or null
  setPreviewImage: any;
  previewOpen: boolean;
  setPreviewOpen: (open: boolean) => void;
};
type cityName = {
  text: string;
  value: number;
};
export const AddNewInfo = ({
  fileList,
  setFileList,
  previewImage,
  setPreviewImage,
  previewOpen,
  setPreviewOpen,
}: AddNewProps) => {
  const { data: city } = useDropDownFlag('city');
  const { data: Industry } = useDropDownFlag('Industry');
  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Fragment>
      <Row gutter={24}>
        <Col xs={24} lg={4}>
          <Form.Item<AssignFormField>
            // label={t('usermanagement.formFields.profile.label')}
            name={AssignFormField.file}
          >
            {/* action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload' */}
            <Upload
              className='uplddocbx'
              listType='picture-card'
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={validateImageBeforeUpload}
              maxCount={1} // Ensure the Upload takes 100% width
            >
              {fileList?.length >= 1 ? null : (
                <div className='upldbtn'>{uploadButton}</div>
              )}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} lg={20}>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <Title level={4}>Basic Information</Title>
            </Col>

            <Col xs={24} lg={6}>
              <Form.Item<AssignFormField>
                label={t('newclient.formFields.clientname.label')}
                rules={[{ required: true, message: 'Name is required' }]}
                name={AssignFormField.Clients}
              >
                <Input
                  placeholder={t('newclient.formFields.clientname.placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.Industry.label')}
                rules={[{ required: true, message: 'Industry is required' }]}
                name={AssignFormField.IndustryId}
              >
                <Select
                  options={Industry?.data?.rc.map((item: cityName) => ({
                    label: item.text, // Use `label` for the display value
                    value: item.value, // Use `value` for the actual value
                  }))}
                  placeholder='Please Select'
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.businessname.label')}
                rules={[
                  { required: true, message: 'Business Name is required' },
                ]}
                name={AssignFormField.BusinessName}
              >
                <Input
                  placeholder={t(
                    'newclient.formFields.businessname.placeholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.city.label')}
                rules={[{ required: true, message: 'City is required' }]}
                name={AssignFormField.CityId}
              >
                <Select
                  options={city?.data?.rc.map((item: cityName) => ({
                    label: item.text, // Use `label` for the display value
                    value: item.value, // Use `value` for the actual value
                  }))}
                  placeholder='Please Select'
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.phoneNumber.label')}
                rules={[
                  { required: true, message: 'Phone number is required' },
                ]}
                name={AssignFormField.PhoneNumber}
              >
                <Input
                  placeholder={'Enter Phone Number'}
                  onKeyPress={(e) => {
                    const isNumber = /^[0-9]$/.test(e.key);
                    const isBackspace = e.key === 'Backspace';
                    if (!isNumber && !isBackspace) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.email.label')}
                rules={[
                  { required: true, message: 'Email is required' }, // Required validation
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  }, // Email format validation
                ]}
                name={AssignFormField.Email}
              >
                <Input placeholder={'Enter Email'} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.Locationdetails.label')}
                rules={[{ required: true, message: 'Address is required' }]}
                name={AssignFormField.Address}
              >
                <Input
                  placeholder={t(
                    'newclient.formFields.Locationdetails.placeholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.businesswebsite.label')}
                rules={[
                  { required: true, message: 'Business website is required' },
                ]}
                name={AssignFormField.BusinessWebsite}
              >
                <Input placeholder={'Enter Business Website'} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                label={t('newclient.formFields.decription.label')}
                name='Description'
                rules={[{ required: true, message: 'Decription is required' }]}
              >
                <TextArea
                  cols={5}
                  rows={5}
                  placeholder={t('newclient.formFields.decription.placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};
