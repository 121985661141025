import { Button, Col, Form, Grid, Input, Row } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types';

import { ProfileField, ProfileFormValues } from './types';

const { useBreakpoint } = Grid;

type ProfileFormProps = {
  handleSubmit: (formValues: ProfileFormValues) => void;
  user: User;
  isLoading: boolean;
  companyName?: string;
  setIsProfileFormDirty: Dispatch<SetStateAction<boolean>>;
};

export const ProfileForm = ({
  handleSubmit,
  user,
  isLoading,
  companyName,
  setIsProfileFormDirty,
}: ProfileFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const getInitialValues = {
    [ProfileField.FirstName]: user.Name,
    [ProfileField.LastName]: user.last_name,
    [ProfileField.Email]: user.email,
    [ProfileField.PhoneNumber]: user.phone_number,
  };

  useEffect(() => {
    if (user.company_id && companyName) {
      return form.setFieldValue(ProfileField.Company, companyName);
    }

    return form.setFieldValue(ProfileField.Company, 'Li3ib');
  }, [companyName, form, user.company_id]);

  const handleOnFinish = (formValues: ProfileFormValues) => {
    setIsProfileFormDirty(false);
    handleSubmit(formValues);
  };

  return (
    <>
      <Form
        id='profile-form'
        name='profile-form'
        form={form}
        layout={'vertical'}
        initialValues={getInitialValues}
        onFinish={handleOnFinish}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        onFieldsChange={() => setIsProfileFormDirty(true)}
        disabled={isLoading}
        validateTrigger={validateTrigger}
      >
        <Col span={screens.lg ? 10 : 'auto'} offset={screens.lg ? 7 : 0}>
          <Form.Item<ProfileField>
            label={t('accountSettings.formFields.firstName.label')}
            name={ProfileField.FirstName}
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder={t(
                'accountSettings.formFields.firstName.placeholder',
              )}
              size='large'
            />
          </Form.Item>
          <Form.Item<ProfileField>
            label={t('accountSettings.formFields.lastName.label')}
            name={ProfileField.LastName}
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder={t('accountSettings.formFields.lastName.placeholder')}
              size='large'
            />
          </Form.Item>
          <Form.Item<ProfileField>
            label={t('accountSettings.formFields.email.label')}
            name={ProfileField.Email}
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder={t('accountSettings.formFields.email.placeholder')}
              size='large'
            />
          </Form.Item>
          <Form.Item<ProfileField>
            label={t('accountSettings.formFields.phoneNumber.label')}
            name={ProfileField.PhoneNumber}
            // TODO: add rules and patterns after removing 'disabled'
          >
            <Input
              placeholder={t(
                'accountSettings.formFields.phoneNumber.placeholder',
              )}
              disabled // TODO: remove disabled when BE ready to handle phone number and company change, out of P0
              size='large'
            />
          </Form.Item>
          <Form.Item<ProfileField>
            label={t('accountSettings.formFields.company.label')}
            name={ProfileField.Company}
            // TODO: add rules after removing 'disabled'
          >
            {/* use Select when BE ready to handle phone number and company change, out of P0  */}
            <Input
              placeholder={t('accountSettings.formFields.company.placeholder')}
              disabled
              size='large'
            />
          </Form.Item>

          <Row justify='end'>
            <Button type='primary' disabled={isLoading} htmlType='submit'>
              {t('accountSettings.buttons.saveChanges')}
            </Button>
          </Row>
        </Col>
      </Form>
    </>
  );
};
