import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  RoleApiService,
  RoleListParams,
  RoleResponse,
} from 'features/role-management/api/role-api.service';
import { UserListParams } from 'features/users';
import qs from 'qs';

import { RoleQueryKeys } from './query-keys';

const { getRole } = RoleApiService();

const getUsersQuery = (
  params: RoleListParams,
  options?: UseQueryOptions<RoleResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<RoleResponse>;
  options?: UseQueryOptions<RoleResponse>;
} => ({
  queryKey: RoleQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getRole(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetRoleList = (
  params: UserListParams,
  options?: UseQueryOptions<RoleResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<RoleResponse, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
