import { ContentFilters, ContentTopBar } from 'components';
import { EditRoleViewModal } from 'features/role-management/components/modal/edit-role-view';
import { RoleFilterForm } from 'features/role-management/components/role-filter-field/role-filter-form';
import { RoleFormFilterValues } from 'features/role-management/components/role-filter-field/type';
import { useState } from 'react';

type RoleProps = {
  setEditRoleModal: any;
  editRoleModal: boolean;
  editRoleData: any;
  handleFilter: (values: RoleFormFilterValues) => void;
  getInitialFilter: RoleFormFilterValues;
};

export const RoleTopBar = ({
  setEditRoleModal,
  editRoleModal,
  editRoleData,
  handleFilter,
  getInitialFilter,
}: RoleProps) => {
  useState<any>();
  const formId = 'role-filters-form';

  return (
    <>
      <ContentTopBar
        title={'Role Management'}
        renderButtons={
          <>
            {/* <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => setShowAddUserModal(true)}
              style={{ backgroundColor: '#223666', color: 'white' }}
            >
              {t('rolemanagement.button.addnewrole')}
            </Button>
            {showAddUserModal && (
              <AddRoleModal onClose={() => setShowAddUserModal(false)} />
            )} */}
            {editRoleModal && (
              <EditRoleViewModal
                onClose={() => setEditRoleModal(false)}
                editRoleData={editRoleData}
              />
            )}
          </>
        }
      />
      <ContentFilters formId={formId}>
        <RoleFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilter}
        />
      </ContentFilters>
    </>
  );
};
