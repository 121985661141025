import { Button, Card, Col, Flex, Row, Typography } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import dayjs from 'dayjs';
import { PendingDocumentView } from 'features/assigned-clients/components/assign-details/documents/pending-document';
import { MoveItemViewDocuments } from 'features/assigned-clients/components/assign-details/move-document/move-documents';
import { useGetPendingDocumentList } from 'features/assigned-clients/use-cases/get-pending-document';
import { CirclePlus, Folder } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MonthValue } from 'routes/utils';

import { SubCategoryDocumentView } from './sub-category-list';

const { Title } = Typography;

type SubCategory = {
  id: number;
  subCategory: string;
  noOfDocuments: number;
  documentName: string | null;
  documentPath: string | null;
  documentSize: string | null;
  documentStatusId: number;
  documentStatus: string | null;
  lastUpdatedDate: string;
};

type Category = {
  id: number;
  categoryName: string | null;
  noOfDocuments: number;
  documentName: string | null;
  documentPath: string | null;
  documentSize: string | null;
  documentStatusId: number;
  documentStatus: string | null;
  lastUpdatedDate: string | null;
  subCategoryList: SubCategory[] | null;
};

export const CategoryDocumentView = ({
  Category_document,
  CompanyId,
  setCommentsInfo,
  setAddCommentsModal,
  setDocumentUploadModal,
  setMoveToModal,
  setDocumentsInfo,
  documentsRefetch,
  documentInfo,
  movetoModal,
  searchName,
  year,
  month,
  getDocumentsCount,
  finishedValue,
  initilaMarkFinished,
}: // selectFilterValue
{
  Category_document: Category[] | any;
  CompanyId: string | undefined;
  setCommentsInfo: any;
  setAddCommentsModal: any;
  setDocumentUploadModal: any;
  setMoveToModal: any;
  setDocumentsInfo: any;
  documentsRefetch: any;
  documentInfo: any;
  movetoModal: any;
  searchName: any;
  year: any;
  month: any;
  getDocumentsCount: any;
  finishedValue: any;
  initilaMarkFinished: any;
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const [showCategoryList, setCategoryList] = useState(true);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [subCategoryData, setSubCategoryData] = useState({});
  const { data: pendingDocumentData, refetch: reFetchPendingDocument } =
    useGetPendingDocumentList({
      CompanyId,
      DocumentStatusId: 1,
      Name: searchName,
      Year: year,
      Month: month,
      ClientId: ids,
      // PeriodId: selectedValue || 1,
    });
  const getMonthName = (monthNumber: number): string => {
    if (monthNumber >= 1 && monthNumber <= 12) {
      return MonthValue[monthNumber - 1]; // Adjust for 0-based index
    }

    return 'Invalid Month'; // Fallback for invalid numbers
  };
  const showSubCategoryData = (data: any) => {
    setCategoryList(false);
    setShowSubCategory(true);
    setSubCategoryData(data);
  };
  const monthNumber = month;
  const titleText = `${getMonthName(monthNumber)} ${year}`;
  useEffect(() => {
    reFetchPendingDocument();
  }, [year, month, searchName]);
  const activetab = localStorage.getItem('activeTab'); // Fetch the activeTab value
  useEffect(() => {
    if (activetab === '1') {
      // Check if activeTab is '1'
      reFetchPendingDocument(); // Call the function only when activeTab is '1'
      initilaMarkFinished();
    }
  }, [year, month, activetab]); // Dependency on finishedValue
  
return (
    <Fragment>
      <Flex className='crdsubhed' justify='space-between'>
        <Title level={3}>{titleText}</Title>
        {
          <ButtonGroup>
            {(!finishedValue?.rc ||
              finishedValue?.rc.length === 0 ||
              finishedValue?.rc[0]?.status === 1) && (
              <Button
                onClick={() => setDocumentUploadModal(true)}
                className='btn btn-primary flex align-center'
              >
                <CirclePlus height={'18px'} className='mr-2' /> UPLOAD NEW
                DOCUMENTS
              </Button>
            )}
          </ButtonGroup>
        }
      </Flex>
      {showCategoryList && (
        <Typography className='ctgrybx'>
          {Category_document?.length > 0 && <h4>Categories</h4>}
        </Typography>
      )}

      {/* showSubSecondCategory */}
      {showCategoryList && (
        <Row className='fldrbx'>
          {showCategoryList &&
          Array.isArray(Category_document) &&
          Category_document?.length
            ? Category_document?.map((item: any, index: number) => (
                <Card
                  hoverable
                  role='button'
                  key={index}
                  onClick={() => {
                    if (item.noOfDocuments > 0) {
                      showSubCategoryData(item);
                    }
                  }}
                >
                  <Flex>
                    <div className='meidafolder'>
                      <Folder
                        fill='#7D819E'
                        stroke='#7D819E'
                        className='meidafolder'
                      />
                    </div>
                    <Typography className='cvnu'>
                      <Title className='crdttl' level={3}>
                        {item.categoryName}
                      </Title>
                      {item.noOfDocuments > 0 ? (
                        <dt className='tmrbx'>
                          {item.noOfDocuments} Documents
                        </dt>
                      ) : (
                        <dt className='tmrbx'>Empty Folder </dt>
                      )}

                      <dt className='tmrbx'>
                        Last Update{' '}
                        {dayjs(item.lastUpdatedDate).format(
                          'DD MMM YYYY | hh:mm A',
                        )}
                      </dt>
                    </Typography>
                  </Flex>
                </Card>
              ))
            : ''}
        </Row>
      )}

      {showSubCategory ? (
        <SubCategoryDocumentView
          subCategoryDocument={subCategoryData}
          setCategoryList={setCategoryList}
          setShowSubCategory={setShowSubCategory}
          setMoveToModal={setMoveToModal}
          setDocumentsInfo={setDocumentsInfo}
          setCommentsInfo={setCommentsInfo}
          setAddCommentsModal={setAddCommentsModal}
          documentsRefetch={documentsRefetch}
        />
      ) : (
        ''
      )}

      {showCategoryList &&
        pendingDocumentData &&
        pendingDocumentData?.rc &&
        pendingDocumentData?.rc.length > 0 && (
          <Col span={24}>
            <Title level={4}>Pending Document</Title>
            <PendingDocumentView
              pendingDocument={pendingDocumentData}
              companyId={CompanyId}
              reFetchPendingDocument={reFetchPendingDocument}
              documentsRefetch={documentsRefetch}
              getDocumentsCount={getDocumentsCount}
            />
          </Col>
        )}
      {movetoModal && (
        <MoveItemViewDocuments
          onClose={() => setMoveToModal(false)}
          documentInfo={documentInfo}
          setSubCategoryData={setSubCategoryData}
          documentsRefetch={documentsRefetch}
        />
      )}
    </Fragment>
  );
};
