import { Form, Grid, message } from 'antd';
import { Modal } from 'components';
import { CategoryFormValues } from 'features/master-management/components/filter-field/type';
import { useCreateCategory } from 'features/master-management/use-cases/create-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { UserFormField } from 'features/users/components/users-filter-form';
import { useState } from 'react';

import { CategoryInfoFormItems } from './category-info-item';
const { useBreakpoint } = Grid;

export const AddCategoryModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CategoryFormValues>();

  const { isLoading, mutate } = useCreateCategory();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };
  const handleUpdate = (formData: CategoryFormValues) => {
    mutate(formData, {
      onSuccess: (data: any) => {
        onClose();
        if (data?.rs == 1) {
          message.success('Category created successfully');
        } else {
          message.error(data?.rm);
        }
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Create Category'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Create Category'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<CategoryFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <CategoryInfoFormItems />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
