import { Avatar, Button, Card, Col, Form, Grid, Input, List, Row } from 'antd';
import { Modal } from 'components';
import dayjs from 'dayjs';
import { useCreateComments } from 'features/assigned-clients/use-cases/create-comments';
import { useCreateResolved } from 'features/assigned-clients/use-cases/create-resolved';
import { useCommentsList } from 'features/assigned-clients/use-cases/get-comments';
import { useUserContext } from 'features/users';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { Folder } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
const { useBreakpoint } = Grid;

export const CommentsItemView = ({
  onClose,
  commentsInfo,
}: // commentsMarkInfo,
{
  onClose: () => void;
  commentsInfo: any;
  commentsMarkInfo: any;
}) => {
  // const { user } = useUserContext();
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const { user } = useUserContext();
  const { id } = useParams();
  const { mutate: mutateResolved, isLoading: resolved } = useCreateResolved();
  const [newComment, setNewComment] = useState('');
  const { companyId, documentId } = commentsInfo || {};
  // Fetch comments
  const { data: commentsData, isLoading: commentsFetch } = useCommentsList({
    CompanyId: companyId || user?.CompanyId,
    DocumentId: documentId,
  } as any);
  const activeTab = localStorage.getItem('activeTab');
  const { isLoading, mutate } = useCreateComments();

  const handleAddComment = () => {
    const IsAdmin: any = false;
    if (!newComment.trim()) return;
    const formData = new FormData();
    // formData.append('CompanyId', user?.CompanyId);
    formData.append('DocumentId', documentId);
    formData.append('Comments', newComment);
    formData.append('IsAdmin', IsAdmin);

    mutate(formData, {
      onSuccess: () => {
        setNewComment('');
      },
    });
  };
  const handleResloved = () => {
    const formData = new FormData();
    formData.append('CompanyId', id as any);
    formData.append('DocumentId', documentId);
    mutateResolved(formData, {
      onSuccess: () => {
        onClose();
        // refetchAttention()
      },
    });
  };

  return (
    <StyledUserModalWrapper className='rightmodal'>
      <Modal
        open
        title={'Comments'}
        onCancel={onClose}
        getContainer={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          style: { display: 'none' }, // Hides the Save button
        }}
        className='comments-modal'
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          disabled={isLoading}
        >
          <Card className='mb-4 bg-gray-50 cursor-pointer hover:bg-gray-100'>
            <div className='flex gap-2'>
              <Folder fill='#aaa' stroke='none' size={62} />
              <div className='flex flex-col grow'>
                <h3>
                  {commentsInfo?.documentName}
                  {commentsInfo?.categoryName && (
                    <span className='bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded leading-5 mr-2 ml-5'>
                      {commentsInfo?.categoryName}
                    </span>
                  )}
                  {commentsInfo?.subCategoryName && (
                    <span className='bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded leading-5'>
                      {commentsInfo?.subCategoryName}
                    </span>
                  )}
                </h3>
                <p>
                  {dayjs(commentsInfo?.lastUpdatedDate).format('DD-MMM-YYYY')}
                </p>
              </div>
            </div>
          </Card>

          <Row style={{ marginBottom: '20px', width: '100%' }}>
            <List
              style={{ width: '100%' }}
              size='small'
              loading={commentsFetch}
              header={<div>Comments</div>}
              dataSource={commentsData?.rc?.[0]?.commentsList || []}
              renderItem={(item: any) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={item.profileImage} />}
                    title={
                      <div className='flex justify-between'>
                        <span>{item.commentBy}</span>
                        <span className='text-xs text-gray-500'>
                          {dayjs(item.commentOn).format('DD-MM-YYYY HH:mm')}
                        </span>
                      </div>
                    }
                    description={item.comments}
                  />
                </List.Item>
              )}
            />
          </Row>

          <Row>
            <Col span={24}>
              <Input.TextArea
                rows={2}
                placeholder='Add a comment'
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col className='w-full'>
              <Button
                type='primary'
                onClick={handleAddComment}
                loading={isLoading}
                style={{ marginTop: '15px' }}
              >
                Add Comment
              </Button>

              {activeTab === '4' && (
                <Button
                  className='ml-5'
                  type='primary'
                  onClick={handleResloved}
                  loading={resolved}
                >
                  Mark As Resolved
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
