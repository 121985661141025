import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, message, Space, Switch, Table, Typography } from 'antd';
import { Column, ContentFilters } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import dayjs from 'dayjs';
import { useGetManageUserList } from 'features/assigned-clients/use-cases/get-manage-user';
import {
  UserFilterFormsValues,
  UserFiltersFormValuesPaginated,
} from 'features/users/components/users-filter-form';
import { useStatusChange } from 'features/users/use-cases/update-status';
import { usePaginationParams } from 'hooks';
import { FilePenLine } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EditManageUser } from './edit-manage-user';
import { UsersClientsFilterForm } from './form-filter/user-clients-filter';
import { AddEditUserModal } from './modal/add-edit-user';

export const ManageUser = ({ assignvalue }: any) => {
  const { id } = useParams();
  const getInitialFilterValues: UserFilterFormsValues = {
    Name: undefined,
    RoleName: undefined,
    Email: undefined,
    IsActive: undefined,
    CreatedDate: undefined,
  };

  const { params, updateParams, resetPage } =
    usePaginationParams<UserFiltersFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });

  const [loadingStatus, setLoadingStatus] = useState<Record<string, boolean>>(
    {},
  );
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const { mutate: changeStatus } = useStatusChange();

  const preparePayload = (data: any) => ({
    ...data,
    CreatedDate: data?.CreatedDate
      ? dayjs(data.CreatedDate).format('DD-MM-YYYY')
      : null,
  });

  const {
    data: dataList,
    isFetching,
    error,
    refetch,
  } = useGetManageUserList({
    ...preparePayload(params),
    CompanyId: id,
  });

  if (error) {
    message.error('Failed to load user list');
  }

  const handleFiltersChange = (values: UserFilterFormsValues) => {
    updateParams(values);
    resetPage();
  };

  const handleStatusChange = (userId: string, currentStatus: boolean) => {
    const updatedStatus = !currentStatus;
    const formData = {
      UserId: userId,
      Status: updatedStatus,
    };

    setLoadingStatus((prev) => ({ ...prev, [userId]: true }));
    changeStatus(formData, {
      onSuccess: () => {
        message.success('Status updated successfully');
        refetch();
        setLoadingStatus((prev) => ({ ...prev, [userId]: false }));
      },
      onError: () => {
        message.error('Error updating status');
        setLoadingStatus((prev) => ({ ...prev, [userId]: false }));
      },
    });
  };
  useEffect(() => {
    if (assignvalue == 6) {
      refetch();
    }
  }, [assignvalue]);

  return (
    <Fragment>
      <ContentFilters formId='users-filters-form'>
        <UsersClientsFilterForm
          formId='users-filters-form'
          handleFilter={handleFiltersChange}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>

      <StyledTableWrapper className='card grid_secton'>
        <StyledTableHeader className='gs_head'>
          <h3>
            User List
            {/* <span>{dataList?.totalCount || '0'}</span> */}
          </h3>
          <div className='buttongroup'>
            <Button
              icon={<PlusCircleOutlined />}
              style={{ backgroundColor: '#223666', color: 'white' }}
              onClick={() => setShowAddModal(true)}
              disabled={isFetching} // Prevent adding new users while loading
            >
              Add New User
            </Button>
            {showAddModal && (
              <AddEditUserModal onClose={() => setShowAddModal(false)} />
            )}
          </div>
        </StyledTableHeader>
        <div className='cstmtblbx w-full'>
          <div className='cstm_table'>
            <Table
              loading={isFetching}
              dataSource={dataList?.rc}
              rowKey='Id'
              pagination={{
                current: params.PageIndex,
                pageSize: params.RecordPerPage,
                total: dataList?.totalCount,
                onChange: (page, pageSize) =>
                  updateParams({ PageIndex: page, RecordPerPage: pageSize }),
              }}
            >
              <Column title='Sr No' render={(_, __, index) => index + 1} />
              <Column title='User Name' dataIndex='Name' />
              <Column title='Email' dataIndex='Email' />
              <Column title='User Role' dataIndex='RoleName' />
              <Column
                title='Date Added'
                dataIndex='CreatedOn'
                render={(createdDate) =>
                  dayjs(createdDate).format('DD MMM YYYY')
                }
              />
              <Column
                title='Status'
                dataIndex='IsActive'
                render={(isActive) => (
                  <Typography style={{ color: isActive ? '#155724' : 'red' }}>
                    {isActive ? 'Active' : 'Inactive'}
                  </Typography>
                )}
              />
              <Column
                width='135px'
                title='Action'
                render={(_, record: any) => (
                  <Space className='grdbtngroup'>
                    <Button
                      className='btn'
                      type='link'
                      onClick={() => {
                        setShowModal(true);
                        setUserData(record);
                      }}
                    >
                      <FilePenLine height={16} />
                    </Button>
                    {/* <Popconfirm
                      title='Delete the user?'
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button className='btn' type='link'>
                        <Trash2 stroke='#E04343' height={16} />
                      </Button>
                    </Popconfirm> */}
                    <Switch
                      disabled={loadingStatus[record.Id]}
                      checked={record.IsActive}
                      onChange={() =>
                        handleStatusChange(record.Id, record.IsActive)
                      }
                    />
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </StyledTableWrapper>

      {showModal && (
        <EditManageUser
          onClose={() => setShowModal(false)}
          userData={userData}
          refetch={refetch}
        />
      )}
    </Fragment>
  );
};
