import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  NewClientsApiService,
  NewClientsResponse,
} from 'features/new-clients/api/new-clients-api.service';

import { NewClientsQueryKeys } from './query-keys';
const { dropDownClientsFlag } = NewClientsApiService();

export const useDropDownClientsFlag = (
  flag: string, // Accept 'city' or other flags
  options?: UseQueryOptions<NewClientsResponse, Error>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    [NewClientsQueryKeys.all, flag], // Query key includes the flag
    () => dropDownClientsFlag(flag), // Pass the flag to dropDownByFlag API function
    {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);
        message.error(uiMessage);
      },
      ...options,
    },
  );
};
