import { Button, Card, Form, Grid, message, Select } from 'antd';
import { Modal } from 'components';
import dayjs from 'dayjs';
// import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { useDropDownFlag } from 'features/assigned-clients/use-cases/drop-down';
import { useMoveDocuments } from 'features/assigned-clients/use-cases/move-documents';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { Folder } from 'lucide-react';
import { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';

const { useBreakpoint } = Grid;

export const MoveItemViewDocuments = ({
  onClose,
  documentInfo,
  // setSubCategoryData,
  documentsRefetch,
}: {
  onClose: () => void;
  documentInfo: any;
  setSubCategoryData: any;
  documentsRefetch: any;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  // const {id}=useParams()
  const { isLoading, mutate } = useMoveDocuments();
  const [subCategoryValue, setSubCategoryValue] = useState('');
  const { data: categories } = useGetCategoryList({ retry: false });
  const { data: subCategory } = useDropDownFlag(
    'SubCategory',
    subCategoryValue,
  );
  // const initial=async()=>{
  //   try {
  //      await AssignedClientsApiService().getAssignedClientDocumentList({
  //       CompanyId:id
  //      }as any)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  const handleSubmit = (formValues: any) => {
    const IsAdmin = true;
    const formData = new FormData();
    formData.append('DocumentId', documentInfo?.documentId);
    formData.append('IsAdmin', IsAdmin.toString());
    if (formValues.subCategory) {
      formData.append('ServiceId', formValues.subCategory); // SubCategoryId
    } else if (formValues.category) {
      formData.append('ServiceId', formValues.category); // CategoryId
    }
    mutate(formData, {
      onSuccess: (data) => {
        if (data?.rs == 1) {
          message.success('Document moved successfully');
          // setSubCategoryData((prevList: any[]) =>
          //   Array.isArray(prevList) ? prevList.filter((doc) => doc.documentId !== documentInfo.documentId) : []
          // );
          // documentsRefetch()
          window.location.reload();
        }
        onClose();
      },
    });
  };

  useEffect(() => {
    documentsRefetch();
  }, [documentsRefetch]);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title='Move Document'
        onCancel={onClose}
        getContainer={false}
        okText='Move'
        footer={[
          <Button
            className='btn cstmfull'
            key='submit'
            form={formName}
            htmlType='submit'
            type='primary'
            loading={isLoading}
          >
            Move
          </Button>,
        ]}
        width={screens.lg ? '520px' : '100%'}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          disabled={isLoading}
          onFinish={handleSubmit}
        >
          <Card className='mb-4 bg-gray-50 cursor-pointer hover:bg-gray-100'>
            <div className='flex gap-2'>
              <Folder fill='#aaa' stroke='none' size={62} />
              <div className='flex flex-col grow'>
                <h3>
                  {documentInfo?.documentName}
                  <span
                    style={{
                      padding: '3px',
                      fontSize: '0.75rem',
                      borderRadius: '3',
                      marginLeft: '5px',
                    }}
                    className={
                      documentInfo.documentStatus === 'Approved'
                        ? 'approved'
                        : documentInfo.documentStatus === 'Pending'
                        ? 'pending'
                        : 'rejected'
                      // default class for 'Reject'
                    }
                  >
                    {documentInfo?.documentStatus}
                  </span>
                </h3>
                <dt></dt>
                <p>
                  {dayjs(documentInfo?.lastUpdatedDate).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>
          </Card>

          <Form.Item
            label='Select Category'
            name='category'
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select
              options={categories?.rc?.map((item: any) => ({
                label: item.categoryName,
                value: item.id,
              }))}
              onChange={(value) => setSubCategoryValue(value)}
              placeholder='Please Select'
            />
          </Form.Item>
          {subCategory?.data?.rc.length > 0 && (
            <Form.Item
              name='subCategory'
              label='Sub Category'
              rules={[{ required: false }]}
            >
              <Select
                options={subCategory?.data.rc?.map((item: any) => ({
                  label: item.text,
                  value: item.value,
                }))}
                placeholder='Please Select'
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
