export enum editAssignFormField {
  Clients = 'clientName',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  IndustryId = 'IndustryId',
  BusinessName = 'BusinessName',
  BusinessWebsite = 'BusinessWebsite',
  Address = 'Address',
  CityId = 'CityId',
  Description = 'Description',
  SubscriptionId = 'subscriptionId',
  SubCategoryAccess = 'SubCategoryAccess',
  SendMailAccess = 'SendMailAccess',
  file = 'file',
  FileName = 'FileName',
}
export type EditAssignFormValues = {
  [editAssignFormField.Clients]: string | undefined;
  [editAssignFormField.Email]: string | undefined;
  [editAssignFormField.PhoneNumber]: string | undefined;
  [editAssignFormField.IndustryId]: string | undefined;
  [editAssignFormField.BusinessName]: string | undefined;
  [editAssignFormField.BusinessWebsite]: string | undefined;
  [editAssignFormField.Address]: string | undefined;
  [editAssignFormField.CityId]: string | undefined;
  [editAssignFormField.SubscriptionId]: string | undefined;
  [editAssignFormField.Description]: string | undefined;
  [editAssignFormField.SubCategoryAccess]: string | undefined;
  [editAssignFormField.SendMailAccess]: string | undefined;
  [editAssignFormField.file]: string | undefined;
  [editAssignFormField.FileName]: string | undefined;
};
