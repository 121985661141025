import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { queryClient } from 'providers';

import { AssignClientsQueryKeys } from './query-keys';
const { deletReportDocument } = AssignedClientsApiService();

export const useDeleteReport = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: FormData) => {
      return deletReportDocument(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: AssignClientsQueryKeys.all,
        });
        if (data?.rs == 1) {
          message.success('Delete Report successfully');
        } else {
          message.warning(data?.rm);
        }
      },
    },
  );
};
