import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Col, Form, Grid, Image, Input, Row, Select, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Title from 'antd/es/typography/Title';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { t } from 'i18next';
import { Fragment } from 'react';

import { editAssignFormField } from './type';

type AddNewProps = {
  fileList: any[]; // Adjust the type of fileList if you have more specific information
  setFileList: (files: any[]) => void;
  previewImage: string | null; // Assuming previewImage is a URL or null
  setPreviewImage: any;
  previewOpen: boolean;
  setPreviewOpen: (open: boolean) => void;
};
type cityName = {
  text: string;
  value: number;
};
export const EditNewClientsInfo = ({
  fileList,
  setFileList,
  previewImage,
  setPreviewImage,
  previewOpen,
  setPreviewOpen,
}: AddNewProps) => {
  const { data: city } = useDropDownFlag('city');
  const { data: Industry } = useDropDownFlag('Industry');

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Fragment>
      <Row gutter={24} className='asdasd'>
        <Col xs={24} lg={4}>
          {screens.lg ? <></> : <Title level={4}>Basic Information</Title>}
          <Form.Item<editAssignFormField>
            // label={t('usermanagement.formFields.profile.label')}
            name={'clientImage'}
          >
            {/* action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload' */}
            <Upload
              className='uplddocbx'
              listType='picture-card'
              name='clientImage'
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={() => false}
              maxCount={1} // Ensure the Upload takes 100% width
            >
              {fileList?.length >= 1 ? null : (
                <div className='upldbtn'>{uploadButton}</div>
              )}
            </Upload>
            {previewImage && (
              <Image
                className='uplddocbx'
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} lg={20}>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              {screens.lg ? <Title level={4}>Basic Information</Title> : <></>}
            </Col>

            <Col xs={24} lg={6}>
              <Form.Item<editAssignFormField>
                label={t('newclient.formFields.clientname.label')}
                rules={[{ required: true, message: 'Name is required' }]}
                name={editAssignFormField.Clients}
              >
                <Input
                  disabled
                  placeholder={t('newclient.formFields.clientname.placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.Industry.label')}
                rules={[{ required: true, message: 'Industry is required' }]}
                name={editAssignFormField.IndustryId}
              >
                <Select
                  options={Industry?.data?.rc.map((item: cityName) => ({
                    label: item.text, // Use `label` for the display value
                    value: item.value, // Use `value` for the actual value
                  }))}
                  placeholder='Please Select'
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.businessname.label')}
                rules={[
                  { required: true, message: 'Businessname is required' },
                ]}
                name={editAssignFormField.BusinessName}
              >
                <Input
                  placeholder={t(
                    'newclient.formFields.businessname.placeholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.city.label')}
                rules={[{ required: true, message: 'City is required' }]}
                name={editAssignFormField.CityId}
              >
                <Select
                  options={city?.data?.rc.map((item: cityName) => ({
                    label: item.text, // Use `label` for the display value
                    value: item.value, // Use `value` for the actual value
                  }))}
                  placeholder='Please Select'
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.phoneNumber.label')}
                rules={[
                  { required: true, message: 'phone number is required' },
                ]}
                name={editAssignFormField.PhoneNumber}
              >
                <Input
                  placeholder={t(
                    'newclient.formFields.phoneNumber.placeholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.email.label')}
                rules={[{ required: true, message: 'email is required' }]}
                name={editAssignFormField.Email}
              >
                <Input
                  disabled
                  placeholder={t('newclient.formFields.email.placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.Locationdetails.label')}
                rules={[{ required: true, message: 'Address is required' }]}
                name={editAssignFormField.Address}
              >
                <Input
                  placeholder={t(
                    'newclient.formFields.Locationdetails.placeholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('newclient.formFields.businesswebsite.label')}
                rules={[
                  { required: true, message: 'Business website is required' },
                ]}
                name={editAssignFormField.BusinessWebsite}
              >
                <Input
                  placeholder={t(
                    'newclient.formFields.businesswebsite.placeholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                label={t('newclient.formFields.decription.label')}
                name='Description'
              >
                <TextArea
                  cols={5}
                  rows={5}
                  placeholder={t('newclient.formFields.decription.placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};
