import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Grid,
  Image,
  Input,
  message,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { Modal } from 'components';
import { useCreateUploadLegalDocumentsAssign } from 'features/assigned-clients/use-cases/create-upload-legal';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

const { useBreakpoint } = Grid;

export const UploadDocumentsLegalItemModal = ({
  onClose,
  refetch,
}: {
  onClose: () => void;
  refetch: () => void;
}) => {
  const formName = 'add-category-form';
  const { id } = useParams();
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { mutate, isLoading } = useCreateUploadLegalDocumentsAssign();

  const handleUpdate = () => {
    if (!fileList.length) {
      form.setFields([
        { name: 'document', errors: ['Please upload a document'] },
      ]);

      return;
    }

    const formData = new FormData();
    const file: any = fileList[0]?.originFileObj;
    formData.append('CompanyId', id as any);
    formData.append('file', file);
    formData.append('DocumentTypeId', 6 as any);
    formData.append('Status', 2 as any);
    mutate(formData, {
      onSuccess: () => {
        refetch();
        onClose();
      },
    });
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={<span className='font-semibold'>Upload Documents</span>}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText='Save'
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
          className: 'btn cstmfull',
        }}
      >
        <Fragment>
          <Form
            className=''
            id={formName}
            name={formName}
            form={form}
            layout='vertical'
            onFinish={handleUpdate}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
            disabled={isLoading}
          >
            <div className='d-block w-full'>
              <Form.Item
                className=''
                name='documentName'
                label='Document Name'
                rules={[
                  {
                    required: true,
                    message: 'Please enter the document name',
                  },
                ]}
              >
                <Input placeholder='Enter document name' />
              </Form.Item>

              <Form.Item label={'Upload Documents'} name={'file'}>
                <div style={{ width: '100%' }}>
                  <Upload
                    action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
                    listType='picture-card'
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    multiple={true}
                    maxCount={5} // Optional: Set a limit for the number of files
                    style={{ width: '100%' }}
                    accept='.jpg,.jpeg,.png,.webp,.csv,.xlsx,.xls,.pdf' // Restrict file types
                    beforeUpload={(file) => {
                      const isValidType = [
                        'image/jpeg',
                        'image/png',
                        'image/webp',
                        'image/jpg',
                        'text/csv',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                        'application/vnd.ms-excel', // .xls
                        'application/pdf',
                      ].includes(file.type);

                      if (!isValidType) {
                        message.error(
                          'You can only upload JPG, PNG, WebP, CSV, Excel, and PDF files!',
                        );

                        return Upload.LIST_IGNORE; // Prevents adding invalid file
                      }

                      return true;
                    }}
                  >
                    {fileList?.length >= 1 ? null : (
                      <div style={{ width: '100%' }}>{uploadButton}</div>
                    )}
                  </Upload>
                </div>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Fragment>
      </Modal>
    </StyledUserModalWrapper>
  );
};
