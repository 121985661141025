import { Form, Grid } from 'antd';
import { Modal } from 'components';
import { useCreateRole } from 'features/role-management/use-cases/create-role';
import { useGetRolePermissionList } from 'features/role-management/use-cases/get-role-permission';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useState } from 'react';

import { RoleInfoFormItems } from './role-info-item';
const { useBreakpoint } = Grid;

export const AddRoleModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFormValues>();
  const { data, isFetching } = useGetRolePermissionList({ UserType: 1 } as any);
  // Maintain switch states for each permission item
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>(
    () => {
      const initialState: { [key: string]: boolean } = {};
      data?.rc?.forEach((item) => {
        initialState[item.Id] = item.IsChecked ?? false; // Provide a default value of false if undefined
      });

      return initialState;
    },
  );
  const { isLoading, mutate } = useCreateRole();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };
  const handleUpdate = (values: any) => {
    const payload: any = {
      RoleName: values.RoleName, // From form input
      UserType: 1,
      PermissionIds: Object.keys(switchStates)
        .filter((id) => switchStates[id]) // Filter only checked permissions
        .map((id) => ({
          id: Number(id), // Convert id to number (if needed)
        })),
    };
    mutate(payload, { onSuccess: onClose });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Create Role'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Create Role'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <RoleInfoFormItems
            data={data}
            isFetching={isFetching}
            switchStates={switchStates}
            setSwitchStates={setSwitchStates}
          />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
