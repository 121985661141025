// import { useNavigate } from 'react-router-dom';
// import { ROUTES } from 'routes/constants';
import { EditNewClients } from 'features/assigned-clients/components/edit-clients/edit-new-clients';
export const EditAssignView = () => {
  // const navigate = useNavigate();

  // const navigateToList = () => {
  //   navigate(ROUTES.CLIENTS);
  // };

  return <EditNewClients />;
};
