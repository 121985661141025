import { Form, Grid, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Modal } from 'components';
import { useClientDocumentApproveReject } from 'features/assigned-clients/use-cases/approve-reject-document';
import { CategoryFormValues } from 'features/master-management/components/filter-field/type';
import { useDropDownClientsFlag } from 'features/new-clients/use-cases/clients-drop';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { UserFormField } from 'features/users/components/users-filter-form';
import { useState } from 'react';
const { useBreakpoint } = Grid;

export const RequestedRejectDocumentsModal = ({
  onClose,
  documentDetail,
  reFetchPendingDocument,
  getDocumentsCount,
}: {
  onClose: () => void;
  documentDetail: any;
  getDocumentsCount: any;
  reFetchPendingDocument: any;
}) => {
  const formName = 'add-activate-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CategoryFormValues>();
  const { data: rejectdata } = useDropDownClientsFlag('RejectReason');
  const { isLoading, mutate } = useClientDocumentApproveReject(
    documentDetail.companyId,
  );
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };
  const handleUpdate = (formValue: any) => {
    const formData = new FormData();
    formData.append('CompanyId', documentDetail?.companyId);
    formData.append('DocumentId', documentDetail?.documentId);
    formData.append('ServiceId', documentDetail?.serviceId);
    formData.append('StatusId', '3');
    formData.append('Reason', formValue?.Reason);
    formData.append('Comment', formValue?.Comments);
    mutate(formData, {
      onSuccess: () => {
        onClose(); // Call onClose when the mutation is successful
        reFetchPendingDocument(); // Call reFetchPendingDocument after onClose
        getDocumentsCount();
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Reject'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Save'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<CategoryFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => setValidateTrigger(['onChange'])}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item label='Region' name='Reason'>
            <Select
              options={rejectdata?.data.rc?.map((item: any) => ({
                label: item.text,
                value: item.text,
              }))}
              placeholder='please select'
            />
          </Form.Item>

          <Form.Item label='Comments' name={'Comments'}>
            <TextArea placeholder='Please enter Comments' />
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
